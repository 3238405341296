import numbro from 'numbro'
import dayjs  from 'dayjs'
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
require('dayjs/locale/ko')
dayjs().locale('ko')

class StringHelpers {

  static moneyWithUnits (money) {
    return `${numbro(money).format({thousandSeparated: true, mantissa: 0})} 원`
  }

  static numToShortStringWithUnit (num) {
    return `${StringHelpers.numToShortString(num)} ${StringHelpers.numToShortStringUnit(num, "money")}`
  }

  // assumes we don't have commas, and assumes number comes in as a number
  // first convert number into a string
  static numToShortString (num) {
    let s = num.toString()
    s = s.replace(/^0+/, '')
    s = s.split('.')[0]
    if (s.length <= 4) {
      return `${parseFloat(num).toFixed(0)}`
    } else if (s.length <= 5) {
      let mahnNumber = (parseFloat(num)/10000).toFixed(1)
      return `${mahnNumber}`
    } else if (s.length <= 8) {
      let mahnNumber = (parseFloat(num)/10000).toFixed(0)
      return `${mahnNumber}`
    } else {
      let ukNumber = (parseFloat(num)/100000000).toFixed(2)
      return `${ukNumber}`
    }
  }

  static numToShortStringUnit (num, type='money') {
    let unitType = ''
    switch (type) {
      case 'money':
        unitType = '원'
        break
      case 'count':
        unitType = '명'
        break
      default:
        unitType = ''
    }

    let s = num.toString()
    s = s.replace(/^0+/, '')
    s = s.split('.')[0]
    if (s.length <= 4) {
      return  `${unitType}`
    } else if (s.length <= 5) {
      return `만${unitType}`
    } else if (s.length <= 8) {
      return `만${unitType}`
    } else {
      return `억`
    }
  }

  static shortDateString (dateString) {
    return dayjs(new Date(dateString)).format('M월 DD일 (ddd)')
  }

  static dateString (dateString) {
    return dayjs(new Date(dateString)).format('YYYY.M.D') + ' (' + StringHelpers.dayOfWeekKorean(dateString) + ')'
  }

  static dateTimeString (dateString) {
    return dayjs(new Date(dateString)).format('YYYY.M.D H:m')
  }

  static monthDay (dateString) {
    return dayjs(dateString).format('M.DD')
  }

  static monthDayWithKorean (dateString) {
    return dayjs(dateString).format('M월 DD일')
  }

  static dayOfWeekKorean (dateString) {
    return StringHelpers.dayOfWeekNumberToKorean(dayjs(dateString).day())
  }

  static dayOfWeekNumberToKorean (day) {
    switch (day) {
      case 0:
        return '일'
      case 1:
        return '월'
      case 2:
        return '화'
      case 3:
        return '수'
      case 4:
        return '목'
      case 5:
        return '금'
      case 6:
        return '토'
      default:
        return ''
    }
  }

  static monthNumberToKorean (day) {
    switch (day) {
      case 0:
        return '1월'
      case 1:
        return '2월'
      case 2:
        return '3월'
      case 3:
        return '4월'
      case 4:
        return '5월'
      case 5:
        return '6월'
      case 6:
        return '7월'
      case 7:
        return '8월'
      case 8:
        return '9월'
      case 9:
        return '10월'
      case 10:
        return '11월'
      case 11:
        return '12월'
      default:
        return ''
    }
  }

  static englishToKoreanWeekday (day) {
    switch (day) {
      case 'Monday':
        return '월'
      case 'Tuesday':
        return '화'
      case 'Wednesday':
        return '수'
      case 'Thursday':
        return '목'
      case 'Friday':
        return '금'
      case 'Saturday':
        return '토'
      case 'Sunday':
        return '일'
      default:
        return ''
    }
  }

  static isSingleKoreanConsonant (text) {
    return text == 'ㄱ' ||
          text == 'ㄴ' ||
          text == 'ㄷ' ||
          text == 'ㄹ' ||
          text == 'ㅁ' ||
          text == 'ㅂ' ||
          text == 'ㅅ' ||
          text == 'ㅇ' ||
          text == 'ㅈ' ||
          text == 'ㅊ' ||
          text == 'ㅌ' ||
          text == 'ㅍ' ||
          text == 'ㅎ'
  }

  // assumes that it is a string of numbers only
  static formatPhoneNumber (phoneNumber) {
    return (phoneNumber.length === 8) 
      ? `${phoneNumber.slice(0,4)}-${phoneNumber.slice(-4)}`
      : `${phoneNumber.slice(0,3)}-${phoneNumber.slice(-4)}` // assumes that its 7 characters
  }

  static bookingDateString (bookingDateString) {
    return `${bookingDateString}: ${StringHelpers.dayOfWeekKorean(bookingDateString)}`
  }

  static bookingTimeString (bookingTime) {
    let minutesString = (bookingTime % 1 === 0) ? '00' : '30'
    let hourString = bookingTime.split('.')[0]
    return `${hourString}:${minutesString} 시`
  }

  static bookingRepeatString (booking) {
    return (booking.totalRepeatCount > 1) ? `${booking.remainingRepeatCount}/${booking.totalRepeatCount}` : ''
  }

  static playerGenderString (gender) {
    return (gender === 1) ? '남' : '여'
  }

  static ageFromBirthDateString (birthDate) {
    return `${dayjs().from(dayjs(birthDate), true)}`
  }

}

export default StringHelpers
