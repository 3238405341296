<template>
  <div class='lg:flex lg:flex-row' style='background-color: #FCFCFC;'>
    <settings-overall
      class='lg:border-r border-gray-100 shadow-xl w-full lg:flex-grow-0 lg:flex-shrink-0 lg:w-96 lg:min-h-screen' />
    <court-settings
      v-if='isEditingSettingCourt'
      class='overflow-x-auto overflow-y-auto lg:flex-grow' />
    <price-settings
      v-else-if='isEditingSettingPrice'
      class='overflow-x-auto overflow-y-auto lg:flex-grow' /> 
  </div>
</template>

<script>
import CourtSettings from '@/views/settings/CourtSettings'
import PriceSettings from '@/views/settings/PriceSettings'
import SettingsOverall from '@/views/settings/SettingsOverall'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Settings',
  components: {
    CourtSettings,
    PriceSettings,
    SettingsOverall,
  },
  computed: {
    ...mapState('manageSettings', [
      'editingSetting'
    ]),
    ...mapGetters('manageSettings', [
      'isEditingSettingCourt',
      'isEditingSettingPrice'
    ]),
  },
}
</script>
