<template>
  <div class='border-white opacity-50 rounded'
    :style='backgroundColorStyle'>
    <div class='box-border'>
      <div class='text-xs text-gray-500'>결제중..</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BookingsSlotPaymentInProgress',
  props: [
    'booking',
    'courtName',
    'courtHourDivided',
  ],
  computed: {
    backgroundColorStyle () {
      return `background-color: #${this.backgroundColor};`
    },
    backgroundColor () {
      return 'F1F1F1'
    },
  },
}
</script>
