import Vue         from 'vue'
import Vuex        from 'vuex'
import cloneDeep   from 'lodash/cloneDeep'
import isEmpty     from 'lodash/isEmpty'
import bookingsApi from '@/api/v1/bookings'
import { getField, updateField } from 'vuex-map-fields'

import dayjs    from 'dayjs'
import utc      from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' 
dayjs.extend(utc)
dayjs.extend(timezone)

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const newBooking = {
  bookingType: 'open',
  profile: {
    name: '',
    phoneNumber: '',
  },
  courtId: 0,
  startTime: '0',
  endTime: '1',
  length: 1
}

const state = () => ({
  selectExistingBookingMode: false,
  selectedExistingBookingList: [],
  bookings: [],
  bookingsOnRepeatedDates: [],
  scheduleSelectedDay: dayjs().tz('Asia/Seoul').format('YYYY-MM-DD'),
  bookingHeight: 3.0, // rem
  drilldownBooking: cloneDeep(newBooking),
  emptyBooking: cloneDeep(newBooking),
  currentTimeInKorea: dayjs().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss'), // get a copy of the current time in Korea from api, so that users can't update their computer time
})

const getters = {
  hasSelectedExistingBookingList: (state) => {
    return !isEmpty(state.selectedExistingBookingList)
  },
  selectedExistingBookingCount: (state) => {
    return state.selectedExistingBookingList.length
  },
  bookingsForCourtWithDate: state => (courtId, date) => {
    return state.bookings.filter(booking => booking.courtId === courtId && booking.bookingDate === date)
                         .sort((a, b) => parseFloat(a.startTime) - parseFloat(b.startTime))
  },
  bookingsForCourt: state => courtId => {
    return state.bookings.filter(booking => booking.courtId === courtId && booking.bookingDate === state.scheduleSelectedDay)
  },
  isRepeatedBooking: state => {
    return state.drilldownBooking.remainingRepeatCount > 1
  },
  isNewBooking: state => {
    return state.drilldownBooking.bookingType === 'open'
  },
  playerBookingSelectedDays: state => {
    return [dayjs(state.scheduleSelectedDay).format('YYYY-MM-DD')] // just temp interface for now
  },
  bookingFromId: state => bookingId => {
    return state.bookings.find(booking => booking.id === bookingId)
  },
  playerMyBookingsCount: state => {
    return state.bookings.filter(booking => booking.profile && booking.profile.name === 'mine').length
  },
  getField,
}

const actions = {
  getBookingsForManager ({ commit, state, rootGetters, dispatch }, date) {
    commit('setBookingsDate', date)
    dispatch('activateLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.getBookings(rootGetters['facilities/selectedManagedFacilityId'], state.scheduleSelectedDay).then(resp => {
        commit('setBookings', resp.bookings) // need to fix?
        dispatch('deactiveLoading', null, {root: true})
        resolve()
      })
    })
    // hit API to get bookings for this date
  },
  getPlayerUnbookableTimes ({ state, getters, commit, rootGetters, dispatch }) {
    dispatch('activateLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.getPlayerBookingsInRange(rootGetters['facilities/currentFacilityId'],
                                           getters.playerBookingSelectedDays,
                                           state.startTime,
                                           state.endTime).then(resp => {
        commit('setBookings', camelcaseKeys(resp.bookings))
        commit('courts/setCourts', camelcaseKeys(resp.courts).sort((a,b) => parseInt(a.id) - parseInt(b.id)), { root: true })
        resolve()
      })
    })
  },
  showBookingsOnRepeatedDates ({ state, commit, rootGetters }, dateTimeRange) {
    return new Promise(resolve => {
      bookingsApi.getBookingsOnRepeatedDate(rootGetters['facilities/currentFacilityId'],
                                            state.drilldownBooking.courtId,
                                            state.drilldownBooking.newBookingRequestId,
                                            dateTimeRange.dateRange,
                                            dateTimeRange.timeRange[0], dateTimeRange.timeRange[1]).then(resp => {
        commit('setBookingsOnRepeatedDates', resp.bookings)  // need to fix?
        resolve()
      })
    })
  },
  createNewBooking ({ commit, dispatch }, newBooking) {
    let apiReady = snakecaseKeys(newBooking)
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.postBooking(apiReady).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        commit('addBooking', resp.booking)
        resolve()
      })
    })
  },
  cancelBooking ({ commit, dispatch }, booking) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.cancelBooking(booking.id,
                                { new_booking_request_id: booking.newBookingRequestId })
      .then(() => {
        commit('removeBooking', booking.id)
        dispatch('stopFullPageLoading', null, {root: true})
        dispatch('resetdrilldownBooking')
        resolve()
      })
    })
  },
  cancelBatchBookingsRelatedRequest ({ commit, dispatch }, booking) {
    return new Promise(resolve => {
      bookingsApi.batchCancelBookingsRelatedRequest({
        new_booking_request_id: booking.newBookingRequestId,
        court_id:               booking.courtId,
        booking_date:           booking.bookingDate, 
        start_time:             booking.startTime})
      .then(() => {
        commit('removeBooking', booking.id)
        dispatch('resetdrilldownBooking')
        resolve()
      })
    })
  },
  cancelBatchBookingsNotRelatedRequest ({ state, commit }) {
    return new Promise(resolve => {
      let bookingParams = state.selectedExistingBookingList.map((booking) => {
        return { booking_id: booking.bookingId }
      })
      bookingsApi.batchCancelBookingsNotRelatedRequest({cancelled_bookings_data: bookingParams})
      .then(() => {
        state.selectedExistingBookingList.forEach((booking) => {
          commit('removeBooking', booking.bookingId)
        })
        commit('resetSelectedExistingBookingList')
        resolve()
      })
    })
  },
  resetdrilldownBooking ({ commit }) {
    commit('setDrilldownBooking', cloneDeep(newBooking))
  },
  resetBookingsOnRepeatedDates ({ commit }) {
    commit('setBookingsOnRepeatedDates', [])
  },
  updateBooking ({ commit, dispatch }, booking) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.updateBooking(booking.id, snakecaseKeys(booking)).then(resp => {
        commit('setSingleBooking', resp.booking)
        commit('setDrilldownBooking', camelcaseKeys(resp.booking))
        dispatch('stopFullPageLoading', null, {root: true})
        resolve()
      })
    })
  },
  updateBookingAttendanceStatus ({ state }) {
    return new Promise(resolve => {
      bookingsApi.updateBooking(state.drilldownBooking.id, snakecaseKeys(state.drilldownBooking)).then(resp => {
        resolve(resp)
      })
    })
  },
  updateBatchBookings ({ commit }, booking) {
    return new Promise(resolve => {
      bookingsApi.batchUpdateBookings(booking.id, snakecaseKeys(booking)).then((resp) => {
        commit('setSingleBooking', resp.booking)
        commit('setDrilldownBooking', camelcaseKeys(resp.booking))
        resolve()
      })
    })
  },
  drilldownBooking ({ commit }, booking) {
    commit('setDrilldownBooking', booking)
  },
  toggleSelectExistingBookingMode ({ state, commit }) {
    commit('setSelectExistingBookingMode', !state.selectExistingBookingMode)
    if (state.selectExistingBookingMode === false) {
      commit('resetSelectedExistingBookingList')
    }
  },
  selectExistBookingSlot ({ commit }, existBookingSlot) {
    commit('setSelectedExistingBookingList', existBookingSlot)
  },
  selectNextDay ({ state, commit }) {
    commit('setBookingsDate', dayjs(state.scheduleSelectedDay).add(1, 'day'))
  },
  getCurrentTimeInKorea ({ commit }) {
    return new Promise(resolve => {
      bookingsApi.getCurrentTimeInKorea().then(resp => {
        console.log('getCurrentTimeInKorea', dayjs(resp.current_time_in_korea).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss'))
        commit('setCurrentTimeInKorea', dayjs(resp.current_time_in_korea).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss'))
        resolve()
      })
    })
  },
}

const mutations = {
  addBooking (state, booking) {
    state.bookings.push(camelcaseKeys(booking))
  },
  removeBooking (state, bookingId) {
    let index = state.bookings.findIndex(booking => booking.id === bookingId)
    if (index !== -1) {
      state.bookings.splice(index, 1)
    }
  },
  setSingleBooking (state, newBooking) {
    let index = state.bookings.findIndex(booking => booking.id === newBooking.id)
    if (index !== -1) {
      state.bookings.splice(index, 1, camelcaseKeys(newBooking))
    }
  },
  setBookingsDate (state, date) {
    state.scheduleSelectedDay = dayjs(date).format('YYYY-MM-DD')
  },
  setBookings (state, bookings) {
    state.bookings = camelcaseKeys(bookings, {deep: true})
  },
  setBookingsOnRepeatedDates (state, bookings) {
    state.bookingsOnRepeatedDates = camelcaseKeys(bookings)
  },
  setShowBookingDetails (state, openOrClosedState) {
    state.showBookingDetails = openOrClosedState
  },
  setDrilldownBooking (state, booking) {
    state.drilldownBooking = cloneDeep(booking)
  },
  setSelectExistingBookingMode (state, status) {
    state.selectExistingBookingMode = status
  },
  setSelectedExistingBookingList (state, existBookingSlot) {
    let index = state.selectedExistingBookingList.findIndex(existBooking => existBooking.bookingId === existBookingSlot.bookingId)
    if (index === -1) {
      state.selectedExistingBookingList.push(existBookingSlot)
    } else {
      state.selectedExistingBookingList.splice(index, 1)
    }
  },
  resetSelectedExistingBookingList (state) {
    state.selectedExistingBookingList = []
  },
  setCurrentTimeInKorea (state, currentTimeInKorea) {
    state.currentTimeInKorea = currentTimeInKorea
  },
  updateField,
}




export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

