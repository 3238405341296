import Vue       from 'vue'
import Vuex      from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
// import dayjs     from 'dayjs'
import profilesApi from '@/api/v1/profiles'
import { getField, updateField } from 'vuex-map-fields'
import isEmpty from 'lodash/isEmpty'

Vue.use(Vuex)

// var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
// dayjs.extend(isSameOrAfter)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  myProfiles: [],
  editingProfile: cloneDeep(emptyProfile)
})

const getters = {
  hasProfiles: state => {
    return state.myProfiles.length > 0
  },
  myProfile: (state, getters) => {
    return (getters.hasProfiles) ? state.myProfiles[0] : {}
  },
  hasMyProfile: (state, getters) => {
    return !isEmpty(getters.myProfile)
  },
  createProfileParams: (state, getters, rootState) => {
    return {
      profile: {
        user_id:      rootState.userId,
        name:         state.editingProfile.name,
        phone_number: state.editingProfile.phoneNumber
      }
    }
  },
  disabledPlayerCreateProfile: state => {
    return !state.editingProfile.name ||
           !state.editingProfile.phoneNumber ||
           !state.editingProfile.isValidPhoneNumber
  },
  getField
}

const emptyProfile = {
    id: 0,
    name: '',
    phoneNumber: '',
    isValidPhoneNumber: false,
    client: {
      clientType: '',
    }
}


const actions = {
  getMyProfiles ({ commit, dispatch }) {
    return new Promise(resolve => {
      profilesApi.getMyPlayerProfiles().then(resp => {
        commit('setProfiles', camelcaseKeys(resp.profiles))
        resolve()
      }).catch(error => {
        if (error.status === 401) {
          dispatch('showOnBoardingModal', true, {root: true})
        }
      })
    })
  },
  createProfileByPlayer ({ getters, commit, dispatch }) {
    return new Promise(resolve => {
      profilesApi.createProfile(getters.createProfileParams).then(newProfile => {
        commit('addToProfiles', camelcaseKeys(newProfile))
        dispatch('resetEditingProfile')
        resolve(newProfile)
      })
    })
  },
  resetEditingProfile ({ commit }) {
    commit('setEditingProfile', cloneDeep(emptyProfile))
  },
  editThisProfile ({ commit }, {playerProfile, playerClientData} ) {
    commit('setEditingProfile', cloneDeep(playerProfile))
    commit('setEditingProfileClientData', cloneDeep(playerClientData))
  }
}

const mutations = {
  setProfiles (state, profiles) {
    state.myProfiles = profiles
  },
  addToProfiles (state, profile) {
    state.myProfiles.push(profile)
  },
  setEditingProfile (state, profile) {
    state.editingProfile = profile
  },
  setEditingProfileClientData (state, clientData) {
    state.editingProfile.client = clientData
  },
  updateField
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
