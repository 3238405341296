<template>
    <div 
      class='bg-white border-gray-100 hover:border-blue-400 hover:rounded-md hover:shadow-inner hover:z-10 cursor-pointer'
      :class='isSelectedSlotStyle'>
      <div class='text-sm text-gray-900 font-bold'>{{timeString}} 시</div>
      <div class='text-xs text-gray-400'>코트 {{courtName}}</div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BookingRequestHelpers from '@/utils/booking-request-helpers'

export default {
  name: 'BookingsSlotOpen',
  props: [
    'booking',
    'courtName'
  ],
  computed: {
    ...mapState('bookings', [
      'drilldownBooking',
    ]),
    ...mapState('manageRequests', [
      'newBookingRequestSlotList',
    ]),
    ...mapGetters('courts', [
      'courtSlotLength'
    ]),
    ...mapGetters([
      'isCourtManager',
      'isCoach',
      'isRegisteredPlayer',
    ]),
    isFullHour () {
      return this.courtSlotLength(this.booking.courtId) == 1.0
    },
    searchList () {
      return (this.isCourtManager || this.isCoach || this.isRegisteredPlayer) ? this.newBookingRequestSlotList : []
    },
    isSelectedSlotStyle () {
      let index = this.searchList.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, this.booking))
      return (index !== -1) ? 'z-10 opacity-100 border-blue-900 rounded-md shadow-inner' : ''
    },
    fullHourString () {
      return this.booking.startTime
    },
    timeString () { // need to update with string functions
      return (this.isFullHour) ? this.fullHourString : this.booking.startTime
    }
  },
}
</script>
