<template>
  <div class='lg:flex lg:flex-row lg:items-stretch h-screen' style='background-color: #FCFCFC;'>
    <div class='overflow-x-auto overflow-y-auto lg:flex-grow text-left p-2 lg:p-8'>
      <div class='mt-8 lg:mt-0 flex flex-col-reverse lg:flex-row justify-start items-start lg:items-center gap-x-2'>
        <!-- {{ newBookingRequestCreatedDateString }} -->
        <el-date-picker
          v-model='newRequestsCreatedDateRange'
          class='w-64'
          type='daterange'
          align='right'
          unlink-panels
          range-separator='-'
          start-placeholder='시작 날찌'
          end-placeholder='마지막 날찌'
          :picker-options='pickerOptions'>
        </el-date-picker>
      </div>
      <div class='flex flex-row justify-start items-start mt-2 gap-x-2'>
        <div class='flex flex-col items-start justify-start bg-gray-100 rounded-lg p-2 lg:p-4'>
          <h4 class='text-xs lg:text-sm uppercase text-gray-500'>
            총결제 금액
          </h4>
          <h1 class='text-lg lg:text-3xl text-gray-900'>{{ totalCollectedString }}</h1>
        </div>
        <el-popover
          placement='top-start'
          title='설명문'
          width='230'
          trigger='hover'
          content='예상 결제수수료약 (1.5%~3%). 카드 수수료는 카드사별로 상이할 수 있음.'>
        <div slot='reference'
          class='flex flex-col items-start justify-start bg-gray-100 rounded-lg p-2 lg:p-4'>
          <h4 class='text-xs lg:text-sm uppercase text-gray-500'>
            수수료 합계
            <span class='font-semibold text-red-500'>*</span>
          </h4>
          <h1 class='text-lg lg:text-3xl text-gray-900'>{{ totalFeesString }}</h1>
        </div>
        </el-popover>

        <div class='flex flex-col items-start justify-start bg-gray-100 rounded-lg p-2 lg:p-4'>
          <h4 class='text-xs lg:text-sm uppercase text-gray-500'>잔액 합계</h4>
          <h1 class='text-lg lg:text-3xl text-gray-900'>{{ totalNetFeesString }}</h1>
        </div>
      </div>
      <table v-if='hasNewBookingRequestsWithUserId'
        class='mt-4 lg:mt-8 table-fixed min-w-full'>
        <thead>
          <tr class='text-sm'>
            <th class='p-2 uppercase whitespace-nowrap border-b'>결제 날자</th>
            <th class='p-2 uppercase whitespace-nowrap border-b'>사용 날자</th>
            <th class='p-2 uppercase whitespace-nowrap border-b'>Schedules</th>
            <th class='p-2 uppercase whitespace-nowrap border-b w-36'>이름</th>
            <th class='p-2 uppercase whitespace-nowrap border-b text-right'>결제금액</th>
            <th class='p-2 uppercase whitespace-nowrap border-b text-right'>
              <el-popover
                placement='top-start'
                title='설명문'
                width='230'
                trigger='hover'
                content='예상 결제수수료약 (1.5% ~ 3%).  카드 수수료는 카드사별로 상이할 수 있음.'>
                <span slot='reference'>
                  예상 카드수수료
                  <span class='font-semibold text-red-500'>*</span>
                </span>
              </el-popover>
            </th>
            <th class='p-2 uppercase whitespace-nowrap border-b text-right'>온라인 결제 수수료</th>
            <th class='p-2 uppercase whitespace-nowrap border-b text-right'>잔액</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for='newBookingRequest in newBookingRequestsWithUserId'
            :key='`newBookingRequest-${newBookingRequest.id}`'
            class='border-b text-sm'>
            <td class='p-2 whitespace-nowrap'>
              {{ dateStringFormatted(newBookingRequest.createdAt) }}
            </td>
            <td class='p-2 whitespace-nowrap'>
              {{ dateStringFormatted(newBookingRequest.metaData.dates[0]) }}
            </td>
            <td class='p-2 whitespace-nowrap w-40'>
              <div
                v-for='(schedule, index) in newBookingRequest.schedules'
                :key='`${newBookingRequest.id}-schedule-${index}`'
                class='flex flex-row justify-start w-40'>
                <div class='w-12'>Court {{ schedule.courtName }}:</div>
                <div class='text-center flex-grow'>{{ schedule.startTime }} - {{ schedule.endTime }}</div>
              </div>
            </td>
            <td class='p-2 whitespace-nowrap'>
              {{ newBookingRequest.name }}
            </td>
            <td class='p-2 text-right whitespace-nowrap'>
              {{ priceString(newBookingRequest.bookingsTotalPrice) }}
            </td>
            <td class='p-2 text-right whitespace-nowrap'>
              {{ feeFromToss(newBookingRequest.bookingsTotalPrice) }} 원
            </td>
            <td class='p-2 text-right whitespace-nowrap'>
              {{ feeFromTennisPlay() }} 원
            </td>
            <td class='p-2 text-right whitespace-nowrap'>
              {{ netFeesString(newBookingRequest.bookingsTotalPrice) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else
        class='mt-6 opacity-50'>
        추가된 예약이 없습니다.
      </div>
    </div>
    <mobile-expand-button
      class='inline-block lg:hidden'
      @click.native='hideContentOnMobile'>
      {{ newBookingRequestCreatedDateString }}<span class='inline-block ml-2 text-gray-400'>[날짜 선택]</span>
    </mobile-expand-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import MobileExpandButton  from '@/components/MobileExpandButton'
import StringHelpers       from '@/utils/string-helpers'
import dayjs               from 'dayjs'

export default {
  name: 'ManageBillings',
  components: {
    MobileExpandButton,
  },
  data () {
    return {
      selectedManagerNewRequest: null,
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
    }
  },
  watch: {
    newRequestsCreatedDateRange: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.refreshData()
        }
      }, 
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState([
      'showContentOnMobile'
    ]),
    ...mapFields('manageRequests', [
      'newRequestsCreatedDateRange',
    ]),
    ...mapGetters('manageRequests', [
      'newBookingRequestsWithUserId',
      'hasNewBookingRequestsWithUserId'
    ]),
    newBookingRequestCreatedDateString () {
      return `${StringHelpers.dateString(this.newRequestsCreatedDateRange[0])} - ${StringHelpers.dateString(this.newRequestsCreatedDateRange[1])}`
    },
    newRequestsNavclass () {
      return (this.showContentOnMobile) ? 'hidden' : 'block'
    },
    startDateString () {
      return dayjs(this.newRequestsCreatedDateRange[0]).format('YYYY-MM-DD')
    },
    endDateString () {
      return dayjs(this.newRequestsCreatedDateRange[1]).format('YYYY-MM-DD')
    },
    totalCollected () {
      return this.newBookingRequestsWithUserId.reduce((total, newBookingRequest) => {
        return total + parseInt(newBookingRequest.bookingsTotalPrice)
      }, 0)
    },
    totalCollectedString () {
      return StringHelpers.moneyWithUnits(this.totalCollected)
    },
    totalFees () {
      return this.newBookingRequestsWithUserId.reduce((total, newBookingRequest) => {
        return total + this.combinedFees(parseInt(newBookingRequest.bookingsTotalPrice))
      }, 0)
    },
    totalFeesString () {
      return StringHelpers.moneyWithUnits(this.totalFees)
    },
    totalNetFees () {
      return this.newBookingRequestsWithUserId.reduce((total, newBookingRequest) => {
        return total + this.netFees(parseInt(newBookingRequest.bookingsTotalPrice))
      }, 0)
    },
    totalNetFeesString () {
      return StringHelpers.moneyWithUnits(this.totalNetFees)
    },
  },
  methods: {
    ...mapActions('manageRequests', [
      'getManagerNewBookingRequestsForBilling',
    ]),
    ...mapActions([
      'hideContentOnMobile',
      'displayContentOnMobile',
    ]),
    refreshData () {
      this.getManagerNewBookingRequestsForBilling()
      this.displayContentOnMobile()
    },
    openManagerNewBookingRequestCancellationModal (newBookingRequest) {
      this.selectedManagerNewRequest = newBookingRequest
      this.$modal.show('manager-new-booking-request-cancellation-modal')
    },
    closeManagerNewBookingRequestCancellationModal () {
      this.selectedManagerNewRequest = null
      this.$modal.hide('manager-new-booking-request-cancellation-modal')
    },
    priceString (price) {
      return StringHelpers.moneyWithUnits(price)
    },
    feeFromToss (price) {
      return Math.round(price * 0.02)
    },
    feeFromTennisPlay () {
      return 500
    },
    combinedFees (price) {
      return (this.feeFromToss(price) + this.feeFromTennisPlay())
    },
    feeString (price) {
      return StringHelpers.moneyWithUnits(this.combinedFees(price))
    },
    netFees (price) {
      return price - this.combinedFees(price)
    },
    netFeesString (price) {
      return StringHelpers.moneyWithUnits(this.netFees(price))
    },
    dateStringFormatted (date) {
      return StringHelpers.dateString(date)
    },
  },
}
</script>

<style lang='scss'>
.manage-new-booking-requests .vdp-datepicker__calendar {
  @apply w-full border-transparent;
  background-color: transparent;
}
</style>


<style type='scss' scoped>
.new-requests-nav {
  height: 100%;
}
@media (min-width: 1024px) {
  .new-requests-nav {
    height: 100%;
    display: block;
  }
}
</style>