<template>
  <div
    id='CalendarContainer' 
    class='p-4 lg:p-8'>
    <bookings-nav-select-date />
    <bookings-nav-refresh-control />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import BookingsNavSelectDate     from '@/views/bookings/BookingsNavSelectDate'
import BookingsNavRefreshControl from '@/views/bookings/BookingsNavRefreshControl'

import dayjs from 'dayjs'

export default {
  name: 'BookingsNav',
  components: {
    BookingsNavSelectDate,
    BookingsNavRefreshControl,
  },
  data () {
    return {
      endAvailablePublicDate: dayjs(new Date()).add(2, 'week'),
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        },
      },
    }
  },
  computed: {
    ...mapState([
      'showContentOnMobile',
    ]),
    ...mapState('bookings', [
      'bookingsDate'
    ]),
    ...mapGetters('facilities', [
      'selectedManagedFacilityName',
    ]),
    currentlySelectedDate () {
      return dayjs(this.bookingsDate).format('M.D')
    },
  },
  methods: {
    ...mapActions([
      'hideContentOnMobile',
      'displayContentOnMobile',
    ]),
  },
}
</script>

<style lang='scss'>
#CalendarContainer {
  .el-calendar-table .el-calendar-day {
    height: auto;
  }

  .el-calendar__header {
    @apply p-0;
    border-bottom: none;
  }

  .el-calendar__body {
    @apply p-0;
  }

  .is-selected .el-calendar-day {
    @apply font-semibold shadow-md bg-blue-100;
  }
}
</style>