<template>
  <modal
    name='signup-modal'
    :adaptive='true'
    classes='relative p-12 lg:p-16'
    height='800px'>
    <button class='z-30 absolute top-3 right-3' @click="$modal.hide('signup-modal')">
      <x-icon class='text-black' />
    </button>
    <div class='flex flex-col justify-center items-center gap-y-8'>
      <logo height='3rem' />
    </div>
    <h1 class='mt-10 text-lg text-center uppercase text-gray-700' style='font-weight:900'>이메일 회원가입</h1>
    <div class='mt-4'>
      <input 
        v-model='email'
        class=' w-full p-2 rounded border-gray-300'
        type='text'
        placeholder='이메일'>
      <input 
        v-model='password'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='password'
        placeholder='비밀번호'
        @focusout='checkPasswordRequirements'>
      <div v-if='!arePasswordRequirementsMet'
        class='text-red-600 text-sm text-left'>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</div>
      <input 
        v-model='passwordConfirmation'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='password'
        placeholder='비밀번호 확인'
        @focusout='checkPasswordConfirmation'>
      <div v-if='!isPasswordConfirmed'
        class='text-red-600 text-sm text-left'>비밀번호가 일치하지 않습니다.</div>
      <input 
        v-model='name'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='text'
        placeholder='이름'>
      <input 
        v-model='phoneNumber'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='text'
        placeholder='전화번호'>
      <div class='text-red-600 text-sm text-left'>전화번호를 올바르게 입력했는지 확인해주세요.<br>잘못된 정보 입력으로 피해가 발생했을 경우, 당사는 어떠한 책임도 부담하지 않습니다.</div>
      <button
        class='mt-8 w-full p-4 rounded text-white uppercase'
        :class='emptyFieldLoginButtonClass'
        :disabled='needToInputLoginField'
        @click='submitEmailSignup'>
        회원가입
      </button>
      <div class='flex flex-row justify-between mt-4'>
        <ul v-if='needToInputLoginField'
          class='list-disc list-inside text-red-600 text-sm text-left'>
          <li v-if='emptyEmailField'>이메일을 입력해주세요.</li>
          <li v-if='emptyPasswordField'>비밀번호를 입럭해주세요.</li>
          <li v-if='emptyNameField'>이름을 입럭해주세요.</li>
          <li v-if='emptyPhoneNumberField'>전화번호를 입럭해주세요.</li>
        </ul>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapFields }        from 'vuex-map-fields'
import { mapActions }       from 'vuex'
import { XIcon }            from '@vue-hero-icons/outline'
import Logo from '@/components/Logo'


export default {
  name: 'SignupForm',
  components: {
    XIcon,
    Logo,
  },
  data () {
    return {
      arePasswordRequirementsMet: true,
      isPasswordConfirmed: true,
    }
  },
  computed: {
    ...mapFields('users', [
      'signupParams.email',
      'signupParams.password',
      'signupParams.passwordConfirmation',
      'signupParams.name',
      'signupParams.phoneNumber',
    ]),
    emptyEmailField () {
      return !this.email
    },
    emptyPasswordField () {
      return !this.password
    },
    emptyPasswordConfirmationField () {
      return !this.passwordConfirmation
    },
    emptyNameField () {
      return !this.name
    },
    emptyPhoneNumberField () {
      return !this.phoneNumber
    },
    needToInputLoginField () {
      return this.emptyEmailField ||
             this.emptyPasswordField ||
             this.emptyPasswordConfirmationField ||
             this.emptyNameField ||
             this.emptyPhoneNumberField ||
             !this.arePasswordRequirementsMet ||
             !this.isPasswordConfirmed
    },
    emptyFieldLoginButtonClass () {
      return (this.needToInputLoginField) ? 'bg-gray-500 opacity-25 cursor-not-allowed' : 'bg-gray-900'
    },
  },
  methods: {
    submitEmailSignup () {
      this.emailSignup().then(() => {
        this.$router.push({name: 'Home'}).catch(() => {})
      }).catch((error) => {
        if (error.response.status === 409) {
          this.$alert('이미 회원가입된 이메일입니다. 이메일을 확인 후 다시 회원가입해주세요.', {
            confirmButtonText: '확인',
            type: 'warning'
          })
        } else if (error.response.data.error === 'IncorrectPasswordFormatError') {
          this.$alert('비밀번호는 4자 이상으로 되어야합니다.', {
            confirmButtonText: '확인',
            type: 'warning'
          })   
        } else if (error.response.data.error === 'IncorrectEmailFormatError') {
          this.$alert('이메일주소 형식이 아닙니다.', {
            confirmButtonText: '확인',
            type: 'warning'
          })   
        } else {
          console.error(error)
        }
      })
    },
    checkPasswordRequirements () {
      let regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,16}$/
      this.arePasswordRequirementsMet = regExp.test(this.password)
      if (this.passwordConfirmation) {
        this.isPasswordConfirmed = (this.password === this.passwordConfirmation)
      }
    },
    checkPasswordConfirmation () {
      this.isPasswordConfirmed = (this.password === this.passwordConfirmation)
    },
    ...mapActions([
      'setLocalStorageData'
    ]),
    ...mapActions('users',[
      'emailSignup'
    ]),
  }
}
</script>
