<template>
  <component
    :is='bookingSlotComponent'
    @click.native='clickSlotAction'
    :booking='booking'
    :court-name='courtName'
    :is-editing-booking='isEditingBooking'
    :is-selected-booking='isSelectedBooking'
    :court-hour-divided='courtHourDivided'
    class='w-24 flex flex-col justify-center border booking-slot'
    style='margin: 1px;'
    :style='bookingHeightStyle'>
    {{ errorString }}
  </component>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BookingsSlotExisting           from '@/components/bookings/BookingsSlotExisting'
import BookingsSlotOpen               from '@/components/bookings/BookingsSlotOpen'
import BookingsSlotUnavailable        from '@/components/bookings/BookingsSlotUnavailable'
import BookingSlotMyNewBookingRequest from '@/components/bookings/BookingSlotMyNewBookingRequest'
import BookingsSlotPaymentInProgress  from '@/components/bookings/BookingsSlotPaymentInProgress'


export default {
  name: 'BookingsSlot',
  components: {
    BookingsSlotExisting,
    BookingsSlotOpen,
    BookingsSlotUnavailable,
    BookingSlotMyNewBookingRequest,
    BookingsSlotPaymentInProgress
  },
  props: [
    'booking',
    'courtName',
    'courtHourDivided'
  ],
  computed: {
    ...mapState('bookings', [
      'drilldownBooking',
      'bookingHeight',
      'selectExistingBookingMode',
      'selectedExistingBookingList'
    ]),
    ...mapState('search', [
      'endTime',
      'startTime',
    ]),
    ...mapGetters([
      'isCourtManager',
      'isCoach',
      'isRegisteredPlayer',
    ]),
    ...mapGetters('bookings', [
      'playerMyBookingsCount',
    ]),
    ...mapGetters('manageRequests', [
      'newBookingRequestSlotListCount',
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityBookableHoursPerDay'
    ]),
    bookingSlotComponent () {
      if (this.isExistingSlot) {
        return 'bookings-slot-existing'
      } else if (this.isSlotOpen) {
        return 'bookings-slot-open'
      } else if (this.isUnavailableSlot) {
        return 'bookings-slot-unavailable'
      } else if (this.isPaymentInProgressSlot) {
        return 'bookings-slot-payment-in-progress'
      } else if (this.isMyNewBookingRequestSlot) {
        return 'booking-slot-my-new-booking-request'
      } else {
        return ''
      }
    },
    drilldownComponent () {
      if (this.isUnavailableSlot) {
        return 'div'
      } else if (this.isCourtManager) {
        return this.managerDrilldownComponents
      } else if (this.isRegisteredPlayer) {
        return this.playerDrilldownComponents
      } else if (this.isCoach) {
        // figure out what to do
        return ''
      } else {
        return ''
      }
    },
    managerDrilldownComponents () {
      if (this.booking.bookingType.includes('reserved') || this.booking.bookingType === 'substitution') {
        return 'existing-booking'
      } else if (this.booking.bookingType === 'blocked') {
        return 'existing-booking'
        // return 'block-booking'
      } else if (this.booking.bookingType === 'open') {
        return 'manager-new-booking-request'
      } else {
        console.log('manager clicks, what is going on? what component to use?')
        return ''
      }
    },
    playerDrilldownComponents () {
      if (this.isSlotOpen) {
        return 'player-new-booking-request'
      } else {
        return 'existing-booking-view'
      }
    },
    errorString () {
      return (this.bookingSlotComponent === 'div') ? 'there is an error' : ''
    },
    isEditingBooking () {
      return this.isExistingSlot &&
             this.drilldownBooking.id === this.booking.id &&
             this.isCourtManager
    },
    isExistingSlot () {
      return this.booking.bookingType.includes('reserved') ||
             this.booking.bookingType === 'blocked' ||
             this.booking.bookingType === 'substitution'
    },
    isSelectedBooking () {
      return this.isExistingSlot && this.selectExistingBookingMode &&
             this.selectedExistingBookingList.findIndex(existBooking => existBooking.bookingId === this.booking.id) !== -1 &&
             this.isCourtManager
    },
    isUnavailableSlot () {
      return this.booking.bookingType === 'unavailable'
    },
    isMyNewBookingRequestSlot () {
      return this.booking.bookingType === 'my_new_booking_request'
    },
    isSlotOpen () {
      return this.booking.bookingType === 'open'
    },
    isPaymentInProgressSlot () {
      return this.booking.bookingType === 'not_paid'
    },
    bookingLength () {
      return parseFloat(this.booking.endTime) - parseFloat(this.booking.startTime)
    },
    bookingLengthForUI () {
      var length = this.bookingLength
      if (this.isSearchPage) {
        if (!this.isEndingInRange) {
          length -= this.endTimeDiff
        }
        if (!this.isBeginningInRange) {
          length -= this.startTimeDiff
        }
      }
      return length
    },
    bookingHeightStyle () {
      if (this.isUnavailableSlot ||
          this.isExistingSlot) {
        const length = this.bookingLengthForUI * this.courtHourDivided * this.bookingHeight
        const border = this.bookingLengthForUI * this.courtHourDivided - 1
        return `height: calc(${length}rem + ${border}px);`
      } else {
        return `height: ${this.bookingHeight}rem`
      }
    },
    endTimeDiff () { // will be a positive number if the booking ends after the time-range
      return parseFloat(this.booking.endTime) - this.endTime
    },
    startTimeDiff () { // will be a positive number if the booking starts before the time-range
      return this.startTime - parseFloat(this.booking.startTime)
    },
    isSearchPage () {
      return this.$route.name === 'Search'
    },
    isEndingInRange () {
      return this.endTimeDiff <= 0
    },
    isBeginningInRange () {
      return this.startTimeDiff <= 0
    },
    singleSlotClass () {
      return (this.bookingLengthForUI == 1) ? 'inline-block' : ''
    },
    playerExceedBookableHoursPerDay () {
      // need to add calc for court num_of_hour_divided later
      return this.selectedFacilityBookableHoursPerDay < this.playerMyBookingsCount + this.newBookingRequestSlotListCount
    },
  },
  methods: {
    ...mapActions([
      'sidepanelOpen',
    ]),
    ...mapActions('bookings', [
      'showNewBooking',
      'showExistingBooking',
      'selectExistBookingSlot',
    ]),
    ...mapMutations('bookings', [
      'setDrilldownBooking',
    ]),
    ...mapActions('manageRequests', [
      'selectNewBookingRequestSlot',
    ]),
    managerActions () {
      if (this.isSlotOpen) {
        this.selectNewBookingRequestSlot({
          courtId:     this.booking.courtId,
          bookingDate: this.booking.bookingDate,
          startTime:   this.booking.startTime,
          endTime:     this.booking.endTime
        })
      } else if (this.selectExistingBookingMode) {
        this.selectExistBookingSlot({
          bookingId:   this.booking.id
        })
      } else {
        this.setDrilldownBooking(this.booking)
        this.sidepanelOpen({ drilldownComponent: this.drilldownComponent })
      }
    },
    playerActions () {
      if (this.isSlotOpen) {
        this.selectNewBookingRequestSlot({
          courtId:     this.booking.courtId,
          bookingDate: this.booking.bookingDate,
          startTime:   this.booking.startTime,
          endTime:     this.booking.endTime
        }).then(() => {
          if (this.playerExceedBookableHoursPerDay) {
            this.selectNewBookingRequestSlot({
              courtId:     this.booking.courtId,
              bookingDate: this.booking.bookingDate,
              startTime:   this.booking.startTime,
              endTime:     this.booking.endTime
            }).then(() => {
              this.$alert(`하루에 최대 ${this.selectedFacilityBookableHoursPerDay}시간까지만 예약가능합니다. 예약시간을 확인해주세요.`, {
                confirmButtonText: '확인',
                type: 'warning'
              })
            })
          }
        })
      } else if (this.booking.bookingType === 'reserved' && this.booking.profile.name === 'mine') {
        this.setDrilldownBooking(this.booking)
        this.sidepanelOpen({ drilldownComponent: this.drilldownComponent })
      } else {
        console.log('what action to take for player on click slot')
      }
    },
    clickSlotAction () {
      if (this.isUnavailableSlot || this.isPaymentInProgressSlot) {
        // do nothing
      } else if (this.isCourtManager) {
        this.managerActions()
      } else if (this.isRegisteredPlayer) {
        this.playerActions()
      } else {
        console.log('dont know the slot')
      }
    },
  },
}
</script>

<style lang='scss' scoped>

@media print {
  .booking-slot {
    @apply w-20 text-xs;
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
}
</style>
