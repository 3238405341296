<template>
  <nav class='nav-container'>
    <div class='flex flex-row lg:flex-col items-center justify-evenly w-1/2 lg:w-auto'>
      <router-link :to='{ name: "PlayerReservations" }'
        @click.native='hideContentOnMobile'
        class='navlink'>
        <clipboard-list-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>예약현황</h4>
      </router-link>
      <router-link :to='{ name: "PlayerScheduleBookings" }'
        @click.native='hideContentOnMobile'
        class='navlink'>
        <calendar-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>예약하기</h4>
      </router-link>
    </div>
    <div class='flex flex-row lg:flex-col items-center justify-evenly w-1/2 lg:w-auto'>
      <router-link :to='{ name: "CustomerSupport" }'
        @click.native='hideContentOnMobile'
        class='navlink'>
        <support-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='text-xs mt-1 text-gray-400'>고객센터</h4>
      </router-link>
      <button 
        class='navlink'
        @click='logout'>
        <logout-icon class='block mx-auto h-4' style='margin-top: -2px;' />
        <h4 class='mt-1 text-gray-400' style='font-size:0.7rem'>로그아웃</h4>
      </button>
    </div>
  </nav>
</template>

<script>
import { mapActions } from 'vuex'
import ssoSessionsApi from '@/api/v1/users/api-sso-sessions.js'
import { 
  CalendarIcon, 
  ClipboardListIcon, 
  LogoutIcon,
  SupportIcon,
  } from '@vue-hero-icons/outline'

export default {
  name: 'NavMenuPlayer',
  components: {
    CalendarIcon,
    ClipboardListIcon,
    LogoutIcon,
    SupportIcon,
  },
  methods: {
    ...mapActions([
      'hideContentOnMobile',
      'clearLocalStorageData',
    ]),
    logout () {
      ssoSessionsApi.logout().then(() => {
        this.clearLocalStorageData()
        this.$router.push({name: 'Login'})
      })
    }
  },
}
</script>

<style scoped lang='scss'>
.navlink {
  @apply px-4 lg:px-0 w-auto lg:w-16 py-2 lg:py-3 lg:mx-2 lg:mb-8 rounded-md inline-block lg:block text-gray-800 border border-transparent text-gray-800;
}
.navlink:hover {
  @apply border-indigo-100 bg-white;
}
.navlink.router-link-active {
  @apply text-gray-700 font-semibold shadow-inner border-gray-100;
  background-color: #FFFFFFEE;
}

.navlink.router-link-active h4 {
  @apply text-blue-700 font-semibold;
}
</style>
