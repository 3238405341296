import Vue       from 'vue'
import Vuex      from 'vuex'
Vue.use(Vuex)

const state = () => ({
  editingSetting: ''
})

const getters = {
  isEditingSettingCourt: state => {
    return state.editingSetting === 'court'
  },
  isEditingSettingPrice: state => {
    return state.editingSetting === 'price'
  },
}

const actions = {
  selectSettingToEdit ({ commit }, price) {
    commit('setEditingSetting', price)
  },
}

const mutations = {
  setEditingSetting (state, setting) {
    state.editingSetting = setting
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
