import Vue                       from 'vue'
import Vuex                      from 'vuex'
import TossPaymentApi            from '@/api/v1/toss-payments'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')

const state = () => ({
})

const getters = {
  getField
}

const actions = {
  requestTossPaymentOnSuccess ({ commit }, orderParams) {
    return new Promise((resolve, reject) => {
      TossPaymentApi.tossPaymentSuccess(orderParams.order_id, orderParams).then(resp => {
        commit('payments/setCurrentOrder', camelcaseKeys(resp.new_booking_request, {deep: true}), { root: true })
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  requestTossPaymentCancel ({ commit, rootGetters }, {orderId, cancelParams} ) {
    return new Promise((resolve, reject) => {
      TossPaymentApi.tossPaymentCancel(orderId, cancelParams).then(resp => {
        if (rootGetters['isCourtManager']) {
          commit('manageRequests/updateCancellednewBookingRequestsWithUserId', camelcaseKeys(resp.new_booking_request, {deep: true}), {root: true})
        } else if (rootGetters['isRegisteredPlayer']) {
          commit('playerBookingRequests/updateCancelledMyBookingRequests', camelcaseKeys(resp.new_booking_request, {deep: true}), {root: true})
        }
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
}

const mutations = {
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
