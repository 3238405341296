import base from './base'

const getFacilities = () => base
  .get('/facilities/').then(resp => resp.data)

const getManagedFacilities = () => base
  .get('/facilities/user_facilities').then(resp => resp.data)

const postFacility = (facilityParams) => base
  .post('/facilities/', facilityParams).then(resp => resp.data)

const updateFacility = (facilityId, facilityParams) => base
  .patch(`/facilities/${facilityId}`, facilityParams).then(resp => resp.data)

const deleteFacility = (facilityId) => base
  .delete(`/facilities/${facilityId}`).then(resp => resp.data)

export default {
  getFacilities: getFacilities,
  getManagedFacilities: getManagedFacilities,
  postFacility: postFacility,
  updateFacility: updateFacility,
  deleteFacility: deleteFacility
}
