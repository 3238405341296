<template>
  <div class='flex flex-row justify-center items-center h-screen'>
    <div>
      <loading />
      <div class='text-lg max-w-sm px-12'>
        Please contact the Tennis Play team 
        to get access to your facility.
      </div>
    </div>
  </div>
</template>

<script>
import Loading    from '@/components/Loading'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    Loading,
  },
  watch: {
    roles: {
      handler: function (newVal) {
        if (newVal) {
          if (this.isCourtManager || this.isCoach) {
            this.$router.push({ name: 'ManageBookings' }).catch(()=>{})
          } else if (this.isRegisteredPlayer) {
            this.$router.push({ name: 'PlayerReservations' }).catch(()=>{})
          } else {
            this.$router.push({ name: 'Schedule' }).catch(()=>{})
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
      'roles',
    ]),
    ...mapGetters([
      'isCourtManager',
      'isRegisteredPlayer',
    ]),
  },
}
</script>
