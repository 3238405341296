<template>
  <div class='p-8 flex-grow text-left'>
    <div class='mt-6 flex flex-row justify-start'>
      <h1 class='mt-2  text-2xl font-semibold mr-12'><span class='font-base'>코트: </span>{{ editPriceCourtListString }}</h1>
      <button class='block bg-gray-900 text-sm text-white py-3 px-8 rounded-md hover:shadow-lg'
        @click='saveChanges'>
        저장
      </button>
    </div>
    <div v-for='(priceByMonths) in pricesByMonths'
      :key='`priceByMonths-${priceByMonths.id}`'
      class='mt-2' >
      <price-for-months :price-for-months='priceByMonths' />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep    from 'lodash/cloneDeep' 
import PriceForMonths from '@/views/settings/PriceForMonths'

export default {
  name: 'PriceSettings',
  components: {
    PriceForMonths,
  },
  data () {
    return {
      pricesByMonths: {}
    }
  },
  watch: {
    'editingPrice': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.pricesByMonths = cloneDeep(this.editingPrice.pricesByMonths).sort((a, b) => parseInt(a.months) - parseInt(b.months))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('courts', [
      'courtNameFromId'
    ]),
    ...mapState('prices', [
      'editingPrice',
    ]),
    editPriceCourtListString () {
      return this.editingPrice.courtIds.map((courtId) => this.courtNameFromId(courtId)).join(', ')
    }
  },
  methods: {
    ...mapActions('prices', [
      'updateBatchPrices',
    ]),
    saveChanges () {
      this.$confirm('변경된 사항을 저장하시겠습니까?', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        this.updateBatchPrices(this.pricesByMonths).then(() => {
          this.$message({
            type: 'success',
            message: '변경사항이 저장되었습니다.'
          }) 
        })
      })
    },
  }
}
</script>

<style lang='scss' scoped>
button:disabled,
button[disabled] {
  @apply opacity-50 cursor-not-allowed;
}
</style>
