<template>
  <el-dialog
    :visible='showingOnBoardingModal && isRegisteredPlayer'
    custom-class='w-full lg:w-1/4'
    top='8vh'
    :show-close='false'>
    <div class='text-lg font-semibold text-left'>회원정보 입력</div>
    <div class='mb-4 lg:mb-8 text-xs text-left'>*원활한 코트 예약을 위해 기본정보를 입력해주세요.</div>
    <profile-form class='mb-8 lg:mb-12' />
    <button
      class='create-profile-button w-full'
      :disabled='disabledPlayerCreateProfile'
      @click='createNewProfile'>
      입력 완료
    </button>
  </el-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ProfileForm from '@/components/profiles/ProfileForm'

export default {
  name: 'OnboardingModal',
  components:{
    ProfileForm
  },
  computed: {
    ...mapState([
      'showingOnBoardingModal'
    ]),
    ...mapGetters('playerProfile', [
      'disabledPlayerCreateProfile',
    ]),
    ...mapGetters([
      'isRegisteredPlayer',
    ]),
  },
  methods: {
    ...mapActions([
      'closeOnBoardingModal'
    ]),
    ...mapActions('playerProfile', [
      'createProfileByPlayer',
    ]),
    createNewProfile () {
      this.createProfileByPlayer().then(() => {
        this.closeOnBoardingModal()
      })
    },
  }
}
</script>

<style lang='scss' scoped>
button.create-profile-button {
  @apply py-2 px-8 text-sm rounded bg-gray-900 text-white opacity-75 cursor-pointer;
}

button.create-profile-button:hover {
  @apply opacity-100 shadow-md;
}

button.create-profile-button:disabled,
button.create-profile-button[disabled]{
  @apply opacity-25 cursor-not-allowed;
}
</style>
