<template>
  <div v-if='showCourt'>
    <div class='w-24 h-10 text-sm flex justify-center items-center font-bold booking-for-court-column bg-white border-b sticky top-0'>
      코트 {{court.courtName}}
    </div>
    <bookings-slot v-for='booking in slotsForCourt'
      :key='`booking-${booking.courtId}-${booking.startTime}`'
      :court-name='court.courtName'
      :booking='booking' 
      :court-hour-divided='court.numOfHourDivided' />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import dayjs                    from 'dayjs'
import cloneDeep                from 'lodash/cloneDeep'
import uniqBy                   from 'lodash/uniqBy'
import BookingsSlot             from '@/views/bookings/BookingsSlot.vue'

export default {
  name: 'BookingsForCourt',
  components: {
    BookingsSlot
  },
  props: [ 
    'court', 
    'bookings', 
    'bookingDate', 
    'timeRange', 
    'showFullyBookedCourt' 
  ],
  watch: {
    hasOpenSlots: {
      handler: function (newVal) {
        if (newVal) { 
          this.pushToHasOpenSlotsCourt(this.court.id)
        } else {
          this.removeFromHasOpenSlotsCourt(this.court.id)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('bookings', [
      'emptyBooking',
      'currentTimeInKorea',
      'scheduleSelectedDay',
    ]),
    ...mapGetters([
      'isCourtManager',
    ]),
    ...mapGetters('courts', [
      'courtSlotLength'
    ]),
    hasOpenSlots () {
      return this.slotsForCourt.find(slot => this.isOpenSlot(slot)) !== undefined
    },
    slotLength () {
      return this.courtSlotLength(this.court.id)
    },
    slotsForCourt () {
      let editingBookings = cloneDeep(this.bookings.filter(booking => parseFloat(booking.startTime) >= parseFloat(this.startTimeConsideringNow))) // assumes that bookings are one slot length, and start at the beginning of slots
      editingBookings = uniqBy(editingBookings, 'startTime') // should remove duplicates... could get errors if within a given time, they are not sorted the same way

      let slots = []
      // need function for set this time range
      for (let i = this.startTimeConsideringNow; i < this.timeRange.end; i += this.slotLength) {
        if (editingBookings.length > 0 && parseFloat(editingBookings[0].startTime) == i) {
          let bookingInSlot = editingBookings.shift()
          let nextBookingInSlot = this.setNextBooking(editingBookings)

          // has a next booking & next booking is from same booking request
          while (nextBookingInSlot && 
            bookingInSlot.endTime             == nextBookingInSlot.startTime &&
            bookingInSlot.newBookingRequestId == nextBookingInSlot.newBookingRequestId && // this is used to compare for managers, will both be null for players
            bookingInSlot.bookingType         == nextBookingInSlot.bookingType) { // this is used for players... used to separate out mine (which should be reserved) vs unavailable for ones that aren't mine
            bookingInSlot.endTime = nextBookingInSlot.endTime
            bookingInSlot.id      += `,${nextBookingInSlot.id}` // just to make it easier to see the ids for now
            bookingInSlot.length  += 1 // probably can remove, and just use the id lookup to sum
            bookingInSlot.price   += nextBookingInSlot.price // probably can remove, and just use the id lookup to sum
            editingBookings.shift()
            nextBookingInSlot = this.setNextBooking(editingBookings)
          }

          i = parseFloat(bookingInSlot.endTime) - this.slotLength // to offset the for loop
          slots.push(bookingInSlot)
        } else {
          slots.push(this.openSlotFromStartTime(i))
        }
      }
      return slots
    },
    showCourt () {
      return this.hasOpenSlots || this.showFullyBookedCourt
    },
    startTimeConsideringNow () {
      if (this.isCourtManager || dayjs(this.bookingDate).isAfter(dayjs(this.currentTimeInKorea), 'day')) {
        return this.timeRange.start
      } else if (dayjs(this.bookingDate).isSame(dayjs(this.currentTimeInKorea), 'day')) {
        let hour = dayjs(this.currentTimeInKorea).hour()
        return Math.max(this.timeRange.start, hour)
      } else {
        return 26
      }
    },
  },
  methods: {
    ...mapMutations('courts', [
      'pushToHasOpenSlotsCourt',
      'removeFromHasOpenSlotsCourt',
    ]),
    openSlotFromStartTime (startTimeNumber) {
      let openSlot = cloneDeep(this.emptyBooking)
      openSlot['courtId']     = this.court.id
      openSlot['courtName']   = this.court.courtName
      openSlot['bookingDate'] = this.bookingDate
      openSlot['startTime']   = startTimeNumber.toString()
      openSlot['endTime']     = (startTimeNumber + this.slotLength).toString()
      return openSlot
    },
    setNextBooking (bookingList) {
      return (bookingList.length > 0) ? bookingList[0] : null
    },
    isOpenSlot (slot) {
      if (this.isCourtManager) {
        return slot.bookingType === 'open'
      } else {
        return slot.bookingType === 'open' || slot.bookingType === 'reserved'
      }
    },
  },
}
</script>

<style lang='scss' scoped>

@media print {
  .booking-for-court-column {
    @apply w-20;
  }
}
</style>
