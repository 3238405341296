<template>
  <div 
    class='text-sm lg:text-base max-w-lg border rounded-lg bg-white shadow-md'>
    <div class='bg-white px-6 py-4 font-semibold'>
      이용예정
    </div>
    <div class='flex flex-row items-start justify-between bg-gray-100'>
      <div class='flex flex-col gap-x-4 py-4 px-6 w-40'>
        <div class='flex-shrink-0 flex-grow-0 rounded-md w-40 flex flex-col justify-start items-start gap-y-1'>
          <h3 class='text-sm'>{{monthAndDay}}</h3>
          <h1 class='text-3xl font-semibold text-gray-900'>{{dayOfWeek}}요일</h1>
          <h1 v-if='daysUntil'
            class='text-sm font-normal'>
            {{daysUntilString}}
          </h1>
        </div>
        <div class='text-sm flex flex-col justify-start'>
          <button
            class='hover:text-red-600 border border-solid border-gray-300 hover:border-red-300 w-24 text-xs mt-2 px-2 py-1 rounded hover:shadow-md'
            @click='cancelNewBookingRequest(bookingRequest.id)'>취소요청</button>
        </div>
      </div>
      <div class='flex flex-col justify-center gap-y-1 py-4 px-6'>
        <div class='flex-shrink-0 flex-grow-0 rounded-md text-sm flex flex-col justify-start items-start gap-y-1'
          style='word-break: keep-all;'>
          <span class='flex flex-row justify-start items-center gap-x-1'><location-marker-icon class='inline-block h-5' /> 장소</span>
          <span class='text-2xl font-semibold text-gray-900'>{{bookingRequest.metaData.facilityName}}</span>
          <div class='flex flex-row justify-start items-center gap-x-2 hidden'>
            <smartphone-icon class='w-5 flex-shrink-0 flex-grow-0' />
            <a href='tel:+82-02-536-0555' class='text-xs'>
              02-536-0555
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class='py-8 px-6 text-base'>
      <div v-for='(arrayOfSlots, key) in bookingsByCourt'
        :key='`${bookingRequest.id}-bookingSlot-${key}`'
        class='flex flex-row justify-start items-start border-b pb-2 mb-2'>
        <label class='w-16'><span class=''>코트</span> {{key}}</label>
          <div class='flex-grow'>
          <div v-for='(slot, key) in sortedAndCombinedSlots(arrayOfSlots)'
            :key='`${bookingRequest.id}-bookingSlot-${key}-slot-for-court-${slot.id}`'
            class='flex flex-row items-center justify-between'>
            <span class='inline-block'>{{slot.startTime}} - {{slot.endTime}}시 {{ timeLengthString(slot) }}</span>
            <span class='inline-block ml-1'>{{priceString(slot.price)}}원</span>
          </div>
        </div>
      </div>
      <div v-if='feeAmount > 0'
        class='flex flex-row justify-between items-start text-sm mb-2'>
        <label class='block'>온라인예약 수수료 (부가세포함)</label>
        <div>
          {{priceString(feeAmount)}}원
        </div>
      </div>
      <div class='text-lg font-semibold flex flex-row justify-between items-center rounded-md pt-2'>
        <span class='w-20'>예약 금액</span>
        <h3 class='text-gray-900'>{{priceString(totalPriceForBookingRequest)}}원</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions }            from 'vuex'
import { LocationMarkerIcon }    from '@vue-hero-icons/outline'
import { SmartphoneIcon }        from 'vue-feather-icons'
import StringHelpers             from '@/utils/string-helpers'
import BookingRequestHelpers     from '@/utils/booking-request-helpers'
import CancellationPolicyHelpers from '@/utils/cancellation-policy-helpers'
import numbro from 'numbro'
import dayjs  from 'dayjs'

export default {
  name: 'PlayerUpcomingBookingRequest',
  components: {
    LocationMarkerIcon,
    SmartphoneIcon,
  },
  props: [
    'bookingRequest',
    'dateCategory',
  ],
  computed: {
    monthAndDay () {
      return (this.bookingRequest.metaData.playDate) ? StringHelpers.monthDayWithKorean(this.bookingRequest.metaData.playDate) : ''
    },
    dayOfWeek () {
      return (this.bookingRequest.metaData.playDate) ? StringHelpers.dayOfWeekKorean(this.bookingRequest.metaData.playDate) : ''
    },
    daysUntil () {
      return dayjs(this.bookingRequest.metaData.playDate).diff(new Date(), 'day') + 1
    },
    daysUntilString () {
      return `${this.daysUntil}일 전`
    },
    totalPriceForBookingRequest () {
      let totalPrice = parseInt(this.bookingRequest.bookingsTotalPrice) / parseInt(this.bookingRequest.repeatCount) + parseInt(this.feeAmount)
      return totalPrice
    },
    bookingsByCourt () {
      return BookingRequestHelpers.requestBookingsByCourt(this.bookingRequest.schedules)
    },
    bookingDateTime () {
      return this.bookingRequest.paidAt ? dayjs(this.bookingRequest.paidAt).format() : dayjs(this.bookingRequest.createdAt).format()
    },
    playDateTime () {
      return dayjs(`${this.bookingRequest.metaData.playDate} ${this.bookingRequest.metaData.minStartTime}`).format()
    },
    feeAmount () {
      return this.bookingRequest.feeAmount ? parseInt(this.bookingRequest.feeAmount) : 0
    },
    refundPercentage () {
      return CancellationPolicyHelpers.cancellationRefundPercentage(this.bookingDateTime, this.playDateTime)
    },
    refundFeePercentage () {
      return 100 - this.refundPercentage
    },
    refundAmount () {
      return parseInt(this.totalPriceForBookingRequest) * parseInt(this.refundPercentage) / 100
    },
  },
  methods: {
    ...mapActions('paymentsKakao', [
      'requestKakaoPaymentCancel'
    ]),
    ...mapActions('paymentsToss', [
      'requestTossPaymentCancel'
    ]),
    sortedAndCombinedSlots (slots) {
      return BookingRequestHelpers.combinedSlots(slots)
    },
    timeLengthString (slot) {
      let length = parseInt(slot.endTime) - parseInt(slot.startTime)
      return (length > 1) ? `(${length}시간)` : ''
    },
    priceString (price) {
      return numbro(price).format({trimMantissa: true, thousandSeparated: true})
    },
    cancelKakaoPayment (newBookingRequestId) {
      let text = this.refundFeePercentage ? `결제금액의 ${this.refundFeePercentage}% 취소 수수료가 발생합니다. 예약 취소를 요청하겠습니까?` : '예약 취소를 요청하겠습니까?'
      this.$confirm(text, {
        confirmButtonText: '취소 요청',
        cancelButtonText: '아니요',
        type: 'warning'
      }).then(() => {
        this.requestKakaoPaymentCancel({ orderId: newBookingRequestId,
                                         cancelParams: { refund_amount: this.refundAmount, reason_for_cancellation: '사용자 취소' }}).then(() => {
          this.$alert('예약이 취소되었습니다.', {
            type: 'success'
          })
        }).catch((error) => {
          console.error(error)
          this.$alert('예약취소가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch(() => {})
    },
    cancelTossPayment (newBookingRequestId) {
      let text = this.refundFeePercentage ? `결제금액의 ${this.refundFeePercentage}% 취소 수수료가 발생합니다. 예약 취소를 요청하겠습니까?` : '예약 취소를 요청하겠습니까?'
      this.$confirm(text, {
        confirmButtonText: '취소 요청',
        cancelButtonText: '아니요',
        type: 'warning'
      }).then(() => {
        this.requestTossPaymentCancel({ orderId: newBookingRequestId,
                                        cancelParams: { refund_amount: this.refundAmount, reason_for_cancellation: '사용자 취소' }}).then(() => {
          this.$alert('예약이 취소되었습니다.', {
            type: 'success'
          })
        }).catch((error) => {
          console.error(error)
          this.$alert('예약취소가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch(() => {})
    },
    cancelNewBookingRequest (newBookingRequestId) {
      if (this.bookingRequest.paidWith === 'kakao_payment') {
        this.cancelKakaoPayment(newBookingRequestId)
      } else if (this.bookingRequest.paidWith === 'toss_payment') {
        this.cancelTossPayment(newBookingRequestId)
      } else {
        console.error('cancel new booking request error')
      }
    }
  },
}
</script>
