<template>
  <tbody class='border-t'>
    <tr>
      <td>코트 {{courtNameFromId(parseInt(courtId))}}</td>
      <td class='pb-1 px-2 text-left'>{{courtTimeString(sortedBookings[0])}}</td>
      <td class='pb-1 pl-2 text-right'>{{priceString(sortedBookings[0])}}</td>
    </tr>
    <tr v-for='(booking, index) in notFirstRowBookings'
      :key='`court-booking-time-row-${index}`'>
      <td></td>
      <td class='pb-1 px-2 text-left'>{{courtTimeString(booking)}}</td>
      <td class='pb-1 pl-2 text-right'>{{priceString(booking)}}</td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import numbro    from 'numbro'

export default {
  name: 'BookingsByDateThenCourt',
  props: [
    'bookings',
    'courtId',
  ],
  computed: {
    ...mapGetters('courts', [
      'courtNameFromId'
    ]),
    sortedBookings () {
      return cloneDeep(this.bookings).sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime))
    },
    notFirstRowBookings () {
      return this.sortedBookings.slice(1)
    },
  },
  methods: {
    courtTimeString (booking) {
      return `${parseInt(booking.startTime)} - ${parseInt(booking.endTime)}시`
    },
    priceString (booking) {
      return (booking && booking.price) ? numbro(booking.price).format({thousandSeparated: true}) : '-'
    },
  }
}
</script>

<styles lang='scss'>

</styles>
