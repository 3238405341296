<template>
  <div class='text-left'>
    <div id='CalendarContainer'
      class='px-4 lg:px-4 pt-8 pb-16 relative'>
      <player-bookings-nav-select-date
        @updated-date='updateScheduleData' />
      <bookings-nav-player-refresh-control />
      <facility-contact-card 
        :facility-data='showingFacilityContactCardData'
        class='p-4 mt-4' />
      <button 
        @click='$modal.show("facility-selector-modal")'
        class='w-full mt-4 border py-3 rounded hover:shadow-md uppercase'>
        코트 변경
      </button>
      <button
        @click='displayContentOnMobile'
        class='block lg:hidden absolute bottom-4 hover:shadow-md rounded-md py-2 mt-4 text-sm border uppercase text-gray-700 text-center'
        style='width: calc(100% - 2rem);'>
        닫기
    </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import FacilityContactCard             from '@/components/FacilityContactCard.vue'
import BookingsNavPlayerRefreshControl from '@/views/player-bookings/BookingsNavPlayerRefreshControl.vue'
import PlayerBookingsNavSelectDate     from '@/views/player-bookings/PlayerBookingsNavSelectDate.vue'
import dayjs from 'dayjs'

export default {
  name: 'BookingsNavPlayer',
  components: {
    PlayerBookingsNavSelectDate,
    BookingsNavPlayerRefreshControl,
    FacilityContactCard,
  },
  watch: {
    facilityAndDateUpdateTrigger: {
      handler (newVal, oldVal) {
        if (newVal !== oldVal && this.initialDataTrigger) {
          this.updateScheduleData()
        } else {
          console.log('dont need to get new bookings')
        }
      },
      immediate: true
    },
    initialDataTrigger: {
      handler (newVal) {
        if (newVal) {
          this.updateScheduleData()
        } else {
          console.log('waiting to ensure user has profile')
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('playerProfile', [
      'hasMyProfile',
    ]),
    ...mapGetters('facilities', [
      'currentFacilityId',
      'showingFacilityContactCardData',
    ]),
    ...mapState('bookings', [
      'bookingsDate',
      'scheduleSelectedDay',
    ]),
    ...mapState('facilities', [
      'facilities',
    ]),
    currentlySelectedDate () {
      return dayjs(this.bookingsDate).format('M.D')
    },
    initialDataTrigger () {
      return this.hasMyProfile && 
             this.currentFacilityId
    },
    facilityAndDateUpdateTrigger () {
      return `facilityId:${this.currentFacilityId}, date:${this.scheduleSelectedDay}`
    },
  },
  methods: {
    ...mapActions([
      'hideContentOnMobile',
      'displayContentOnMobile',
      'sidepanelClose',
      'deactiveLoading',
    ]),
    ...mapActions('bookings', [
      'getPlayerUnbookableTimes',
    ]),
    ...mapMutations('manageRequests', [
      'resetNewBookingRequestSlotList',
    ]),
    updateScheduleData () {
      this.resetNewBookingRequestSlotList()
      this.displayContentOnMobile()
      this.sidepanelClose()
      if (this.hasMyProfile) {
        this.getPlayerUnbookableTimes().then(() => {
          this.$nextTick(() => {
            this.deactiveLoading()
          })
        })
      } else {
        console.log('dont have profile yet')
      }
    }
  },
}
</script>
