<template>
  <div class='login-container viewport-size background-image-full' style='background-image: url("player_hero.png");'>
    <section class='viewport-size'>
      <div class='viewport-size flex flex-col justify-center'>
        <div class='hero-content mx-auto z-10 text-left flex flex-col gap-x-16'>
          <div>
            <logo height='5rem' is-white-version='true' />
            <h4 class='text-white my-8 my-16 text-xl leading-7'>
              Find a time to play.<br>
              Book a time to play.<br>
              Just Tennis Play!
            </h4>
          </div>
          <div class='flex flex-col lg:justify-start items-center lg:items-start gap-y-8'>
            <div class='flex flex-col lg:flex-row justify-start items-center gap-y-4 lg:gap-x-4 border-gray-600'>
              <button @click='openLoginModal'
                class='border-2  py-3 rounded-lg hover:shadow-md opacity-90 hover:opacity-100 font-semibold flex flex-row justify-center items-center gap-x-4'
                style='background-color: #E8F5FB; border-color: #1C6383; width:300px;'>
                <mail-icon class='inline-block' />
                <span>이메일 로그인</span>
              </button>
              <div class='text-white text-center w-full opacity-50 text-sm hidden'>또는</div>
              <kakao-login :api-key='apiKey' :on-failure='onFailure' :on-success='kakaoOnSuccess'
                class='opacity-90 hover:opacity-100 cursor-pointer flex flex-row justify-center'
                image='kakao_account_login_btn_medium_wide' />
            </div>
            <div class='text-white text-sm'>
              <button @click='openSignupForm'
                class='border-2 px-1 py-2 text-sm border-transparent rounded-md bg-gray-800 hover:shadow-md font-semibold text-white opacity-80 hover:opacity-100'
                style=''>회원가입</button>하고 간편한 코트예약하세요
            </div>
          </div>
          <login-form />
          <signup-form />
        </div>
      </div>
    </section>
    <middle-content-section section-index='1'>
      <p>
        Quickly find available times to book visually, with the latest, up-to-date information.
        Using your web browser, you can easily see what times are available, and see which courts
        may be booked together.
      </p>
      <p class='mt-8'>
        With the latest data from the courts, you are able to make sure that the courts you want, are there for you.
      </p>
    </middle-content-section>
    <middle-content-section section-index='2'>
      <p>
        Easily select the times that work for you and your friends. Know the price immediately,
        and book with confidence knowing you have the courts reserved.
      </p>
    </middle-content-section>
    <middle-content-section section-index='3'>
      <p>
        See your past reservations and confirm your upcoming court times without having to double check by calling
        the court managers!
      </p>
    </middle-content-section>
    <section
      class='viewport-size background-image-full flex flex-col justify-between items-center text-gray-100 relative'
      style='background-image:url("bg_footer_image.png");'>
      <div class='glass-container p-8 lg:p-24 flex flex-col lg:flex-row items-center justify-around mx-auto'
        style='width: 80vw; height: 50vh; max-height:  800px; margin-top: calc(20vh);'>
        <div class='w-full lg:w-1/2 flex-shrink-0 p-4 lg:p-12'>
          <div
            class='flex flex-row justify-start items-center whitespace-nowrap uppercase text-lg lg:text-2xl font-semibold'>
            About Us
          </div>
        </div>
        <div class='w-full lg:w-1/2 flex-shrink-0 p-4 lg:p-12 text-left text-gray-100 text-sm lg:text-lg'>
          The Tennis Play team hopes to bring tennis to as many people as possible. Letting Court managers quickly and
          easily manage their reservations digitally, we firmly believe that we can let more tennis players have more
          opporutnities to have access to the sport, and grow the game for everyone.
        </div>
      </div>
      <div class='h-1/4 flex flex-row justify-between text-xs items-end pb-16' style='width: 80vw;'>
        <div class='text-left'>
          <logo height='1.5rem' is-white-version='true' />
          <h1 class='mt-1'>포스비위드유(651-05-02704)</h1>
          <router-link :to='{ name: "CancellationPolicy" }'>테니스플레이 온라인약관</router-link>
        </div>
        <div class='text-right text-gray-300'>
          <h1>대표자: 이건우</h1>
          <h4>
            사업장 주소: 서울특별시 영등포구 문래동 2가 35<br>
            통신판매업신고: 제 2022-서울영등포-3142호<br>
          </h4>
          <h4>고객센터 : 070-4571-5681</h4>
          <h4><a href='mailto:thetennisplay@gmail.com'>thetennisplay@gmail.com</a></h4>         
          <h4>Ⓒ 포스위드유 All rights reserved</h4>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions }       from 'vuex'
import { MailIcon }         from '@vue-hero-icons/outline'
import ssoSessionsApi       from '@/api/v1/users/api-sso-sessions.js'
import KakaoLogin           from 'vue-kakao-login'
import Logo                 from '@/components/Logo'
import LoginForm            from '@/components/LoginForm'
import SignupForm           from '@/components/SignupForm'
import MiddleContentSection from '@/views/login/MiddleContentSection'


export default {
  name: 'Login',
  components: {
    MiddleContentSection,
    MailIcon,
    Logo,
    LoginForm,
    SignupForm,
    KakaoLogin,
  },
  data () {
    return {
      apiKey: process.env.VUE_APP_KAKAO_JS_KEY
    }
  },
  methods: {
    openLoginModal () {
      this.$modal.show('login-modal')
    },
    openSignupForm () {
      this.$modal.show('signup-modal')
    },
    ...mapActions([
      'setLocalStorageData',
    ]),
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
    kakaoOnSuccess (data) {
      ssoSessionsApi.create('kakao', { access_token: data.access_token }).then(resp => {
        this.onSuccess(resp)
      }).catch((error) => {
        if (error.response.status === 409) {
          this.$alert('이미 회원가입된 이메일입니다. 이메일을 확인 후 다시 회원가입해주세요.', {
            confirmButtonText: '확인',
            type: 'warning'
          })
        }
      })
    },
    onSuccess (resp) {
      localStorage.setItem('auth._token.tennis_play', resp.data.access_token)
      localStorage.setItem('role', resp.data.role)
      localStorage.setItem('userId', resp.data.user_id)
      this.setLocalStorageData()
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    onFailure (data) {
      console.error(data)
      this.$notify({
        title: 'Login Error',
        message: data.response.data[0].message,
        type: 'warning',
        position: 'top-right'
      })
    },
  },
  mounted () {
    this.checkTokenStatus().then(resp => {
      if (resp && resp.data.isTokenValid) {
        this.$router.push({name: 'Home'}).catch(() => {})
      }
    })
  }
}
</script>

<style lang='scss'>
.login-container {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
}

.login-container section {
  scroll-snap-align: start;
  position: relative;
  scroll-snap-stop: always;
}

.player-hero {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}


.viewport-size {
  width: 100vw;
  height: 100vh;
}

.background-image-full {
  background-repeat: no-repeat; 
  background-position: center; 
  background-size: cover;
}

.screenshot-shadow {
  box-shadow:
    2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07)
  ;
}


.glass-container {
  background: rgba(255, 255, 255, 0.09);
  border-radius: 4px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  border: 1px solid rgba(255, 255, 255, 0.08);
}

@media (min-width: 1024px) {
  .hero-content {
    padding-right: 40%;
    padding-bottom: 10%;
  }

}

</style>
