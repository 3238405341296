<template>
  <tbody class='border-t'>
    <tr class='text-sm'>
      <td class='py-2 lg:py-4 px-1'></td>
      <td class='py-2 lg:py-4 px-1'>{{priceFormat.label}}({{dayOfWeekString}})</td>
      <td class='py-2 lg:py-4 px-1'>
        <input v-model='priceFormat.startTime'
          type='number'
          class='w-8 lg:w-12 rounded border-gray-200 p-1 text-right'>시 
      </td>
      <td class='py-2 lg:py-4'> ~ </td>
      <td class='py-2 lg:py-4 px-1'>
        <input v-model='priceFormat.endTime'
          type='number'
          class='w-8 lg:w-12 rounded border-gray-200 p-1 text-right'>시
      </td>
      <td class='py-2 lg:py-4 px-1'>
        <input v-model='priceFormat.price'
          type='number'
          class='w-16 lg:w-20 rounded border-gray-200 p-1 text-right'>원
      </td>
    </tr>
  </tbody>
</template>
<script>
import StringHelpers  from '@/utils/string-helpers'

export default {
  name: 'PriceForLabel',
  props: ['priceFormat'],
  computed: {
    dayOfWeekString () {
      let string = this.priceFormat.dayOfWeeks.map(dayOfweek => StringHelpers.dayOfWeekNumberToKorean(dayOfweek)).join(', ')
      return string
    },
  }
}
</script>
