<template>
  <div class='pt-8 pb-24 lg:max-w-3xl'>
    <div class='px-8 text-left'>
      <h1 class='font-semibold text-2xl mb-8'>결제가 완료되었습니다.</h1>
      <el-steps :active='2' align-center class='mb-8 player-booking-flow'>
        <el-step title='코트 선택'></el-step>
        <el-step title='정보 확인 & 결제'></el-step>
        <el-step title='예약 완료'></el-step>
      </el-steps>
      <player-new-booking-request-card />
    </div>
    <div class='fixed flex flex-row gap-x-8 bottom-0 py-4 px-8 bg-gray-100 w-full'>
      <button class='block text-sm py-3 px-4 border border-sold border-transparent hover:border-gray-300 hover:shadow-md rounded-md'
        @click='goToScheduleBooking'>
        다른 예약하러 가기
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PlayerNewBookingRequestCard from '@/components/PlayerNewBookingRequestCard'

export default {
  name: 'PaymentFinished',
  components: {
    PlayerNewBookingRequestCard,
  },
  methods: {
    ...mapActions('payments', [
      'resetCurrentOrder',
    ]),
    goToScheduleBooking () {
      this.$router.push({name: 'PlayerScheduleBookings' })
    },
  },
  beforeDestroy () {
    this.resetCurrentOrder()
  }
}
</script>

<style lang='scss'>
  .player-booking-flow .el-step__title.is-finish,
  .player-booking-flow .el-step__description.is-finish,
  .player-booking-flow .el-step__head.is-finish,
  .player-booking-flow .el-step__head.is-finish .el-step__line,
  .player-booking-flow .el-step__title.is-process,
  .player-booking-flow .el-step__description.is-process,
  .player-booking-flow .el-step__head.is-process {
    color: #166cc5;
    border-color: #166cc5;
  }
</style>
