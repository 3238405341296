<template>
  <div>
    <v-select v-if='isFindingProfile'
      v-model='playerProfile'
      @search='searchPlayer'
      :value='searchText'
      @option:selected='selectProfile'
      :options='selectablePlayers'
      :loading='loadingProfiles'
      :filterable='false'
      label='name'>
      <template v-slot:option='profile'>
        <li class='flex flex-row justify-between items-center py-1 text-sm'>
          <div>{{ profile.name }}</div>
          <div class='opacity-75 tabular-nums text-left'>{{profile.phone_number}}</div>
        </li>
      </template>
      <template v-slot:spinner>
        <i v-if='loadingProfiles' class='el-icon-loading p-2' />
      </template>
      <template #list-footer="{ search }">
        <li @click='openCreateProfile(search)'
          class='px-2 py-1 mt-2 bg-gray-50 hover:bg-blue-500 text-gray-700 hover:text-white cursor-pointer'>
          {{search}} 
          <span class='opacity-75 inline-block ml-2'>(프로필 추가)</span>
        </li>
      </template>
    </v-select>
    <div v-else
      class='p-4 border mb-4 shadow-lg rounded-lg'>
      <h2 class='text-xl font-medium text-gray-900'>프로필 추가</h2>
      <div class='flex flex-col items-start mt-4'>
        <label class='uppercase text-sm text-gray-600 w-1/3'>이름</label>
        <input 
          type='text'
          class='form-input rounded border-gray-300 w-1/2' 
          v-model='newProfileName'/>
      </div>
      <div class='flex flex-col items-start mt-4'>
        <label class='uppercase text-sm text-gray-600 w-1/3'>전화번호</label>
        <vue-phone-number-input 
          class='form-input rounded border-gray-300 w-full' 
          v-model='newProfilePhoneNumber'
          default-country-code='KR'
          :no-country-selector='true'
          :no-example='true' 
          :translations='phoneNumberInputTranslations'
          @update='checkValidPhoneNumber' />
      </div>
      <div class='flex flex-col items-start mt-4'>
        <label class='uppercase text-sm text-gray-600 w-1/3'>유형</label>
        <select class='form-input rounded border-gray-300 w-1/2'
                v-model='newProfileClientType'>
          <option value='regular'>정기회원</option>
          <option value='general'>일반회원</option>
        </select>
      </div>
      <div class='flex flex-row justify-start gap-x-2 mt-4'>
        <button 
          @click='createNewProfile'
          class='create-profile-button'
          :disabled='disabledManagerCreateProfile'>
          추가
        </button>
        <button 
          @click='cancelCreate'
          class='py-2 px-4 text-sm rounded border border-transparent hover:border-red-300 text-gray-500 hover:text-red-600'>
          취소
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import debounce            from 'debounce'
import VuePhoneNumberInput from 'vue-phone-number-input'
import StringHelpers       from '@/utils/string-helpers'
import { mapGetters, mapActions }      from 'vuex'

export default {
  name: 'SelectOrCreateProfile',
  components: {
    VuePhoneNumberInput,
  },
  data () {
    return {
      selectablePlayers: [],
      playerProfile: null,
      loadingProfiles: false,
      isFindingProfile: true,
      searchText: '',
      newProfileName: '',
      newProfilePhoneNumber: '',
      newProfileClientType: '',
      isValidPhoneNumber: false,
      phoneNumberInputTranslations: {
        phoneNumberLabel: ''
      },
    }
  },
  computed: {
    ...mapGetters('facilities', [
      'currentFacilityId'
    ]),
    newPlayerParams () {
      return {
        'name':         this.newProfileName,
        'phone_number': this.newProfilePhoneNumber
      }
    },
    disabledManagerCreateProfile () {
      return !this.newProfileName || !this.isValidPhoneNumber || !this.newProfileClientType
    }
  },
  methods: {
    ...mapActions('managePlayers', [
      'findPlayerProfile',
      'createProfileByManager',
      'addProfileToClientList'
    ]),
    searchPlayer (text) {
      this.loadingProfiles = true
      let searchString = (StringHelpers.isSingleKoreanConsonant(text.slice(-1))) ? text.substring(0, text.length-1) : text
      if (searchString) {
        this.findPlayerProfile(searchString).then(resp => {
          this.selectablePlayers = resp.profiles
          this.loadingProfiles = false
        })
      } else {
        this.loadingProfiles = false
      }
    },
    selectProfile (profile) {
      this.$emit('update-selected-profile', profile.id)
    },
    openCreateProfile (newPlayerName) {
      this.newProfileName   = newPlayerName
      this.isFindingProfile = false
    },
    createNewProfile () {
      this.createProfileByManager(this.newPlayerParams).then(newProfile => {
        let facilityProfileParams = {
          facility_profile: {
            facility_id: this.currentFacilityId,
            profile_id: newProfile.id,
            is_active: true,
            client_type: this.newProfileClientType
          }
        }
        this.addProfileToClientList(facilityProfileParams)
        this.playerProfile = newProfile
        this.selectProfile(newProfile)
         this.isFindingProfile = true
      })
    },
    cancelCreate () {
      this.isFindingProfile = true
    },
    checkValidPhoneNumber (val) {
      this.isValidPhoneNumber = val.isValid
    }
  },
  created () {
    this.searchPlayer = debounce(this.searchPlayer, 500)
  },
}
</script>

<style lang='scss' scoped>
button.create-profile-button {
  @apply py-2 px-8 text-sm rounded bg-gray-900 text-white opacity-75 cursor-pointer;
}

button.create-profile-button:hover {
  @apply opacity-100 shadow-md;
}

button.create-profile-button:disabled,
button.create-profile-button[disabled]{
  @apply opacity-25 cursor-not-allowed;
}
</style>
