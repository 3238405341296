<template>
  <tbody>
    <tr class='border-t'>
      <td class='py-3 px-2'>
        {{courtNameFromId(newBookingRequest.courtId)}}
      </td>
      <td class='py-3 px-2'>
        {{bookingStartDateString}}
      </td>
      <td v-if='!hideEndDate' class='py-3'>{{bookingEndDateString}}</td>
      <td class='py-3 px-2'>{{newBookingRequest.startTime}}시</td>
      <td class='py-3 px-2 text-right'>
        <button v-if='hasBookingsOnRepeatedDates && !isNewBookingRequestTypeExtension'
          @click='removeNewBookingRequestOnList'>
          <x-circle-icon class='text-red-400 hover:text-red-800 h-6' />
        </button>
        <span v-else>{{bookingPriceString}}</span>
      </td>
    </tr>
    <tr v-if='hasBookingsOnRepeatedDates'>
      <td class='py-2 px-2' colspan='5'>
        <div v-for='booking in bookingsOnRepeatedDates'
           :key='`booking-${booking.id}`'
           class='text-red-400 text-xs w-full'>
          <div>
            *{{booking.bookingDate}} {{parseInt(booking.startTime)}}시~{{parseInt(booking.endTime)}}시 예약이 있어 반복예약이 불가합니다.
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapGetters } from 'vuex'
import { XCircleIcon }      from '@vue-hero-icons/outline'
import StringHelpers  from '@/utils/string-helpers'
import dayjs          from 'dayjs'
import bookingsApi    from '../../../src/api/v1/bookings'
import numbro         from 'numbro'


export default {
  name: 'AddNewBookingRequestRow',
  props: [
    'newBookingRequest',
    'repeatCount',
    'newBookingRequestType',
    'checkForRepeatDates',
    'hideEndDate',
  ],
  components: {
    XCircleIcon
  },
  data () {
    return {
      bookingsOnRepeatedDates: [],
    }
  },
  watch: {
    repeatCount: {
      handler: function (newVal) {
        if (this.checkForRepeatDates) {
          if (newVal) {
            this.showBookingsOnRepeatedDates(this.bookingDataForCheckRepeatable)
          } else if (parseFloat(newVal) == 1) {
            this.resetBookingsOnRepeatedDates()
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('facilities', [
      'selectedManagedFacilityId',
    ]),
    ...mapGetters('courts',[
      'courtNameFromId',
    ]),
    ...mapGetters('prices', [
      'priceForNewBookingRequest'
    ]),
    bookingDataForCheckRepeatable () {
      let dateRange = []
      let numOfWeeks = this.repeatCount
      for(var i = 0; i < parseInt(numOfWeeks); i++) {
        dateRange.push(dayjs(this.newBookingRequest.bookingDate).add(i, 'week').locale('kr').format('YYYY-MM-DD'))
      }
      return {
        courtId:   this.newBookingRequest.courtId,
        dateRange: dateRange,
        startTime: this.newBookingRequest.startTime,
        endTime:   this.newBookingRequest.endTime,
      }
    },
    isNewBookingRequestTypeExtension () {
      return this.newBookingRequestType === 'reservedExtension'
    },
    hasBookingsOnRepeatedDates () {
      return this.bookingsOnRepeatedDates.length > 0
    },
    bookingDayOfWeekString () {
      return StringHelpers.dayOfWeekKorean(this.newBookingRequest.bookingDate) 
    },
    bookingStartDateString () {
      return `${dayjs(this.newBookingRequest.bookingDate).locale('kr').format('YY.MM.DD')}`
    },
    bookingEndDateString () {
      return `${dayjs(this.newBookingRequest.bookingDate).add(parseInt(this.repeatCount)-1, 'week').locale('kr').format('YY.MM.DD')}`
    },
    bookingPriceString () {
      return numbro(this.priceForNewBookingRequest(this.newBookingRequest)).format({trimMantissa: true, thousandSeparated: true})
    },
  },
  methods: {
    showBookingsOnRepeatedDates () {
      const camelcaseKeys = require('camelcase-keys')
      return new Promise(resolve => {
        bookingsApi.getBookingsOnRepeatedDate(this.selectedManagedFacilityId,
                                              this.bookingDataForCheckRepeatable.courtId,
                                              this.bookingDataForCheckRepeatable.dateRange,
                                              this.bookingDataForCheckRepeatable.startTime,
                                              this.bookingDataForCheckRepeatable.endTime).then(resp => {
          this.bookingsOnRepeatedDates = camelcaseKeys(resp.bookings)
          this.$emit('stack-create-new-booking-request-available', { courtId:     this.newBookingRequest.courtId,
                                                                     bookingDate: this.newBookingRequest.bookingDate,
                                                                     startTime:   this.newBookingRequest.startTime,
                                                                     status:      this.hasBookingsOnRepeatedDates })
          resolve()
        })
      })
    },
    removeNewBookingRequestOnList () {
      this.$emit('remove-new-booking-request-on-list', this.newBookingRequest)
    },
    resetBookingsOnRepeatedDates () {
      this.bookingsOnRepeatedDates = []
    }
  }
}
</script>