<template>
  <div class='flex flex-row justify-start items-stretch gap-x-4 mb-4'>
    <div class='bg-gray-50 pl-2 pr-6 py-2'>{{bookingDate}}</div>
    <div class='flex-grow py-2'>
      <table class='w-full'>
        <bookings-by-date-then-court
          v-for='(bookings, courtId) in bookingsByCourtId'
          :key='`${bookingDate}-${courtId}`'
          :bookings='bookings'
          :court-id='courtId'/>
      </table>
    </div>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy'
import BookingsByDateThenCourt from '@/views/manage-players/BookingsByDateThenCourt'


export default {
  name: 'PlayerDrilldownRowSingleDay',
  components: {
    BookingsByDateThenCourt
  },
  props: [
    'bookings',
    'bookingDate'
  ],
  computed: {
    bookingsByCourtId () {
      return groupBy(this.bookings, 'courtId')
    }
  },
}
</script>
