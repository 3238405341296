import base from './base'

const tossPaymentSuccess = (orderId, paymentParams) => {
  return base.patch(`/toss_payments/${orderId}/success`, paymentParams).then(resp => resp.data)
}

const tossPaymentCancel = (orderId, cancelParams) => base
  .patch(`/toss_payments/${orderId}/cancel`, cancelParams).then(resp => resp.data)

export default {
  tossPaymentSuccess: tossPaymentSuccess,
  tossPaymentCancel: tossPaymentCancel
}
