<template>
  <transition name='fade'>
    <div v-if='showSidepanel'
      class='sidepanel fixed top-0 right-0 h-full bg-white shadow-2xl pt-8 pb-24 px-4 md:px-8 text-left z-30 overflow-y-auto'>
      <x-icon @click='sidepanelClose' class='cursor-pointer fixed z-20' style='top: 1rem; right: 1rem;' />
      <slot />
      <component :is='sidepanelContentComponent'
                 @done-edit='sidepanelClose' />
      <!-- <loading-indicator    v-if='isLoading'></loading-indicator> -->
      <!--  <div v-if='isLoading' class='overlay' :class='displayedCSS'></div> -->
      <div class='fixed bottom-0 right-0 bg-gray-100 sidepanel-footer-container px-8 flex flex-row items-center'>
        <portal-target name='sidepanel-footer' class='w-full'></portal-target>
      </div>
    </div>

  </transition>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { XIcon }                from '@vue-hero-icons/outline'
import ExistingBooking           from '@/components/bookings/ExistingBooking'
import BlockBooking              from '@/components/bookings/BlockBooking'
import ExistingBookingView       from '@/components/bookings/ExistingBookingView'
import PlayerDrilldown           from '@/views/manage-players/PlayerDrilldown'
import ManagerNewBookingRequest  from '@/views/bookings/ManagerNewBookingRequest'
import PlayerNewBookingRequest   from '@/views/bookings/PlayerNewBookingRequest'

export default {
  name: 'Sidepanel',
  components: {
    BlockBooking,
    ExistingBooking,
    PlayerNewBookingRequest,
    PlayerDrilldown,
    ManagerNewBookingRequest,
    ExistingBookingView,
    XIcon
  },
  computed: {
    ...mapState([
      'showSidepanel',
      'sidepanelContentComponent'
    ]),
    ...mapState('bookings', [
      'showNewBooking',
      'showExistingBooking',
      'showBlockedBooking',
    ]),
    displayedCSS () {
      return (this.isLoading) ? 'displayed' : ''
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
  },
}
</script>
<style lang='scss'>
.sidepanel-footer-container {
    width: 100%;
  }

@media (min-width: 1024px) {
  .sidepanel-footer-container {
    width: 30rem;
  }
}
</style>

<style scoped lang='scss'>
.fade-enter-active,
.fade-leave-active {
  transition: all .2s;
}

div.fade-enter,
div.fade-leave-to {
  opacity: 0;
  right: -420px;
}

.overlay {
  @apply bg-cover absolute top-0 left-0 h-full w-full z-20;
  background-color: #223645;
  transition: opacity 0.2s ease-in-out;
  transition: visibility 0.2s ease-in-out;
}
.overlay.displayed {
  opacity: 0.8;
}

.sidepanel {
  width: 100%;
}

@media (min-width: 1024px) {
  .sidepanel,
  .sidepanel-footer-container {
    width: 30rem;
  }
}

</style>