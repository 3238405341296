<template>
  <img :src='`${publicPath}/${whiteOrBlackLogo}`' class='inline-block' :style='heightStyle' />
</template>

<script>

export default {
  name: 'Logo',
  props: [ 
    'height',
    'isWhiteVersion',
  ],
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    heightStyle () {
      return (this.height) ? `height: ${this.height}` : 'height: 2rem'
    },
    whiteOrBlackLogo () {
      return (this.isWhiteVersion) ? 'logo_tennis_play_white.png' : 'logo_tennis_play.png'
    },
  }
}
</script>
