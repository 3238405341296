import base from './base'

const kakaoPaymentReady = (orderId) => base
  .get(`/kakao_payments/${orderId}/ready`).then(resp => resp.data)

const kakaoPaymentSuccess = (orderId, orderParams) => base
  .patch(`/kakao_payments/${orderId}/success`, orderParams).then(resp => resp.data)

const kakaoPaymentCancel = (orderId, cancelParams) => base
  .patch(`/kakao_payments/${orderId}/cancel`, cancelParams).then(resp => resp.data)

export default {
  kakaoPaymentReady: kakaoPaymentReady,
  kakaoPaymentSuccess: kakaoPaymentSuccess,
  kakaoPaymentCancel: kakaoPaymentCancel,
}
