<template>
  <div class='text-center py-32 px-8'>
    <i class='el-icon-loading text-6xl mb-4'></i>
    <div class='font-semibold text-2xl mb-24'>결제가 진행중입니다.</div>
    <div>잠시만 기다려주세요.<br>결제 진행중에 페이지를 닫으시거나 다른페이지로 이동하시면, 오류가 발생할 수 있습니다.</div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PaymentSuccess',
  watch: {
    'isCurrentOrderDone': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal) {
          this.resetQueryParams()
          this.$router.replace({name: 'PaymentFinished'})
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('payments', [
      'isCurrentOrderDone',
    ]),
    tossOrderParams () {
      return {
        order_id: this.$route.query.orderId.split('-')[2],
        payment_key: this.$route.query.paymentKey,
        amount: this.$route.query.amount
      }
    },
    kakaoOrderParams () {
      return {
        order_id: this.$route.query.order_id.split('-')[2],
        pg_token: this.$route.query.pg_token
      }
    }
  },
  methods: {
    ...mapActions('paymentsKakao', [
      'requestKakaoPaymentSuccess'
    ]),
    ...mapActions('paymentsToss', [
      'requestTossPaymentOnSuccess',
    ]),
    alertPaymentErrorMessage () {
      this.$alert('결제가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
        type: 'warning',
        showClose: false,
      }).then(() => {
        this.$router.replace({name: 'PlayerScheduleBookings'}).catch(() => {})
      })
    },
    resetQueryParams () {
      let query = Object.assign({}, this.$route.query)
      delete query.order_id
      delete query.orderId
      delete query.paymentKey
      delete query.pg_token
      delete query.amount
      delete query.pay_with
      this.$router.replace({ query })
    },
  },
  mounted () {
    if (this.$route.query.pay_with === 'kakao_payment') {
      this.requestKakaoPaymentSuccess(this.kakaoOrderParams).then(() => {
      }).catch((error) => {
        console.error('error: ', error)
        this.resetQueryParams()
        if (error.response.status === 409) {
          let message = '결제가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.'
          if (error.response.data.error === 'AlreadyReservedSchedulesError') {
            message = '이미 예약되거나 결제중인 시간이 포함되어 있습니다. 다른 시간을 선택해주세요.'
          } else if (error.response.data.error === 'PaymentTimeoutError') {
            message = '세션 유효기간이 초과되어 결제사와 연결이 종료되었습니다. 다시 예약을 진행해주세요.'
          }
          this.$alert(message, {
            type: 'warning',
            showClose: false,
          }).then(() => {
            this.$router.replace({name: 'PlayerScheduleBookings'}).catch(() => {})
          })
        } else {
          this.alertPaymentErrorMessage()
        }
      })
    } else if (this.$route.query.pay_with === 'toss_payment') {
      this.requestTossPaymentOnSuccess(this.tossOrderParams).then(() => {
      }).catch((error) => {
        console.error('error: ', error)
        this.resetQueryParams()
        if (error.response.status === 409) {
          let message = '결제가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.'
          if (error.response.data.error === 'AlreadyReservedSchedulesError') {
            message = '이미 예약되거나 결제중인 시간이 포함되어 있습니다. 다른 시간을 선택해주세요.'
          } else if (error.response.data.error === 'PaymentTimeoutError') {
            message = '세션 유효기간이 초과되어 결제사와 연결이 종료되었습니다. 다시 예약을 진행해주세요.'
          }
          this.$alert(message, {
            type: 'warning',
            showClose: false,
          }).then(() => {
            this.$router.replace({name: 'PlayerScheduleBookings'}).catch(() => {})
          })
        } else {
          this.alertPaymentErrorMessage()
        }
      })
    } else {
      this.alertPaymentErrorMessage()
    }
  }
}
</script>
