import Vue       from 'vue'
import Vuex      from 'vuex'

Vue.use(Vuex)

const state = () => ({
  dayData: [
    752000,
    892000,
    1052000,
    752000,
    1052000,
    923000,
    652000,
    652000,
    552000,
    872000,
    752000,
    892000,
    1052000,
    752000,
    1052000,
    923000,
    652000,
    652000,
    552000,
    872000,
    752000,
    892000,
    1052000,
    752000,
    1052000,
    923000,
    652000,
    652000,
    552000,
    872000,
  ],
  dates: [
        "2021-09-01",
        "2021-09-02",
        "2021-09-03",
        "2021-09-04",
        "2021-09-05",
        "2021-09-06",
        "2021-09-07",
        "2021-09-08",
        "2021-09-09",
        "2021-09-10",
        "2021-09-11",
        "2021-09-12",
        "2021-09-13",
        "2021-09-14",
        "2021-09-15",
        "2021-09-16",
        "2021-09-17",
        "2021-09-18",
        "2021-09-19",
        "2021-09-20",
        "2021-09-21",
        "2021-09-22",
        "2021-09-23",
        "2021-09-24",
        "2021-09-25",
        "2021-09-26",
        "2021-09-27",
        "2021-09-28",
        "2021-09-29",
        "2021-09-30",
      ],
  heatMapData: [
        [
          0,
          0,
          12
        ],
        [
          0,
          1,
          14
        ],
        [
          0,
          2,
          16
        ],
        [
          0,
          3,
          18
        ],
        [
          0,
          4,
          22
        ],
        [
          0,
          5,
          24
        ],
        [
          0,
          6,
          24
        ],
        [
          0,
          7,
          24
        ],
        [
          0,
          8,
          0
        ],
        [
          0,
          9,
          0
        ],
        [
          0,
          10,
          0
        ],
        [
          0,
          11,
          0
        ],
        [
          0,
          12,
          0
        ],
        [
          1,
          0,
          0
        ],
        [
          1,
          1,
          2
        ],
        [
          1,
          2,
          2
        ],
        [
          1,
          3,
          2
        ],
        [
          1,
          4,
          15
        ],
        [
          1,
          5,
          7
        ],
        [
          1,
          6,
          10
        ],
        [
          1,
          7,
          10
        ],
        [
          1,
          8,
          11
        ],
        [
          1,
          9,
          7
        ],
        [
          1,
          10,
          11
        ],
        [
          1,
          11,
          0
        ],
        [
          1,
          12,
          0
        ],
        [
          2,
          0,
          0
        ],
        [
          2,
          1,
          1
        ],
        [
          2,
          2,
          1
        ],
        [
          2,
          3,
          0
        ],
        [
          2,
          4,
          14
        ],
        [
          2,
          5,
          13
        ],
        [
          2,
          6,
          9
        ],
        [
          2,
          7,
          15
        ],
        [
          2,
          8,
          7
        ],
        [
          2,
          9,
          8
        ],
        [
          2,
          10,
          6
        ],
        [
          2,
          11,
          0
        ],
        [
          2,
          12,
          0
        ],
        [
          3,
          0,
          0
        ],
        [
          3,
          1,
          2
        ],
        [
          3,
          2,
          0
        ],
        [
          3,
          3,
          2
        ],
        [
          3,
          4,
          0
        ],
        [
          3,
          5,
          3
        ],
        [
          3,
          6,
          0
        ],
        [
          3,
          7,
          0
        ],
        [
          3,
          8,
          0
        ],
        [
          3,
          9,
          1
        ],
        [
          3,
          10,
          3
        ],
        [
          3,
          11,
          3
        ],
        [
          3,
          12,
          0
        ],
        [
          4,
          0,
          0
        ],
        [
          4,
          1,
          1
        ],
        [
          4,
          2,
          1
        ],
        [
          4,
          3,
          0
        ],
        [
          4,
          4,
          11
        ],
        [
          4,
          5,
          7
        ],
        [
          4,
          6,
          7
        ],
        [
          4,
          7,
          9
        ],
        [
          4,
          8,
          5
        ],
        [
          4,
          9,
          11
        ],
        [
          4,
          10,
          12
        ],
        [
          4,
          11,
          0
        ],
        [
          4,
          12,
          0
        ],
        [
          5,
          0,
          0
        ],
        [
          5,
          1,
          1
        ],
        [
          5,
          2,
          0
        ],
        [
          5,
          3,
          3
        ],
        [
          5,
          4,
          2
        ],
        [
          5,
          5,
          0
        ],
        [
          5,
          6,
          2
        ],
        [
          5,
          7,
          1
        ],
        [
          5,
          8,
          0
        ],
        [
          5,
          9,
          1
        ],
        [
          5,
          10,
          2
        ],
        [
          5,
          11,
          1
        ],
        [
          5,
          12,
          0
        ],
        [
          6,
          0,
          0
        ],
        [
          6,
          1,
          5
        ],
        [
          6,
          2,
          6
        ],
        [
          6,
          3,
          10
        ],
        [
          6,
          4,
          12
        ],
        [
          6,
          5,
          5
        ],
        [
          6,
          6,
          0
        ],
        [
          6,
          7,
          0
        ],
        [
          6,
          8,
          0
        ],
        [
          6,
          9,
          0
        ],
        [
          6,
          10,
          0
        ],
        [
          6,
          11,
          0
        ],
        [
          6,
          12,
          0
        ]
      ]
})


const actions = {
  
}

const mutations = {

}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
