<template>
  <div class='lg:flex lg:flex-row lg:items-stretch' style='background-color: #FCFCFC;'>
    <bookings-nav-player v-if='selectedFacilityId'
      class='bookings-nav-player lg:border-r border-gray-100 shadow-xl w-full lg:flex-grow-0 lg:flex-shrink-0 lg:w-96 fixed lg:static z-40 lg:z-0 bg-white'
      :class='bookingsNavPlayerclass' />
    <bookings-schedule v-if='selectedFacilityId'
      class='overflow-x-auto overflow-y-auto lg:flex-grow bookings-container' 
      :class='desktopRightPadding'
      :show-fully-booked-court='false' />
    <facility-selector />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import FacilitySelector   from '@/components/player/FacilitySelector'
import BookingsNavPlayer  from '@/views/player-bookings/BookingsNavPlayer'
import BookingsSchedule   from '@/views/bookings/BookingsSchedule'


export default {
  name: 'PlayerScheduleBookings',
  components: {
    BookingsSchedule,
    BookingsNavPlayer,
    FacilitySelector,
  },
  computed: {
    ...mapState([
      'showSidepanel',
      'showContentOnMobile'
    ]),
    ...mapState('facilities', [
      'selectedFacility',
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityId',
    ]),
    desktopRightPadding () {
      return (this.showSidepanel) ? 'desktop-right-padding' : ''
    },
    bookingsNavPlayerclass () {
      return (this.showContentOnMobile) ? 'hidden' : 'block'
    }
  },
  methods: {
    ...mapActions('facilities', [
      'getFacilities'
    ]),
    ...mapActions('prices', [
      'getPrices'
    ]),
  },
  mounted () {
    this.getFacilities().then(() => {
      if (this.selectedFacility) {
        this.getPrices()
      } else {
        this.$modal.show('facility-selector-modal')
      }
    })
  }
}
</script>

<style lang='scss' scoped>
.bookings-nav-player {
  height: calc(100vh - 4rem);
}

.bookings-container,
.bookings-container.desktop-right-padding {
  padding-right: 0;
}

@media (min-width: 1024px) {
  .bookings-nav-player {
    height: auto;
    display: block;
  }

  .bookings-container.desktop-right-padding {
    padding-right: 30rem;
  }
}
</style>