<template>
    <el-dialog
      :visible.sync='showOnLoadModal'
      :fullscreen='dialogFullscreenStatus'
      :append-to-body='true'
      width='400px'
      class='on-load-modal'>
      <div class='on-load-modal-content relative flex flex-col justify-center items-center bg-gray-50 pb-20'>
        <slot></slot>
        <div class='bg-gray-100 py-3 absolute bottom-0 left-0 right-0'>
          <label class='flex flex-row justify-center items-center gap-x-1 p-2'>
            <input type='checkbox' v-model='saveHideModal' />
            <span>다시 보지않기</span>
          </label>
        </div>
      </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OnLoadModal',
  data () {
    return {
      showOnLoadModal: true,
      saveHideModal: false,
    }
  },
  computed: {
    ...mapGetters('facilities', [
      'selectedFacilityShowingOnLoadModalName'
    ]),
    dialogFullscreenStatus () {
      return this.isMobile
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
  },
  watch: {
    saveHideModal: {
      handler: function (newVal) {
        if (newVal) {
          localStorage.setItem(`dont_show_modal_again-${this.selectedFacilityShowingOnLoadModalName}`, 'saveHidden')
        } else {
          localStorage.removeItem(`dont_show_modal_again-${this.selectedFacilityShowingOnLoadModalName}`)
        }
      }, 
    }
  },
  created () {
    if (localStorage.getItem(`dont_show_modal_again-${this.selectedFacilityShowingOnLoadModalName}`) == 'saveHidden') {
      this.showOnLoadModal = false
    }
  }
}
</script>

<style lang='scss'>
  .on-load-modal-content {
    height: 500px;
  }
</style>
