<template>
  <div>
    <h1 class='text-2xl mb-8'>
       예약수정
    </h1>
    <div v-if='isCourtManager' class='mb-6'>
      <label for='courtName' class='block text-sm font-medium text-gray-500'>코트</label>
      <div class='font-medium text-gray-900'>{{courtNameFromId(drilldownBooking.courtId)}}</div>
    </div>
    <div v-else class='mb-6'>
      <label for='courtName' class='block text-sm font-medium text-gray-500'>코트</label>
      <div class='font-medium text-gray-900'>{{selectedFacilityName}} - {{courtNameFromId(drilldownBooking.courtId)}}</div>
    </div>
    <div class='mb-6'>
      <label for='bookingDateTime' class='block text-sm font-medium text-gray-500'>날짜</label>
      <div class='font-medium text-gray-900'>
        {{bookingDateString}}
      </div>
    </div>
    <div class='mb-6'>
      <label for='bookingDateTime' class='block text-sm font-medium text-gray-500'>시간</label>
      <div class='font-medium text-gray-900'>
        {{drilldownBooking.startTime}} - {{drilldownBooking.endTime}}시 ({{drilldownBooking.length}}시간)
      </div>
    </div>
    <div v-if='isCourtManager' class='mb-6'>
      <label for='reserver' class='block text-sm font-medium text-gray-700'>예약자</label>
      <div class='font-medium text-gray-900'>
        {{drilldownBooking.profile.name}}
        <span v-if='hasPhoneNumber'>({{drilldownBooking.profile.phoneNumber}})</span>
      </div>
    </div>
    <div class='mb-6' v-if='multiSlotBookableFromCourtId(drilldownBooking.courtId)'>
      <label for='numberOfHours' class='block text-sm font-medium text-gray-700'>
        예약시간:
        {{timeRange[0]}} - {{timeRange[1]}}시 ({{timeRange[1]-timeRange[0]}}시간)
      </label>
      <div class='px-4 pt-2 pb-4 mt-2 rounded border border-gray-200'>
        <el-slider
          v-model='timeRange'
          range
          :step='0.5'
          :min='selectableStartTime'
          :max='selectableEndTime'
          :marks='timeMarks'
          @input='checkBookingRepeatableByTime'
          class='w-full' />
      </div>
    </div>
    <div v-if='isNewBooking && isCourtManager'>
      <label for='repeat' class='block text-sm font-medium text-gray-700'>반복 예약</label>
      <div class=''>
        <el-radio-group v-model='presetRepeating' class='py-4'>
          <el-radio-button label='한번만'></el-radio-button>
          <el-radio-button label='연대관'></el-radio-button>
          <el-radio-button label='직접입력'></el-radio-button>
        </el-radio-group>
        <div v-if='isRepeating' class='grid grid-cols-6 gap-6'>
          <input 
            v-if='isRepeatingCustom' 
            type='number' 
            v-model='repeatCount' 
            min='1' 
            class='col-span-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'/>
          <div class='leading-10 col-span-3 text-gray-400 text-xs'>{{repeatText}}</div>
        </div>
      </div>
    </div>
    <div v-for='booking in bookingsOnRepeatedDates'
         :key='`booking-${booking.id}`'
         class='text-red-400 text-xs my-6'>
      <div v-if='isNewBooking'>
        *{{booking.bookingDate}} {{booking.startTime}}시~{{booking.endTime}}시 예약이 있어 반복예약이 불가합니다.
      </div>
      <div v-else>
        *{{booking.bookingDate}} {{booking.startTime}}시~{{booking.endTime}}시 예약이 있어 이후 모든예약 수정은 불가합니다.
      </div>
    </div>
    <div class='mb-6'>
      <div v-if='isNewBooking'>
        <div class='py-2 flex flex-row' v-if='isRepeating'>
          <div>반복</div>
          <div class='flex-grow text-right'>{{this.repeatCount}}<span class='inline-block ml-1'>주</span></div>
        </div>
      </div>
      <div class='py-2 border-b font-bold flex flex-row justify-between' style='background-color: #FAFAFA;'>
        <div>가격</div>
        <div  v-if='hasEditedTotalPrice'>
          <vue-numeric class='text-right h-8' v-model='editedTotalPrice' separator=','></vue-numeric>
          <span class='inline-block ml-1'>원</span>
        </div>
        <div class='flex-grow text-right' v-else>
          {{defaultTotalPriceString}}<span class='inline-block ml-1'>원</span>
        </div>
      </div>
<!--       <button v-if='isCourtManager'
        class='underline my-2 text-sm text-gray-500' 
        @click='toggleTotalPriceEdit'>
        {{editPriceControlText}}
      </button> -->
    </div>
    <div class='mb-6'>
      <label for='note' class='block text-sm font-medium text-gray-700'>노트</label>
      <textarea
        name='note'
        v-model='note'
        rows='8'
        class='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'></textarea>
    </div>
    <div>
      <div v-if='isNewBooking' class='mt-4 flex flex-row'>
        <button class='block text-sm text-white py-3 px-4 w-1/2 rounded-md hover:shadow-lg'
                :disabled='!isbookingCreatePossible'
                :class='bookingCreateButtonClass'
                @click='submitNewReservation'>
          예약
        </button>
        <button class='block bg-transparent text-sm text-gray-700 py-3 px-2 md:px-4 border border-transparent hover:border-gray-200 ml-2 rounded-md hover:shadow-sm'
          @click='cancelAdd'>
          취소
        </button>
      </div>
      <div v-else class='mt-4 flex flex-row'>
        <button class='block text-sm text-white py-3 px-4 w-1/2 rounded-md hover:shadow-lg'
                :disabled='hasInvalidBookingHours'
                :class='bookingUpdateButtonClass'
                @click='clickBookingUpdateButton'>
          예약 수정
        </button>
        <button class='block bg-transparent text-sm text-gray-700 py-3 px-2 md:px-4 border border-transparent hover:border-gray-200 ml-2 rounded-md hover:shadow-sm'
          @click='cancelEdit'>
          취소
        </button>
      </div>
    </div>
    <el-dialog
      :visible.sync='showEditBookingConfirmation'
      width="70%"
      top='30vh'
      :append-to-body='true'
      :before-close='closeEditBookingConfirmation'>
      <div class='px-6'>
        <div>반복되는 예약입니다.</div>
        <button class='block border w-full text-sm text-white py-3 px-4 h-12 mt-12 mb-4 rounded-md hover:shadow-lg bg-gray-900'
                @click='submitEditBooking'>
          이 예약만 수정
        </button>
        <button class='block border w-full text-sm text-white py-3 px-4 h-12 mb-4 rounded-md hover:shadow-lg'
                @click='submitBatchEditBookings'
                :class='batchBookingUpdateButtonClass'
                :disabled='hasBookingsOnRepeatedDates'>
          이후 모든 예약 수정
        </button>
        <button class='block border w-full h-12 mb-4 font-semibold'
                @click='closeEditBookingConfirmation'>
          취소
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import StringHelpers from '@/utils/string-helpers'
import numbro        from 'numbro'
import dayjs         from 'dayjs'
export default {
  name: 'BookingEditForm',
  data () {
    return {
      note: '',
      numOfHours: 0,
      phoneNumber: '',
      repeatCount: 1,
      name: '',
      presetRepeating: '한번만',
      timeRange: [0, 0],
      hasEditedTotalPrice: true,
      editedTotalPrice: 0,
      showEditBookingConfirmation: false,
      loadingProfiles: false,
      selectablePlayers: [],
      playerProfile: null
    }
  },
  watch: {
    'drilldownBooking.id': {
      handler: function (newVal) {
        if (newVal) {
          this.setFromExistingBooking(newVal)
        } else {
          // only really need to update the number of hours
          this.numOfHours = 1
        }
      },
      immediate: true
    },
    'drilldownBooking.startTime': { // only do this if a new booking
      handler: function (newVal) {
        if (this.drilldownBooking && this.drilldownBooking.bookingType === 'open') {
          this.presetRepeating = '한번만'
          let initialStartTime = parseFloat(newVal) 
          let initialEndTime = initialStartTime + this.courtSlotLength(this.drilldownBooking.courtId)
          this.timeRange = [initialStartTime, initialEndTime]
        }
      },
      immediate: true
    },
    presetRepeating: {
      handler: function (newVal) {
        if (newVal === '한번만') {
          this.repeatCount = 1
        } else if (newVal === '연대관') {
          this.repeatCount = 52
        } else if (newVal === '직접입력') {
          this.repeatCount = 4
        }
      },
      immediate: true      
    },
    repeatCount: {
      handler: function (newVal) {
        if (parseFloat(newVal) > 1) {
          this.checkBookingRepeatableByCount()
        } else if (parseFloat(newVal) == 1) {
          this.resetBookingsOnRepeatedDates()
        }
      }
    }
  },
  computed: {
    ...mapState('bookings', [
      'drilldownBooking',
      'bookingsOnRepeatedDates'
    ]),
    ...mapGetters([
      'isCourtManager'
    ]),
    ...mapGetters('courts', [
      'courtNameFromId',
      'multiSlotBookableFromCourtId',
      'courtSlotLength',
      'facilityIdFromCourtId',
      'courtStartTimeFromId',
      'courtEndTimeFromId'
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityName'
    ]),
    ...mapGetters('bookings', [
      'bookingsForCourt',
      'isRepeatedBooking',
      'isNewBooking'
    ]),
    hasPhoneNumber () {
      return (this.drilldownBooking.profile.phoneNumber)
    },
    bookingDayOfWeek () {
      return dayjs(this.drilldownBooking.bookingDate).day()
    },
    endTime () {
      return parseInt(this.drilldownBooking.startTime) + this.numOfHours
    },
    hoursBookedArray () {
      let arr = []
      let time = this.timeRange[0]
      for (time; time < this.timeRange[1]; time ++) {
        arr.push(time)
      }
      return arr
    },
    isRepeating () {
      return this.presetRepeating !== '한번만'
    },
    isRepeatingCustom () {
      return this.presetRepeating === '직접입력'
    },
    selectableStartTime () {
      return this.endTimeOfPreviousBooking
    },
    selectableEndTime () {
      return this.startTimeOfNextBooking
    },
    endTimeOfPreviousBooking () {
      let arr = this.bookingsForCourt(this.drilldownBooking.courtId)
                    .filter(booking => parseInt(booking.endTime) <= (parseInt(this.drilldownBooking.startTime)))
                    .sort((a, b) => parseInt(b.startTime) - parseInt(a.startTime))
      return (arr.length > 0) ? parseInt(arr[0].endTime) : parseInt(this.courtStartTimeFromId(this.drilldownBooking.courtId))
    },
    startTimeOfNextBooking () {
      let arr = this.bookingsForCourt(this.drilldownBooking.courtId)
                    .filter(booking => parseInt(booking.startTime) >= (parseInt(this.drilldownBooking.startTime)+1))
                    .sort((a, b) => parseInt(a.startTime) - parseInt(b.startTime))
      return (arr.length > 0) ? parseInt(arr[0].startTime) : parseInt(this.courtEndTimeFromId(this.drilldownBooking.courtId))
    },
    timeMarks () {
      var firstMark = this.selectableStartTime
      var endMark   = this.startTimeOfNextBooking
      var marks = {}
      marks[firstMark] = `${firstMark}`
      marks[endMark]   = `${endMark}`
      return marks
    },
    defaultTotalPriceString () {
      return numbro(this.defaultTotalPrice).format({thousandSeparated: true})
    },
    selectedTotalPrice () {
      return (this.hasEditedTotalPrice) ? this.editedTotalPrice : this.defaultTotalPrice
    },
    repeatText () {
      return (!this.hasInvalidRepeatCount) ? `${dayjs(this.drilldownBooking.bookingDate).add(parseInt(this.repeatCount)-1, 'week').locale('kr').format('YYYY-MM-DD (ddd)')}까지` : '반복 횟수를 설정해주세요.'
    },
    bookingDateString () {
      return StringHelpers.shortDateString(this.drilldownBooking.bookingDate)
    },
    addBooking () {
      return {
        name: this.name,
        note: this.note,
        bookingDate: this.drilldownBooking.bookingDate,
        startTime: this.timeRange[0],
        endTime: this.timeRange[1],
        phoneNumber: this.phoneNumber,
        remainingRepeatCount: this.repeatCount,
        // when add bookings, if booking has repeatCount, price is bookingContract`s BookingsTotalPrice
        price: this.selectedTotalPrice,
        courtId: this.drilldownBooking.courtId,
        facilityId: this.facilityIdFromCourtId(this.drilldownBooking.courtId)
      }
    },
    updatedBooking () {
      return {
        id: this.drilldownBooking.id,
        note: this.note,
        price: this.selectedTotalPrice
      }
    },
    blockReservation () {
      return {
        bookingType: 'blocked',
        startTime: this.timeRange[0],
        endTime: this.timeRange[1],
        bookingDate: this.drilldownBooking.bookingDate,
        price: 0,
        courtId: this.drilldownBooking.courtId,
        facilityId: this.facilityIdFromCourtId(this.drilldownBooking.courtId)
      }
    },
    dateTimeRangeForCheckRepeatable () {
      let dateRange = []
      let numOfWeeks = (this.isNewBooking) ? this.repeatCount : this.drilldownBooking.remainingRepeatCount
      for(var i = 1; i < parseInt(numOfWeeks); i++) {
        dateRange.push(dayjs(this.drilldownBooking.bookingDate).add(i, 'week').locale('kr').format('YYYY-MM-DD'))
      }
      return {
        dateRange: dateRange,
        timeRange: this.timeRange,
      }
    },
    hasInvalidBookingHours () {
      return !this.hoursBookedArray.length > 0
    },
    hasBookingsOnRepeatedDates () {
      return this.bookingsOnRepeatedDates.length > 0
    },
    hasInvalidRepeatCount () {
      return !(parseInt(this.repeatCount) > 0)
    },
    isbookingCreatePossible () {
      return !(this.hasBookingsOnRepeatedDates || this.hasInvalidRepeatCount || this.hasInvalidBookingHours)
    },
    bookingCreateButtonClass () {
      return (this.isbookingCreatePossible) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    bookingUpdateButtonClass () {
      return !(this.hasInvalidBookingHours) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    batchBookingUpdateButtonClass () {
      return !(this.hasBookingsOnRepeatedDates) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    editPriceControlText () {
      return (this.hasEditedTotalPrice) ? '취소' : '총 예약금액 변경'
    },
  },
  methods: {
    ...mapActions('bookings', [
      'showBookingsOnRepeatedDates',
      'resetBookingsOnRepeatedDates'
    ]),
    ...mapActions('managePlayers', [
      'searchPlayerProfileByName',
    ]),
    searchPlayer (text) {
      this.loadingProfiles = true
      if (text) {
        this.searchPlayerProfileByName(text).then(resp => {
          this.selectablePlayers = resp
          this.loadingProfiles = false
        })
      } else {
        this.loadingProfiles = false
      }
    },
    setFromExistingBooking (newVal) {
      if (newVal) {
        this.name             = this.drilldownBooking.name
        this.editedTotalPrice = this.drilldownBooking.price
        this.note             = this.drilldownBooking.note
        this.numOfHours       = this.drilldownBooking.length
        this.phoneNumber      = this.drilldownBooking.phoneNumber
        this.timeRange        = [parseFloat(this.drilldownBooking.startTime), parseFloat(this.drilldownBooking.endTime)]
      }
    },
    cancelAdd () {
      this.$emit('done-edit')
    },
    cancelEdit () {
      this.$emit('cancel-edit')
    },
    toggleTotalPriceEdit() {
      if (this.hasEditedTotalPrice) {
        this.hasEditedTotalPrice = false
      } else {
        this.editedTotalPrice = this.defaultTotalPrice
        this.hasEditedTotalPrice = true
      }
    },
    submitNewReservation () {
      this.$confirm('예약내용을 저장하시겠습니까?', {
        confirmButtonText: '저장',
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        this.$emit('submit-new', this.addBooking)
      })
    },
    submitBlock () {
      this.$emit('submit-new', this.blockReservation)
    },
    submitEditBooking () {
        this.$emit('submit-edit-booking', this.updatedBooking)
    },
    submitBatchEditBookings () {
        this.$emit('submit-edit-batch-bookings', this.updatedBooking)
    },
    checkBookingRepeatableByTime () {
      if (this.isRepeatedBooking || this.isRepeating) {
        this.checkBookingRepeatableByCount()
      }
    },
    checkBookingRepeatableByCount () {
      this.showBookingsOnRepeatedDates(this.dateTimeRangeForCheckRepeatable).then(() => {
        if (this.isNewBooking && this.isbookingCreatePossible && parseInt(this.repeatCount) > 1) {
          this.note = `예약자: ${this.name}\n전화번호: ${this.phoneNumber}\n반복횟수: ${this.repeatCount}\n시작날짜: ${dayjs(this.drilldownBooking.bookingDate).locale('kr').format('YYYY-MM-DD (ddd)')}\n종료날짜: ${dayjs(this.drilldownBooking.bookingDate).add(parseInt(this.repeatCount)-1, 'week').locale('kr').format('YYYY-MM-DD (ddd)')}`
        }
      })
    },
    clickBookingUpdateButton () {
      if (this.isRepeatedBooking) {
        this.openEditBookingConfirmation()
      } else {
        this.$confirm('예약내용을 수정하시겠습니까?', {
          confirmButtonText: '저장',
          cancelButtonText: '취소',
          type: 'success'
        }).then(() => {
          this.submitEditBooking()
        })
      }
    },
    openEditBookingConfirmation () {
      this.showEditBookingConfirmation = true
    },
    closeEditBookingConfirmation () {
      this.showEditBookingConfirmation = false
    }
  }
}
</script>