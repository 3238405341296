<template>
  <div class='lg:pt-4 lg:pl-4 h-screen'>
    <bookings-schedule-controls />
    <loading v-if='isLoading' />
    <div class='schedule-header fixed z-20 top-16 lg:top-0 right-0 pl-4 lg:pl-6 pr-0 lg:pr-2 text-left border flex flex-row items-center justify-between text-sm font-semibold uppercase bg-gray-100 cursor-pointer'>
      <button 
        @click='hideContentOnMobile'
        class='px-2 py-2'>
        {{dateString(scheduleSelectedDay)}}
        <span class='inline-block lg:hidden opacity-75'>[날짜 선택]</span>
      </button>
      <button
        v-if='isCourtManager'
        class='print-button px-2 py-2 flex flex-row items-center hover:bg-gray-200'
        @click='printPage'>
        <printer-icon class='h-4 text-gray-600' />
        프린트
      </button>
    </div>
    <div v-if='pastBookingAvailabiltyForPlayer'
      class='mt-10 lg:mt-6 bg-gray-100 py-8 px-4 lg:p-16 text-sm rounded-md'>
      오늘은 더 이상 예약 가능한 코트가 없습니다.<br>
      <span class='underline font-semibold cursor-pointer text-gray-700 hover:text-gray-900' @click='selectNextDay'>내일 예약보기</span>
    </div>
    <div v-else
      class='lg:static top-24 lg:top-0 overflow-auto w-full schedule-bookings'>
      <div v-show='!isLoading'
        class='flex flex-row justify-start'>
        <bookings-for-court
          v-for='court in courts'
          v-bind:key='`court-header-${court.id}`'
          :court='court'
          :bookings='bookingsForCourtWithDate(court.id, scheduleSelectedDay)'
          :time-range='timeRange(court)'
          :booking-date='scheduleSelectedDay'
          :show-fully-booked-court='showFullyBookedCourt' />
      </div>
      <div 
        v-if='showNoMoreOpenSlotsMsg'
        class='bg-gray-100 py-8 px-4 lg:p-16 text-sm rounded-md'>
        {{selectedFacilityNoOpenSlotsMessage}}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { PrinterIcon }          from '@vue-hero-icons/outline'
import StringHelpers            from '@/utils/string-helpers'
import BookingsForCourt         from '@/views/bookings/BookingsForCourt'
import BookingsScheduleControls from '@/components/bookings/BookingsScheduleControls'
import Loading                  from '@/components/Loading'
import dayjs from 'dayjs'

export default {
  name: 'BookingsSchedule',
  components: {
    BookingsForCourt,
    Loading,
    PrinterIcon,
    BookingsScheduleControls,
  },
  props: [
    'showFullyBookedCourt'
  ],
  watch: {
    scheduleSelectedDay: {
      handler: function (newVal) {
        document.title = `Tennis Play: ${newVal}`
      },
      immediate: true
    }
  },
  computed: {
    ...mapState([
      'isLoading',
    ]),
    ...mapGetters([
      'isCourtManager',
      'isRegisteredPlayer',
    ]),
    ...mapState('courts', [
      'courts',
      'hasOpenSlotsCourt',
    ]),
    ...mapState('bookings', [
      'scheduleSelectedDay',
      'currentTimeInKorea',
    ]),
    ...mapGetters('bookings', [
      'bookingsForCourtWithDate',
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityNoOpenSlotsMessage',
    ]),
    ...mapGetters('courts', [
      'openSlotsEmpty',
    ]),
    pastBookingAvailabiltyForPlayer () {
      let endTimes = this.courts.map(court => parseInt(court.useEndTime))
      let lastStartTime = Math.max(...endTimes) - 1
      return this.isRegisteredPlayer && 
             dayjs(this.scheduleSelectedDay).isSame(dayjs(this.currentTimeInKorea), 'day') &&
             dayjs(this.currentTimeInKorea).hour() >= lastStartTime
    },
    showNoMoreOpenSlotsMsg () {
      return this.openSlotsEmpty && 
             !this.isCourtManager && 
             !this.isLoading
    },
  },
  methods: {
    ...mapActions([
      'hideContentOnMobile',
    ]),
    ...mapActions('bookings', [
      'selectNextDay',
    ]),
    dateString (date) {
      return `${StringHelpers.dayOfWeekKorean(date)}요일 (${StringHelpers.monthDay(date)})`
    },
    printPage () {
      window.print()
    },
    timeRange (court) {
      return {
        'start': parseInt(court.useStartTime),
        'end':   parseInt(court.useEndTime)
      }
    },
  },
  created () {
    document.title = `Tennis Play: ${this.scheduleSelectedDay}`
  },
  beforeDestroy () {
    document.title = `Tennis Play`
  },
}
</script>

<style lang='scss' scoped>

.schedule-bookings {
  @apply fixed;
  height: calc(100vh - 9rem);
}

@media print {
  @page {
    size: landscape;
    margin: 1cm;
  }

  .schedule-bookings {
    @apply mt-0 p-0 static;
    width: 297mm;
    height: auto;
    overflow: visible !important;
  }

  .schedule-header {
    @apply hidden;
    display: none !important; 
    visibility: hidden;
    height: 0px;
  }

  .print-button {
    display: none;
  }
}

.schedule-header {
  width:100%;
}

@media (min-width: 1024px) {
  .schedule-header {
    width:calc(100% - 28rem);
  }

  .schedule-bookings {
    @apply pt-6 pb-16 static;
    height: 100vh;
  }


}
</style>

