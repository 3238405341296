<template>
  <div class='booking-nav'>
    <datepicker 
      :inline='true'
      v-model='scheduleSelectedDay'
      minimumView='day'
      maximumView='day'
      calendar-class='w-full'
      format='yyyy-MM-dd'
      :language='ko'/>
    <button 
      @click='backToToday'
      class='block border rounded px-8 py-2 mt-4 uppercase text-gray-700 border-transparent hover:border-indigo-300 underline hover:no-underline text-sm'>
      {{goToDateButtonString}}
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'
import Datepicker from 'vuejs-datepicker'
import {ko}       from 'vuejs-datepicker/dist/locale'

export default {
  name: 'BookingsNavSelectDate',
  components: {
    Datepicker,
  },
  data () {
    return {
      ko: ko
    }
  },
  watch: {
    scheduleSelectedDay: {
      handler (newVal, oldVal) {
        if (newVal !== oldVal) { 
          // get new bookings list
          this.resetNewBookingRequestSlotList()
          this.getBookingsForManager(newVal)
          this.displayContentOnMobile()
          this.sidepanelClose()
        } else {
          console.log('dont need to get new bookings')
        }
      }
    }
  },
  computed: {
    ...mapFields('bookings', [
      'scheduleSelectedDay',
    ]),
    goToDateButtonString () {
      return (this.showingToday) ? '예약현황보기' : '오늘로 가기'
    },
    showingToday () {
      let todayFormatted = dayjs(new Date()).format('YYYY-MM-DD')
      return this.scheduleSelectedDay === todayFormatted
    },
  },
  methods: {
    ...mapActions([
      'displayContentOnMobile',
      'sidepanelClose'
    ]),
    ...mapMutations('manageRequests', [
      'resetNewBookingRequestSlotList',
    ]),
    ...mapActions('bookings', [
      'getBookingsForManager',
    ]),
    backToToday () {
      if (this.showingToday) {
        this.resetNewBookingRequestSlotList()
        this.getBookingsForManager(this.scheduleSelectedDay)
        this.displayContentOnMobile()
      } else {
        this.scheduleSelectedDay = dayjs(new Date()).format('YYYY-MM-DD')
      }
    },
  },
}
</script>

<style lang='scss'>
  .booking-nav .vdp-datepicker__calendar {
    @apply w-full border-transparent;
    background-color: transparent;
  }  
</style>
