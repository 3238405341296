<template>
  <div>
    <h1 class='text-2xl mb-8'>
       예약연장
    </h1>
    <div class='mb-6'>선택된 예약의 반복이 끝나는 날부터 예약을 연장할 수 있습니다. 반복이 끝나는 날짜에 미리 예약된 다른 예약이 있다면 연장이 불가합니다.</div>
    <div class='mb-6'>
      <label for='courtName' class='block text-sm font-medium text-gray-500'>코트</label>
      <div class='font-medium text-gray-900'>{{courtNameFromId(drilldownBooking.courtId)}}</div>
    </div>
    <div class='mb-6'>
      <label for='reserver' class='block text-sm font-medium text-gray-700'>예약자</label>
      <div class='font-medium text-gray-900'>
        {{drilldownBooking.profile.name}} 
        <span v-if='hasPhoneNumber'>({{drilldownBooking.profile.phoneNumber}})</span>
      </div>
    </div>
    <div class='mb-8'>
      <label for='repeat'
        class='block text-sm font-medium text-gray-700 mb-2'>반복 기간</label>
      <div class='flex flex-row justify-between items-center'>
        <div>
          {{extensionStartDateString}} ~ 
          <datepicker
            class='inline-block'
            v-model='repeatEndDate'
            format='yyyy-MM-dd (D)'
            :language='ko'
            input-class='w-36'
            calendar-class='-left-20'
            :disabled-dates="{to: new Date(extensioningNewBookingRequestStartDate)}"
            :highlighted='highlightedDatesOnCalendar'
            @selected='updateRepeatCount'>
          </datepicker>
        </div>
        <div class='text-sm font-medium'>
          (반복 <span style="background-color: #FFFF00">{{repeatCount}}회</span>)
        </div>
      </div>
    </div>
    <div class='mb-8'>
      <table class='w-full text-sm'>
        <thead>
          <tr class='border-b text-left'>
            <th class='pb-2'>코트</th>
            <th class='pb-2'>시작날짜</th>
            <th class='pb-2'>종료날짜</th>
            <th class='pb-2'>시간</th>
            <th class='pb-2 text-right'>가격</th>
          </tr>
        </thead>
        <add-new-booking-request-row 
          v-for='newBookingRequest in extensioningNewBookingRequestSchedules'
          :key='`newBookingRequest-${newBookingRequest.courtId}-${newBookingRequest.bookingDate}-${newBookingRequest.startTime}`'
          :new-booking-request='newBookingRequest'
          :check-for-repeat-dates='true'
          :repeat-count='repeatCount'
          :new-booking-request-type='bookingType'
          @stack-create-new-booking-request-available='getHasBookingOnRepeatedDateStatues' />
        <tr class='border-b border-t font-medium'>
          <td colspan='4'
            class='py-2 text-left'>1주당 가격</td>
          <td class='py-2 text-right'>{{subTotalPriceString}}</td>
        </tr>
        <tr class='border-b border-t font-medium'>
          <td colspan='4'
            class='py-2 text-left'>반복 횟수</td>
          <td class='py-2 text-right'>x {{repeatCount}}</td>
        </tr>
      </table>
    </div>
    <div class='mb-8'>
      <div 
        class='p-2 border-b font-bold flex flex-row justify-between items-center' 
        style='background-color: #FAFAFA;'>
        <label class='block'>Total</label>
        <div class=''>
          <input v-model='totalPrice' class='form-input text-right h-full py-2 rounded-md'>
          원
        </div>
      </div>
    </div>
    <div class='mb-6'>
      <label for='note' class='block text-sm font-medium text-gray-700'>노트</label>
      <textarea
        name='note'
        v-model='note'
        rows='8'
        class='mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md'></textarea>
    </div>
    <portal to='sidepanel-footer'>
      <div class='flex flex-row w-full h-20 py-4'>
        <button class='block text-sm text-white py-3 px-3 w-1/2 rounded-md hover:shadow-lg bg-gray-900'
                :disabled='disabledNewBookingRequestAddButton'
                :class='createNewBookingRequestButtonClass'
                @click='submitNewReservation'>
          예약연장
        </button>
        <button class='block bg-transparent text-sm text-gray-700 py-3 px-2 md:px-4 border border-transparent hover:border-gray-200 ml-2 rounded-md hover:shadow-sm'
          @click='cancelExtension'>
          취소
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'
import dayjs  from 'dayjs'
import numbro from 'numbro'
import StringHelpers      from '@/utils/string-helpers'
import AddNewBookingRequestRow from '@/components/bookings/AddNewBookingRequestRow'
import snakecaseKeys           from 'snakecase-keys'

export default {
  name: 'BookingExtensionForm',
  components: {
    Datepicker,
    AddNewBookingRequestRow,
  },
  data () {
    return {
      hasBookingOnRepeatedDateStatues: [],
      repeatEndDate: '',
      ko: ko,
      repeatCount: 1,
      totalPrice: 0,
      bookingType: 'reservedExtension',
      note: '',
    }
  },
  watch: {
    repeatCount: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          this.updateTotalPrice()
        }
      },
      immediate: true
    },
    extensioningNewBookingRequestStartDate: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          this.repeatEndDate = this.extensioningNewBookingRequestStartDate
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('bookings', [
      'drilldownBooking',
    ]),
    ...mapState('manageRequests', [
      'extensioningNewBookingRequest',
    ]),
    ...mapGetters('manageRequests', [
      'hasExtensioningNewBookingRequest',
      'extensioningNewBookingRequestSchedules',
      'extensioningNewBookingRequestStartDate'
    ]),
    ...mapGetters('facilities', [
      'selectedManagedFacilityId',
      'selectedManagedFacilityName',
    ]),
    ...mapGetters('courts', [
      'courtNameFromId'
    ]),
    ...mapGetters('prices', [
      'priceForNewBookingRequest'
    ]),
    extensionStartDateString () {
      return this.extensioningNewBookingRequestStartDate + ' (' + StringHelpers.dayOfWeekKorean(this.extensioningNewBookingRequestStartDate) + ')'
    },
    hasPhoneNumber () {
      return (this.drilldownBooking.profile.phoneNumber)
    },
    subTotalPrice () {
      return this.hasExtensioningNewBookingRequest ? this.extensioningNewBookingRequestSchedules.map(booking => parseFloat(this.priceForNewBookingRequest(booking))).reduce((a, b) => a + b, 0) : 0
    },
    subTotalPriceString () {
      return numbro(this.subTotalPrice).format({trimMantissa: true, thousandSeparated: true})
    },
    submitNewBookingRequestData () {
      let params = {
          new_booking_request: {
          facility_id:          this.selectedManagedFacilityId,
          profile_id:           this.extensioningNewBookingRequest.profileId,
          booking_type:         this.bookingType,
          repeat_count:         this.repeatCount,
          bookings_total_price: parseInt(this.totalPrice),
          note:                 this.note,
          meta_data: {
            facility_name: this.selectedManagedFacilityName,
            dates: this.datesFromRepeatCountAndFirstDate(this.extensioningNewBookingRequestStartDate)
          },
          schedules:            snakecaseKeys(this.extensioningNewBookingRequestSchedules)
        }
      }
      return params
    },
    hasBookingsOnRepeatedDates () {
      return this.hasBookingOnRepeatedDateStatues.map(newBookingRequest => newBookingRequest.status).some(status => status === true)
    },
    createNewBookingRequestButtonClass () {
      return (!this.disabledNewBookingRequestAddButton) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    disabledNewBookingRequestAddButton () {
      return this.hasBookingsOnRepeatedDates
    },
    highlightedDatesOnCalendar () {
      return {
        from: new Date(this.extensioningNewBookingRequestStartDate),
        to: new Date(this.repeatEndDate)
      }
    }
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('manageRequests', [
      'postNewBookingRequestSlots',
    ]),
    datesFromRepeatCountAndFirstDate (firstDate) {
      let arr = []
      for (var i = 0; i < this.repeatCount; i++) {

        arr.push(dayjs(firstDate).add(1*i, 'week').format('YYYY-MM-DD'))
      }
      return arr
    },
    updateTotalPrice () {
      this.totalPrice = this.repeatCount * this.subTotalPrice
    },
    cancelExtension () {
      this.$emit('cancel-extension')
    },
    submitNewReservation () {
      this.$confirm(`예약연장 하시겠습니까?`, {
        confirmButtonText: '예약연장',
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        this.postNewBookingRequestSlots(this.submitNewBookingRequestData).then(() => {
          this.sidepanelClose()
        })
      }).catch(() => {})
    },
    updateRepeatCount (event) {
      this.repeatEndDate = dayjs(event)
      this.repeatCount = this.repeatEndDate.diff(this.extensioningNewBookingRequestStartDate, 'week') + 1
    },
    getHasBookingOnRepeatedDateStatues (status) {
      this.hasBookingOnRepeatedDateStatues.push(status)
    }
  }
}
</script>
