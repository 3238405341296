<template>
    <div
      class='p-4 border mb-4 shadow-lg rounded-lg'>
      <div class='flex flex-col items-start'>
        <label class='uppercase text-sm text-gray-600 mb-1'>이름</label>
        <input
          type='text'
          class='rounded border-gray-500 w-full lg:w-1/2'
          v-model='name'/>
      </div>
      <div class='flex flex-col items-start mt-4'>
        <label class='uppercase text-sm text-gray-600 mb-1'>전화번호</label>
        <vue-phone-number-input
          class='rounded border-gray-500 w-full lg:w-1/2'
          v-model='phoneNumber'
          default-country-code='KR'
          :no-country-selector='true'
          :no-example='true'
          :translations='phoneNumberInputTranslations'
          @update='checkValidPhoneNumber' />
      </div>
      <div v-if='isCourtManager'
           class='flex flex-col items-start mt-4'>
        <label class='uppercase text-sm text-gray-600 mb-1'>유형</label>
        <select class='rounded border-gray-500 w-full lg:w-1/2'
                v-model='clientType'>
          <option value='regular'>정기회원</option>
          <option value='general'>일반회원</option>
        </select>
      </div>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import { mapGetters }     from 'vuex'
import { mapFields }      from 'vuex-map-fields'

export default {
  name: 'ProfileForm',
  components: {
    VuePhoneNumberInput,
  },
  data () {
    return {
      phoneNumberInputTranslations: {
        phoneNumberLabel: ''
      },
    }
  },
  computed: {
    ...mapFields('playerProfile', [
      'editingProfile.name',
      'editingProfile.phoneNumber',
      'editingProfile.client.clientType',
      'editingProfile.isValidPhoneNumber'
    ]),
    ...mapGetters([
      'isCourtManager',
    ]),
  },
  methods: {
    checkValidPhoneNumber (val) {
      this.isValidPhoneNumber = val.isValid
    }
  },
}
</script>

<style type='scss'>
.vue-phone-number-input {
  @apply w-1/2 lg:w-1/3
}

.input-tel__input {
  @apply w-full;
  border-color: red
}
</style>
