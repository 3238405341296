<template>
  <modal
    name='login-modal'
    :adaptive='true'
    classes='relative p-12 lg:p-16'
    height='800px'>
      <button class='z-30 absolute top-3 right-3' @click="closeLoginModal">
        <x-icon class='text-black' />
      </button>
      <div class='flex flex-col justify-center items-center gap-y-8'>
        <logo height='3rem' />
      </div>
      <div v-if='showingFormLogin'>
        <h1 class='mt-16 text-lg text-center uppercase text-gray-700' style='font-weight:700'>이메일 로그인</h1>
        <div class='mt-4'>
          <input 
            v-model='email'
            class='border w-full p-2 rounded border-gray-300'
            type='text'
            placeholder='이메일'>
          <input 
            v-model='password'
            class='border mt-4 w-full p-2 rounded border-gray-300'
            type='password'
            placeholder='비밀번호'
            @keypress.enter='submitEmailLogin'>
          <button
            class='mt-8 w-full p-4 rounded-md text-white uppercase'
            :class='emptyFieldLoginButtonClass'
            :disabled='needToInputLoginField'
            @click='submitEmailLogin'>
            로그인
          </button>
          <div class='flex flex-row-reverse justify-between mt-4'>
            <button class='text-gray-900'
              @click='goToSignupForm'>
              회원가입
            </button>
            <ul v-if='needToInputLoginField'
              class='list-disc list-inside text-red-600 text-sm text-left'>
              <li v-if='emptyEmailField'>이메일을 입력해주세요.</li>
              <li v-if='emptyPasswordField'>비밀번호를 입럭해주세요.</li>
            </ul>
          </div>
          <div class='flex flex-row justify-center mt-12'>
            <button class='text-gray-400'
              @click="changeShowingForm('forgot-password')">
              비밀번호를 잊으셨나요? <span class='underline'>비밀번호 재설정</span>
            </button>
          </div>
        </div>
      </div>
      <update-login-password-form v-else-if='showingFormForgotPassword'
        @change-showing-form='changeShowingForm' />
  </modal>
</template>

<script>
import { mapActions }       from 'vuex'
import { XIcon }            from '@vue-hero-icons/outline'
import ssoSessionsApi from '@/api/v1/users/api-sso-sessions.js'
import Logo           from '@/components/Logo'
import UpdateLoginPasswordForm from '@/components/UpdateLoginPasswordForm'

export default {
  name: 'LoginForm',
  components: {
    XIcon,
    Logo,
    UpdateLoginPasswordForm,
  },
  data () {
    return {
      showingForm: 'login',
      email: '',
      password: '',
      apiKey: process.env.VUE_APP_KAKAO_JS_KEY
    }
  },
  computed: {
    showingFormLogin () {
      return this.showingForm === 'login'
    },
    showingFormForgotPassword () {
      return this.showingForm === 'forgot-password'
    },
    emptyEmailField () {
      return !this.email
    },
    emptyPasswordField () {
      return !this.password
    },
    needToInputLoginField () {
      return this.emptyEmailField || this.emptyPasswordField
    },
    emptyFieldLoginButtonClass () {
      return (this.needToInputLoginField) ? 'bg-gray-500 opacity-25 cursor-not-allowed' : 'bg-gray-900'
    },
  },
  methods: {
    submitEmailLogin () {
      ssoSessionsApi.create('email', { email: this.email, password: this.password }).then(resp => {
        this.onSuccess(resp)
      }).catch(() => {
        this.$alert('아이디 또는 비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.', {
          type: 'warning',
          confirmButtonText: 'Close'
        })
      })
    },
    goToSignupForm () {
      this.$modal.hide('login-modal')
      this.$modal.show('signup-modal')
    },
    ...mapActions([
      'setLocalStorageData'
    ]),
    kakaoOnSuccess (data) {
      ssoSessionsApi.create('kakao', { access_token: data.access_token }).then(resp => {
        this.onSuccess(resp)
      })
    },
    onSuccess (resp) {
      localStorage.setItem('auth._token.tennis_play', resp.data.access_token)
      localStorage.setItem('role', resp.data.role)
      localStorage.setItem('userId', resp.data.user_id)
      this.setLocalStorageData()
      this.$router.push({name: 'Home'}).catch(() => {})
    },
    onFailure (data) {
      console.error(data)
      this.$notify({
        title: 'Login Error',
        message: data.response.data[0].message,
        type: 'warning',
        position: 'top-right'
      })
    },
    changeShowingForm (showingForm) {
      this.showingForm = showingForm
    },
    closeLoginModal () {
      this.changeShowingForm('login')
      this.$modal.hide('login-modal')
    },
  }
}
</script>
