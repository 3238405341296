<template>
  <section class=' mt-8 lg:mt-12'>
    <h2 class='text-lg font-semibold uppercase text-gray-600'>예약 정보</h2>
    <div class='flex flex-row justify-between mt-2'>
      <div class='flex flex-col justify-start text-left w-1/2 p-2 mr-2 rounded-md bg-gray-100'>
        <h4 class='text-sm text-gray-500'>Past</h4>
        <div class='text-2xl'>{{pastBookingsCount}}</div>
        <div class='text-xs uppercase text-gray-600'>시간
        </div>
      </div>
      <div class='flex flex-col justify-start text-left w-1/2 p-2 ml-2 rounded-md bg-blue-100'>
        <h4 class='text-sm text-gray-500'>Upcoming</h4>
        <div class='text-2xl'>{{upcomingBookingsCount}}</div>
        <div class='text-xs uppercase text-gray-600'>
          {{drilldownPlayer.lessonsSubstitutionReplaceCount}}
          <span class='font-light'>시간</span>
        </div>
      </div>
    </div>
    <div v-if='playerHasBookings'
      class='override-calendar-order mt-4'>
      <v-calendar
       :attributes='bookingsForCalendar'
       :available-dates='{ start: new Date(), end: null }'
       is-expanded
       :rows='2'
       :step='1'
       class='border-0' />
    </div>  
    <div v-else
      class='bg-gray-50 rounded-lg flex flex-row items-center text-center justify-center p-12 mt-8'>
      <span>{{drilldownPlayer.details.name}} 예약 없음</span>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PlayerDrilldownBookings',
  computed: {
    ...mapState('managePlayers', [
      'drilldownPlayer'
    ]),
    ...mapGetters('managePlayers', [
      'drilldownPastBookings',
      'drilldownUpcomingBookings',
    ]),
    bookingsForCalendar () {
      return this.pastBookingsStyled.concat(this.upcomingBookingsStyled)
    },
    pastBookingsStyled () {
      return this.drilldownPastBookings.map(booking => {
        let styledBooking = booking
        styledBooking['dates']     = booking.bookingDate
        styledBooking['highlight'] = {
          color:    'gray',
          fillMode: 'solid'
        }
        return styledBooking
      })
    },
    upcomingBookingsStyled () {
      return this.drilldownUpcomingBookings.map(booking => {
        let styledBooking = booking
        styledBooking['dates']     = booking.bookingDate
        styledBooking['highlight'] = {
          color: 'blue',
          fillMode: 'light',
        }
        return styledBooking
      })
    },
    pastBookingsCount () {
      return this.drilldownPastBookings.length
    },
    upcomingBookingsCount () {
      return this.drilldownUpcomingBookings.length
    },
    playerHasBookings () {
      return this.pastBookingsCount + this.upcomingBookingsCount > 0
    }
  },
}
</script>

<styles lang='scss'>
.override-calendar-order {
  /*.vc-pane-layout {
    @apply flex flex-col-reverse;
  }*/

  .vc-container {
    border-width: 0;
  }

  .vc-title {
    @apply uppercase;
    --text-lg: 0.875rem;
    --gray-800: #999;
  }

  .vc-day-content {
    @apply text-gray-500;
  }
}
</styles>
