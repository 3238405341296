import dayjs  from 'dayjs'
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
require('dayjs/locale/ko')
dayjs().locale('ko')

class CancellationPolicyHelpers {

  static cancellationRefundPercentage (bookingDateTime, playDateTime) {
    let bookingMinuteDiff = (dayjs(new Date()).diff(bookingDateTime, 'minute') + 1)
    // 예약 후 1시간 안에 취소하면 100% 환불
    if (0 < bookingMinuteDiff && bookingMinuteDiff <= 60) {
      return 100
    }

    let playMinuteDiff = (dayjs(playDateTime).diff(new Date(), 'minute') + 1)
    if (2880 <= playMinuteDiff) {
      // 이용 48시간(1440분) 전 100% 환불
      return 100
    } else if (1440 <= playMinuteDiff && playMinuteDiff < 2880) {
      // 이용 24시간 전 50% 환불
      return 50
    } else {
      // 이용 1일전부터는 0% 환불
      return 0
    }
  }

}

export default CancellationPolicyHelpers
