<template>
  <div ref='chart' :style='styleForHeight'>
    <v-chart :options='chartOptions' :days='days' :hours='hours' :style='styleForHeight' style='width: 100%;'/>
  </div>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/heatmap'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import numbro from 'numbro'

export default {
  name: 'HeatmapChart',
  props: ['datum', 'hours', 'days', 'maxValue', 'minHour'],
  components: {
    'v-chart': ECharts
  },
  computed: {
    chartOptions () {
      return {
        tooltip: {
          position: 'top',
          formatter: params => {                
            return `${params.name}요일 ${params.data[1]+this.hourOffset}시: ${params.data[2]}명`
          }
        },
        animation: false,
        grid: {
          left: 32,
          top: 24,
          bottom: 0,
          right: 0,
        },
        xAxis: {
          type: 'category',
          data: this.days,
          splitArea: { 
            show: true,
            interval: 0,
            areaStyle: { 
              color: ['#FFFFFF', '#FBFBFB'] 
            }
          },
          position: 'top',
          offset: 1,
          axisLabel: { color: '#222222' },
          axisLine: { show: false },
          axisTick: { show: false }
        },
        yAxis: {
          type: 'category',
          data: this.hours,
          position: 'left',
          inverse: true,
          axisLine: { show: false },
          splitArea: { show: false },
          axisTick: { show: false },
        },
        visualMap: {
          min: 0,
          max: this.maxValue || 10,
          calculable: true,
          show: false,
          inRange: { color: ['#e6eff5', '#047cc2'] }
          // inRange: { color: ['#FEFEFE', '#2BA0E2'] }
        },
        series: [{
          name: 'visits',
          type: 'heatmap',
          data: this.formattedDatum,
          label: { 
            show: true,
            color: '#f2f2f2'
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.2)',
            },
            label: { color: '#222'}
          }
        }]
      }
    },
    styleForHeight () {
      return (this.height) ? `height:${this.height}` : 'height:100%'
    },
    formattedDatum () {
      return this.datum.map(item => {
          return [
            item[0], 
            item[1], 
            this.formattedNumber(item[2])
          ]
      })
    },
    hourOffset () {
      return (this.minHour) ? this.minHour : 0
    }
  },
  methods: {
    formattedNumber (number) {
      return (number) ? numbro(number).format({trimMantissa: true, mantissa: 1}) : '-'
    },
  }
}
</script>

<style lang='scss' scoped>

@media (min-width: 640px) {

}
</style>
