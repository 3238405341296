import Vue           from 'vue'
import VueRouter     from 'vue-router'

import Home               from '@/views/Home.vue'
import Login              from '@/views/Login.vue'
import CancellationPolicy from '@/views/CancellationPolicy.vue'
import Schedule           from '@/views/Schedule.vue' // placehodler for non-logged in users to encourage registering

import ManageNewRequests from '@/views/ManageNewRequests.vue'
import ManageBillings    from '@/views/ManageBillings.vue'
import ManageBookings    from '@/views/ManageBookings.vue'
import ManagePlayers     from '@/views/ManagePlayers.vue'
import ManageReport      from '@/views/ManageReport.vue' // empty
import ManageSettings    from '@/views/ManageSettings.vue'

import PlayerReservations     from '@/views/PlayerReservations.vue'
import PlayerScheduleBookings from '@/views/PlayerScheduleBookings.vue'
import CustomerSupport        from '@/views/CustomerSupport.vue'


import PaymentReady    from '@/components/payments/PaymentReady.vue'
import PaymentSuccess  from '@/components/payments/PaymentSuccess.vue'
import PaymentFinished from '@/components/payments/PaymentFinished.vue'
import PaymentFail     from '@/components/payments/PaymentFail.vue'

Vue.use(VueRouter)

const routes = [
  //general
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cancellation-policy',
    name: 'CancellationPolicy',
    component: CancellationPolicy
  },
  // for manage
  {
    path: '/manage/alerts',
    name: 'ManageNewRequests',
    component: ManageNewRequests
  },
  {
    path: '/manage/billings',
    name: 'ManageBillings',
    component: ManageBillings
  },
  {
    path: '/manage/bookings',
    name: 'ManageBookings',
    component: ManageBookings
  },
  {
    path: '/manage/players-list',
    name: 'ManagePlayers',
    component: ManagePlayers
  },
  {
    path: '/manage/report',
    name: 'ManageReport',
    component: ManageReport
  },
  {
    path: '/manage/settings',
    name: 'ManageSettings',
    component: ManageSettings
  },


  // for player
  {
    path: '/my-reservations',
    name: 'PlayerReservations',
    component: PlayerReservations
  },
  {
    path: '/schedule-booking',
    name: 'PlayerScheduleBookings',
    component: PlayerScheduleBookings
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/customer-center',
    name: 'CustomerSupport',
    component: CustomerSupport
  },
  {
    path: '/payments/ready',
    name: 'PaymentReady',
    component: PaymentReady
  },
  {
    path: '/payments/success',
    name: 'PaymentSuccess',
    component: PaymentSuccess
  },
  {
    path: '/payments/finished',
    name: 'PaymentFinished',
    component: PaymentFinished
  },
  {
    path: '/payments/fail',
    name: 'PaymentFail',
    component: PaymentFail
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
