<template>
  <modal
    name='facility-selector-modal'
    :adaptive='true'
    :clickToClose='hasSelectedFacility'
    classes='relative'
    height='680px'>
      <button v-if='hasSelectedFacility'
        class='z-30 absolute top-3 right-3' 
        @click="$modal.hide('facility-selector-modal')">
        <x-icon class='text-black' />
      </button>
      <div class='h-full overflow-y-auto p-12 lg:p-16'>
        <div class='flex flex-col justify-center items-center gap-y-8'>
          <logo height='3rem' />
        </div>
        <div class='py-8'>
          <facility-contact-card 
            v-for='facility in facilities'
            :facility-data='facility'
            :used-for-selection='true'
            :key='`facility-selection-card-${facility.id}`'
            class='border rounded-md p-4 mb-8'
            @click.native='updateSelectedFacility(facility.id)' />
        </div>
      </div>
  </modal>
</template>

<script>
import { mapState, mapActions, mapGetters }       from 'vuex'
import { XIcon }            from '@vue-hero-icons/outline'
import Logo from '@/components/Logo'
import FacilityContactCard from '@/components/FacilityContactCard'


export default {
  name: 'FacilitySelector',
  components: {
    XIcon,
    Logo,
    FacilityContactCard,
  },
  computed: {
    ...mapState('facilities', [
      'facilities',
      'facilityContactCardData',
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityId',
    ]),
    hasSelectedFacility () {
      return Boolean(this.selectedFacilityId)
    },
  },
  methods: {
    ...mapActions('facilities', [
      'selectFacility',
    ]),
    ...mapActions('prices', [
      'getPrices'
    ]),
    updateSelectedFacility (facilityId) {
      let selected = this.facilities.find(facility => facility.id === facilityId)
      this.selectFacility(selected).then(() => {
        localStorage.setItem('selectedFacilityId', facilityId)
        this.getPrices()
      })
      this.$modal.hide('facility-selector-modal')
    },
  }
}
</script>
