import base from './base'

const getCourts = () => base
  .get('/courts/').then(resp => resp.data)

const postCourt = (courtParams) => base
  .post('/courts/', courtParams).then(resp => resp.data)

const updateCourt = (courtId, courtParams) => base
  .patch(`/courts/${courtId}`, courtParams).then(resp => resp.data)

const deleteCourt = (courtId) => base
  .delete(`/courts/${courtId}`).then(resp => resp.data)

export default {
  getCourts: getCourts,
  postCourt: postCourt,
  updateCourt: updateCourt,
  deleteCourt: deleteCourt
}
