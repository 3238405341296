<template>
  <div class='lg:py-8 lg:px-12 h-screen flex flex-row justify-center items-center'>
    <logo height='8rem'></logo>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'

 // placeholder page for non-logged in users
export default {
  name: 'Schedule',
  components: {
    Logo,
  },
}
</script>
