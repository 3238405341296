<template>
  <div class=' text-left p-8 lg:p-16'>
    <logo height='4rem' class='mb-16' />
    <billing-cancellation-full-details  class='w-full' />
    <a href='mailto:thetennisplay@gmail.com'  class='block p-4 border rounded-md max-w-md my-4 hover:bg-gray-100 hover:underline'>Questions?  thetennisplay@gmail.com
    </a>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import BillingCancellationFullDetails from '@/components/BillingCancellationFullDetails'

export default {
  name: 'CustomerSupport',
  components: {
    Logo,
    BillingCancellationFullDetails,
  },
  data () {
    return {

    }
  },
  watch: {

  },
  computed: {

  },
  methods: {

  },
}
</script>

<style lang='scss'>

</style>
