import base from './base'

const getSubstitutionRequests = (facilityId) => base
  .get('/substitution_requests/', {params: { facility_id: facilityId }}).then(resp => resp.data)

// For a player
const getMySubstitutionRequests = () => base
  .get('/substitution_requests/my_requests/').then(resp => resp.data)

const patchApproveSubstitutionRequest = (requestId) => base
  .patch(`/substitution_requests/${requestId}/approve_substitution`).then(resp => resp.data)

const postSubstitutionRequest = (requestParams) => base
.post('/substitution_requests/', {substitution_request: requestParams}).then(resp => resp.data)

const updateSubstitutionRequest = (requestId, requestParams) => base
.patch(`/substitution_requests/${requestId}`, {substitution_request: requestParams}).then(resp => resp.data)

const cancelSubstitutionRequest = (requestId) => base
.patch(`/substitution_requests/${requestId}/cancel`).then(resp => resp.data)


export default {
  getSubstitutionRequests:   getSubstitutionRequests,
  getMySubstitutionRequests: getMySubstitutionRequests,
  patchApproveSubstitutionRequest: patchApproveSubstitutionRequest,
  postSubstitutionRequest:   postSubstitutionRequest,
  updateSubstitutionRequest: updateSubstitutionRequest,
  cancelSubstitutionRequest: cancelSubstitutionRequest,
}
