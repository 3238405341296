import base from '../base'

const create = (provider, userParams) => base
  .post('/users/sso_sessions', {
    provider: provider,
    user_params: userParams,
  }).then(resp => resp)

const refresh = () => base
  .patch('/users/sso_sessions').then(resp => resp)

const destroy = () => base
  .delete('/users/sso_sessions').then(resp => resp)

const logout = () => {
  return base.delete(`/users/sso_sessions/logout`).then(resp => resp)
}
const checkTokenStatus = () => {
  return base.get(`/users/sso_sessions/check_token`).then(resp => resp)
}

const patchLoginPassword = (patchLoginPasswordParams) => {
  return base.patch(`/users/sso_sessions/login_password`, patchLoginPasswordParams).then(resp => resp.data)
}

export default {
  create:           create,
  refresh:          refresh,
  destroy:          destroy,
  logout:           logout,
  checkTokenStatus: checkTokenStatus,
  patchLoginPassword: patchLoginPassword,
}
