import base from './base'

const getBookings = (facilityId, date) => base
  .get('/bookings/', {
    params: {
      booking_date: date,
      facility_id: facilityId
    }}).then(resp => resp.data)

const getSpecificBooking = (bookingId) => base
  .get(`/bookings/${bookingId}`).then(resp => resp.data)

const getCurrentTimeInKorea = () => base
  .get('/bookings/current_time_in_korea').then(resp => resp.data)

const postBooking = (bookingParams) => base
.post('/bookings/', {booking: bookingParams}).then(resp => resp.data)

const updateBooking = (bookingId, bookingParams) => base
.patch(`/bookings/${bookingId}`, {booking: bookingParams}).then(resp => resp.data)

const cancelBooking = (bookingId, bookingParams) => base
.patch(`/bookings/${bookingId}/cancel`, bookingParams).then(resp => resp.data)

const batchUpdateBookings = (bookingId, bookingParams) => base
.patch(`/bookings/${bookingId}/batch_update`, bookingParams).then(resp => resp.data)

const batchCancelBookingsRelatedRequest = (bookingParams) => base
.patch(`/bookings/batch_cancel`, bookingParams).then(resp => resp.data)

const batchCancelBookingsNotRelatedRequest = (bookingParams) => base
.patch(`/bookings/multiple_cancel`, bookingParams).then(resp => resp.data)

const getManagerBookingsInRange = (facilityId, dateArray, timeRangeStart, timeRangeEnd) => base
  .get('/bookings/manager_bookings_in_range', {
    params: {
      facility_id: facilityId,
      date_array: dateArray,
      time_range_start: timeRangeStart,
      time_range_end: timeRangeEnd,
    }}).then(resp => resp.data)

const getPlayerBookingsInRange = (facilityId, dateArray, timeRangeStart, timeRangeEnd) => base
  .get('/bookings/player_bookings_in_range', {
    params: {
      facility_id: facilityId,
      date_array: dateArray,
      time_range_start: timeRangeStart,
      time_range_end: timeRangeEnd,
    }}).then(resp => resp.data)

const getPlayerBookingsAll = () => base
  .get('/bookings/player_bookings_list').then(resp => resp.data)

const getBookingsOnRepeatedDate = (facilityId, courtId, repeatDateArray, timeRangeStart, timeRangeEnd) => base
  .get('/bookings/check_repeat_available', {
    params: {
      facility_id: facilityId,
      court_id: courtId,
      time_range_start: timeRangeStart,
      time_range_end:   timeRangeEnd,
      repeat_date_array: repeatDateArray,
    }}).then(resp => resp.data)

export default {
  getBookings: getBookings,
  getSpecificBooking: getSpecificBooking,
  getManagerBookingsInRange: getManagerBookingsInRange,
  getPlayerBookingsInRange: getPlayerBookingsInRange,
  getPlayerBookingsAll: getPlayerBookingsAll,
  getBookingsOnRepeatedDate: getBookingsOnRepeatedDate,
  getCurrentTimeInKorea: getCurrentTimeInKorea,
  postBooking: postBooking,
  updateBooking: updateBooking,
  cancelBooking: cancelBooking,
  batchUpdateBookings: batchUpdateBookings,
  batchCancelBookingsRelatedRequest: batchCancelBookingsRelatedRequest,
  batchCancelBookingsNotRelatedRequest: batchCancelBookingsNotRelatedRequest
}
