<template>
  <div ref='chart' :style='styleForHeight'>
    <v-chart :options='chartOptions' :style='styleForHeight' style='width: 100%;'/>
  </div>
</template>

<script>
  import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/tooltip'
// import StringHelpers from '@/utils/string-helpers'


export default {
  name: 'BarChartComparison',
  props: [
    'datum', 
    'dates', 
    'height', 
    'isPercentage', 
    'isCount'
  ],
  components: {
  'v-chart': ECharts
  },
  data () {
    return {
      barWidthCalc:  8
    }
  },
  computed: {
    styleForHeight () {
      return (this.height) ? `height:${this.height}` : 'height:100%'
    },
    indexOfLast () {
      return (this.datum && this.datum.length > 1) ? this.datum.length - 1 : 0
    },
    lastValue () {
      return this.datum[this.indexOfLast]
    },
    values () {
      let arr = this.datum.slice()
      arr.splice(this.indexOfLast, 1, {
        value: this.lastValue,
        itemStyle: { color: this.chartHighlightColor }
      })
      return arr
    },
    chartHighlightColor () {
      return '#41ABED'
    },
    setMaxData () {
      return (this.isPercentage) ? 1 : 'dataMax'
    },
    chartOptions () {
      return {
        xAxis: {
          type: 'category',
          show: false,
          data: this.dates,
          boundaryGap: [0,0],
        },
        yAxis: {
          type: 'value',
          max: this.setMaxData,
          show: false,
          boundaryGap: [0,0],
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        tooltip: {
          position: 'top',
          formatter: params => {          
            return (params.value) ? `${params.name}: ${this.tooltipString(params.value)}` : ''
          }
        },
        series: [
          {
            data: this.values,
            type: 'bar',
            barWidth: this.barWidthCalc,
            barCategoryGap: '1%',
            itemStyle: {
              color: '#CACACA',
            },
            markArea: {
              data: [[{yAxis: 0},{yAxis: this.lastValue}]],
              itemStyle: {
                color: this.chartHighlightColor,
                opacity: 0.1,
              }
            },
          }
        ]
      }
    },
  },
  methods: {
    tooltipString (val) {
      if (this.isPercentage) {
        return `${(val*100).toFixed()}%`
      } else {
        let type = 'money'
        if (this.isCount) { type = 'count' }
          console.log('type is; ', type)
        return `${val}`
      }  
    }
  },
  mounted () {
    if (this.$refs.chart.clientWidth < 200) {
      this.barWidthCalc = 5
    } else if (this.$refs.chart.clientWidth < 400) {
      this.barWidthCalc = 10
    } else {
      this.barWidthCalc = 16
    }
  }
}
</script>

<style lang='scss' scoped>

@media (min-width: 640px) {

}
</style>
