<template>
  <div>
    <button 
      @click='refreshTimer'
      class='border rounded w-full py-2 mt-4 uppercase text-gray-700 border-gray-200 hover:border-indigo-300 hover:shadow-lg text-sm flex flex-row justify-center items-center gap-x-1'>
      예약가능 시간 불러오기 {{ secondsLeftToRefresh }}초
      <refresh-icon class='h-4' />
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { RefreshIcon } from '@vue-hero-icons/outline'

export default {
  name: 'BookingsNavPlayerRefreshControl',
  components: {
    RefreshIcon,
  },
  data () {
    return {
      secondsLeftToRefresh: 150,
      interval: null,
    }
  },
  computed: {
    ...mapState('bookings', [
      'scheduleSelectedDay',
      'bookings',
    ]),
  },
  methods: {
    ...mapActions([
      'deactiveLoading',
    ]),
    ...mapActions('bookings', [
      'getPlayerUnbookableTimes',
    ]),
    ...mapMutations('manageRequests', [
      'removeNewBookingRequestSlotList',
    ]),
    refreshTimer () {
      this.secondsLeftToRefresh = 0
      clearInterval(this.interval)
      this.refreshAndResetTimer()
    },
    refreshAndResetTimer () {
      this.getPlayerUnbookableTimes().then(() => {
        this.secondsLeftToRefresh = 150
        this.bookings.forEach(booking => {
          this.removeNewBookingRequestSlotList(booking)
         })
        this.$nextTick(() => {
          this.deactiveLoading()
          this.runTimer()
        })
      })
    },
    runTimer () {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        this.secondsLeftToRefresh -= 1
        if (this.secondsLeftToRefresh <= 0) {
          clearInterval(this.interval)
          this.refreshAndResetTimer()
        }
      }, 1000)
    },
  },
  mounted () {
    this.runTimer()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
}
</script>
