import Vue       from 'vue'
import Vuex      from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import courtsApi from '@/api/v1/courts'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const emptyCourt = { courtName: '', id: 0 }

const state = () => ({
  courts: [],
  editingCourt: cloneDeep(emptyCourt),
  hasOpenSlotsCourt: [],
})

const getters = {
  editingCourtId: state => {
    return state.editingCourt.id
  },
  courtNameFromId: state => courtId => {
    var court = state.courts.find(court => court.id === courtId)
    return (court) ? court.courtName : '?'
  },
  courtSlotLength: state => courtId => {
    var court = state.courts.find(court => court.id === courtId)
    return (court && court.numOfHourDivided) ? (1 / court.numOfHourDivided) : 1.0
  },
  courtStartTimeFromId: state => courtId => {
    var court = state.courts.find(court => court.id === courtId)
    return (court) ? court.useStartTime : '4'
  },
  courtEndTimeFromId: state => courtId => {
    var court = state.courts.find(court => court.id === courtId)
    return (court) ? court.useEndTime : '29'
  },
  earliestCourtStartTime: state => {
    let arr = state.courts.map(court => {
      return parseInt(court.useStartTime)
    })
    return (arr.length > 0) ? Math.min(...arr) : 5 
  },
  latestCourtEndTime: state => {
    let arr = state.courts.map(court => {
      return parseInt(court.useEndTime)
    })
    return (arr.length > 0) ? Math.max(...arr) : 29 
  },
  facilityIdFromCourtId: state => courtId => {
    var court = state.courts.find(court => court.id === courtId)
    return (court) ? court.facilityId : 0
  },
  multiSlotBookableFromCourtId: state => courtId => {
    var court = state.courts.find(court => court.id === courtId)
    return (court) ? !court.singleSlotOnly : true
  },
  openSlotsEmpty: state => {
    return state.hasOpenSlotsCourt.length === 0
  },
  getField
}

const actions = {
  getCourts ({ commit }) {
    return new Promise(resolve => {
      courtsApi.getCourts().then(resp => {
        commit('setCourts', camelcaseKeys(resp.courts).sort((a,b)=> parseInt(a.id) - parseInt(b.id)))
        resolve()
      })
    })
  },
  updateCourt ({ getters, commit }, updatedCourt) {
    return new Promise(resolve => {
      courtsApi.updateCourt(getters.editingCourtId, updatedCourt).then(resp => {
        commit('updateEditedCourts', resp.court)
        resolve()
      })
    })
  },
  resetEditingCourt ({ commit }) {
    commit('setEditingCourt', cloneDeep(emptyCourt))
  },
  selectCourtToEdit ({ commit }, court) {
    commit('setEditingCourt', court)
  },
  updateCourtDetails (context, courtDetails) {
    console.log(context)
    console.log(courtDetails)
  }
}

const mutations = {
  setCourts (state, courts) {
    state.courts = courts
  },
  pushToHasOpenSlotsCourt (state, courtId) {
    let index = state.hasOpenSlotsCourt.findIndex(hasOpenSlotsCourt => parseInt(hasOpenSlotsCourt) === courtId)
    if (index == -1) {
      state.hasOpenSlotsCourt.push(courtId)
    }
  },
  removeFromHasOpenSlotsCourt (state, courtId) {
    let index = state.hasOpenSlotsCourt.findIndex(hasOpenSlotsCourt => parseInt(hasOpenSlotsCourt) === courtId)
    if (index !== -1) {
      state.hasOpenSlotsCourt.splice(index, 1)
    }
  },
  setEditingCourt (state, court) {
    state.editingCourt = court
  },
  updateEditedCourts (state, updatedCourt) {
    let index = state.courts.findIndex(court => parseInt(court.id) === state.editingcourtId)
    if (index !== -1) {
      state.courts.splice(index, 1, updatedCourt)
    }
  },
  updateField
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
