import Vue    from 'vue'
import App    from './App.vue'
import router from './router'
import store  from './store'

Vue.config.productionTip = false


///////////////// Lodash - Use Some javascript utilities that aren't built into ES6
import VueLodash from 'vue-lodash'
import cloneDeep from 'lodash/cloneDeep'
import range     from 'lodash/range'

Vue.use(VueLodash, { lodash: { cloneDeep, range } })


//////////////// Allows us to portal components to different locations
import PortalVue from 'portal-vue'
Vue.use(PortalVue)


/////////////////// Styles to make tailwind work
import './assets/tailwind.css'


/////////////////// prebuilt multiselect
import vSelect from 'vue-select'
import "vue-select/src/scss/vue-select.scss"
// import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)


////////////// Use prebuilt components from Element-UI
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
Vue.use(Element, {locale})


//////////////// To Use Copy Link
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)


//////////////// Drag and drop slots
import VueDragDrop from 'vue-drag-drop'
Vue.use(VueDragDrop)

//////////////// To Use Formatted Currency Value On Input
import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)
//////////////// Get korean address input
// import VueDaumPostcode from 'vue-daum-postcode'
// Vue.use(VueDaumPostcode)

import VModal from 'vue-js-modal'
Vue.use(VModal)

///////////////// Need to get app id for google analytics
import VueGtag from 'vue-gtag'
const isDevelopment = process.env.NODE_ENV === 'development'
Vue.use(VueGtag, {
  config: {
    id: '3194265104',
    enabled: !isDevelopment
  },
  includes: [
    { id: 'G-CGHNBMZHPQ' }, //
  ],
}, router)



//////////////// Use v-calendar & v-date-picker components
import VCalendar from 'v-calendar'
Vue.use(VCalendar)


//////////////// Injecting meta tags into header
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
