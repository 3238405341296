<template>
  <div>
    <div v-if='isCourtManager'
      class='my-6 flex flex-row justify-between'>
      <div>
        <label for='courtName' class='block text-sm font-medium text-gray-500'>코트</label>
        <div class='font-normal text-2xl text-gray-900'>{{courtNameFromId(drilldownBooking.courtId)}}</div>
      </div>
      <div class='mb-6'>
        <label for='bookingDateTime' class='block text-sm font-medium text-gray-500'>{{bookingDateString}}</label>
        <div class='font-normal text-2xl text-gray-900'>
          {{dayOfWeekString}}
          <div class='text-sm font-light text-gray-500 flex flex-row justify-start items-center gap-x-2'>
            <span v-if='isRepeatingBooking'>{{bookingRepeatString}}</span>
            <button v-if='canExtendBooking'
              @click='extendBooking'
              class='flex flex-row justify-start items-center gap-x-0.5 border py-1 pl-1 pr-2 rounded-md'>
              <plus-circle-icon class='h-4' />
              <span class='block text-xs'>연장</span>
            </button>
          </div>
        </div>
      </div>
      <div v-if='drilldownBooking.bookingType !== "blocked"'
        class='w-1/3'>
        <label class='block text-sm font-medium text-gray-500'>예약자</label>
        <div class='font-normal text-2xl text-gray-900'>
          {{drilldownBooking.profile.name}}
          <div v-if='hasPhoneNumber'
            class='text-sm font-light text-gray-500'>
            {{drilldownBooking.profile.phoneNumber}}
          </div>
        </div>
      </div>
    </div>
    <div v-else class='my-6'>
      <label for='courtName' class='block text-sm font-medium text-gray-500'>장소</label>
      <div class='font-medium text-lg text-gray-900'>{{selectedFacilityName}} - 코트{{courtNameFromId(drilldownBooking.courtId)}}</div>
    </div>
    <table class='table-fixed w-full'>
      <thead>
        <tr class='text-gray-500 text-xs'>
          <th>시간</th>
          <th class='text-right pr-2'>가격</th>
          <td v-if='isCourtManager' class='w-10'></td>
        </tr>
        <tr class='font-semibold text-gray-900'>
          <th class='py-2'>
            {{timeString(drilldownBooking.startTime)}} - {{timeString(drilldownBooking.endTime)}}시
            <span class='text-gray-500'>: {{bookingsFromBlock.length}}시간</span>
          </th>
          <th class='py-2 text-right pr-2'>{{bookingPriceString(sumOfPrices)}}원</th>
          <th v-if='canCancelBooking'></th>
        </tr>
      </thead>
      <tbody v-for='booking in bookingsFromBlock'
        :key='`bookingDetails-${booking.id}`'
        @click='editBooking(booking)'
        :class='tbodyStyle'>
        <tr class='text-sm border-t border-solid border-gray-200'>
          <td class='pt-2'>
            {{timeString(booking.startTime)}} - {{timeString(booking.endTime)}}시
          </td>
          <td class='pt-2 text-right pr-2'>{{bookingPriceString(booking.price)}}원</td>
          <td v-if='canCancelBooking'
            class='pt-2 text-right'>
            <button
              @click.stop='deleteBooking(booking)'
              class='text-sm text-gray-500 hover:text-red-700 border border-solid border-gray-100 hover:border-red-300 rounded p-1'>
              <trash-icon class='h-4' />
            </button>
          </td>
        </tr>
        <tr class='text-gray-500 text-xs'>
          <td :colspan='noteColSpan' class='pb-2'>{{booking.note}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
import StringHelpers from '@/utils/string-helpers'
import { PlusCircleIcon, TrashIcon } from '@vue-hero-icons/outline'
import { mapGetters, mapState, mapActions }         from 'vuex'
import { mapFields }                                from 'vuex-map-fields'
import numbro from 'numbro'
import dayjs  from 'dayjs'

export default {
  name: 'ExistingBookingView',
  components: {
    PlusCircleIcon,
    TrashIcon,
  },
  computed: {
    ...mapState('bookings', [
      'drilldownBooking'
    ]),
    ...mapFields('bookings', [
      'drilldownBooking.presentStatus'
    ]),
    ...mapGetters([
      'isCourtManager'
    ]),
    ...mapGetters('bookings', [
      'bookingFromId'
    ]),
    ...mapGetters('courts', [
      'courtNameFromId'
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityName'
    ]),
    hasPhoneNumber () {
      return (this.drilldownBooking.profile.phoneNumber)
    },
    isRepeatingBooking () {
      return this.drilldownBooking.totalRepeatCount > 1
    },
    bookingRepeatString () {
      return StringHelpers.bookingRepeatString(this.drilldownBooking)
    },
    bookingDateString () {
      return dayjs(new Date(this.drilldownBooking.bookingDate)).format('M월 DD일')
    },
    dayOfWeekString () {
      return StringHelpers.dayOfWeekKorean(this.drilldownBooking.bookingDate) + '요일'
    },
    bookingsFromBlock () {
      if (this.drilldownBooking.length > 1) {
        return this.drilldownBooking.id.split(',').map(id => this.bookingFromId(parseInt(id)))
      } else {
        return [this.drilldownBooking]
      }
    },
    canExtendBooking () { // for now, you can extend a booking if its made by a amanger, not a player
      return !this.drilldownBooking.bookingUserId
    },
    canCancelBooking () { // for now, you can cancel a booking slot if its made by a amanger, not a player
      return this.isCourtManager && !this.drilldownBooking.bookingUserId
    },
    noteColSpan () {
      return (this.isCourtManager) ?  3 : 2
    },
    tbodyStyle () {
      return (this.isCourtManager) ?  'hover:bg-gray-100 cursor-pointer' : ''
    },
    sumOfPrices () {
      if (this.bookingsFromBlock.length > 0) {
        return this.bookingsFromBlock.reduce((a, b) => a + b.price, 0)
      } else {
        return 0
      }
    },
  },
  methods: {
    ...mapActions('bookings', [
      'updateBookingAttendanceStatus'
    ]),
    deleteBooking (booking) {
      this.$emit('delete-booking', booking)
    },
    editBooking (booking) {
      if (this.isCourtManager) {
        this.$emit('edit-booking', booking)
      } else {
        console.log('dont do editing if you are a player')
      }
    },
    extendBooking () {
      this.$emit('extend-booking')
    },
    bookingPriceString (price) {
      return numbro(price).format({thousandSeparated: true})
    },
    timeString (time) {
      return parseInt(time)
    },
  }
}
</script>
