<template>
  <div class='relative max-w-lg mx-auto flex flex-row justify-center h-full items-center'>
    <div id='container' class='w-96 mx-auto m-16 bg-white rounded'>
      <div class='divider' aria-hidden='true'></div>
      <p class='loading-text' aria-label='Loading'>
        <span class='letter' aria-hidden='true'>T</span>
        <span class='letter' aria-hidden='true'>E</span>
        <span class='letter' aria-hidden='true'>N</span>
        <span class='letter' aria-hidden='true'>N</span>
        <span class='letter' aria-hidden='true'>i</span>
        <span class='letter' aria-hidden='true'>S</span>
      </p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Loading',
}
</script>

<style scoped lang='scss'>
@mixin pseudo ($content: '') {
  position: absolute;
  content: $content;
}

* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box;
  }
}

#container {
  position: relative;
  // transform: scale(0.725);
}

.divider {
  position: absolute;
  z-index: 2;
  top: 67px;
  left: 242px;
  width: 20px;
  height: 10px;
  background-color: #FFF;
}

.loading-text {
  position: relative;
  font-size: 3.75rem;
  font-weight: 800;
  margin: 0;
  white-space: nowrap;
  // background-color: yellow;
  &::before {
    // For dot
    @include pseudo;
    z-index: 1;
    top: 40px;
    left: 172px;
    width: 12px;
    height: 12px;
    background: #195955;
    border-radius: 50%;
    animation: dotMove 1400ms cubic-bezier(0.25,0.25,0.75,0.75) infinite;
  }
  .letter {
    display: inline-block;
    position: relative;
    color: #222;
    letter-spacing: 0px;
    &:nth-child(1) {
      // For the letter "t"
      transform-origin: 100% 70%;
      transform: scale(1, 1);
      &::before {
        @include pseudo;
        top: 20px;
        left: 12px;
        width: 12px;
        height: 36px;
        background: #FFF;
        transform-origin: 100% 0;
        animation: lineStretch 1400ms cubic-bezier(0.25,0.25,0.75,0.75) infinite;
      }
    }
    &:nth-child(5) {
      // For the letter "i"
      transform-origin: 100% 70%;
      animation: letterStretch 1400ms cubic-bezier(0.25,0.23,0.73,0.75) infinite;
      &::before {
        @include pseudo;
        top: 16px;
        right: 2px;
        width: 12px;
        height: 16px;
        background: #fff;
        
      }
    }
  }
}

// For the dot
@keyframes dotMove {
  0%, 100% {
    transform: rotate(180deg) translate(-73px, -10px) rotate(-180deg)
  }
  50% {
    transform: rotate(0deg) translate(-74px, 10px) rotate(0deg)
  }
}

// For the letter "i"
@keyframes letterStretch {
  0%, 100% {
    transform: scale(1, 0.35);
    transform-origin: 100% 75%;
  }
  18% {
    transform: scale(1, 2.125);
    transform-origin: 100% 67%;
  }
  28% {
    transform: scale(1, 2.125);
    transform-origin: 100% 67%;
  }
  // 37% {
  //   transform: scale(1, 0.875);
  //   transform-origin: 100% 75%;
  // }
  // 46% {
  //   transform: scale(1, 1.03);
  //   transform-origin: 100% 75%;
  // }
  50% {
    transform: scale(1);
    transform-origin: 100% 75%;
  }
  97% {
    transform: scale(1);
    transform-origin: 100% 75%;
  }
}

// For the letter "T"
@keyframes lineStretch {
  0%, 45%, 70%, 100% {
    transform: scaleY(0.125);
  }
  49% {
    transform: scaleY(0.75);
  }
  50% {
    transform: scaleY(0.875);
  }
  53% {
    transform: scaleY(0.5);
  }
  60% {
    transform: scaleY(0);
  }
  68% {
    transform: scaleY(0.18);
  }
}

@media(min-width: 48rem) {
  #container {
    transform: scale(0.725rem);
  }
}

@media(min-width: 62rem) {
  #container {
    transform: scale(0.85);
  }
}

</style>
