<template>
  <div class='overflow-y-auto'>
    <player-drilldown-summary />
    <player-drilldown-requests class='hidden' />
    <player-drilldown-bookings class='hidden' />
    <player-drilldown-booking-billings />
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import PlayerDrilldownSummary         from '@/views/manage-players/PlayerDrilldownSummary'
import PlayerDrilldownBookings        from '@/views/manage-players/PlayerDrilldownBookings'
import PlayerDrilldownRequests        from '@/views/manage-players/PlayerDrilldownRequests'
import PlayerDrilldownBookingBillings from '@/views/manage-players/PlayerDrilldownBookingBillings'

export default {
  name: 'PlayerDrilldown',
  components: {
    PlayerDrilldownSummary,
    PlayerDrilldownBookings,
    PlayerDrilldownRequests,
    PlayerDrilldownBookingBillings,
  },
  computed: {
    ...mapState('managePlayers', [
      'drilldownPlayer'
    ]),
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('managePlayers', [
      'resetDrilldownPlayer'
    ]),

  },
  beforeDestroy () {
    this.resetDrilldownPlayer()
    this.sidepanelClose()
  }
}
</script>
