<template>
  <div>
    <div 
      class='flex flex-row items-start justify-start p-4 border rounded-t-md'
      style='background-color: #FAFAFA;'>
      <div class='w-1/3 flex-shrink-0 flex-grow-0 rounded-md w-40 flex flex-col justify-start items-start gap-y-1'>
        <h3 class='text-sm'>{{monthAndDay}}</h3>
        <h1 class='text-3xl font-semibold text-gray-900'>{{dayOfWeek}}요일</h1>
        <h1 v-if='daysUntil'
          class='text-sm font-normal'>
          {{daysUntilString}}
        </h1>
      </div>
      <facility-contact-card 
        :facility-data='selectedFacility'
        :hide-map='true'
        class='max-w-lg' />
    </div>
    <div class='pt-4 border-l border-r border-b rounded-b-md'>
      <table class='w-full'>
        <thead>
          <th class='py-2 px-2 text-left'>코트</th>
          <th class='py-2 px-2 text-right'>시간</th>
          <th class='py-2 px-2 text-right'>가격</th>
        </thead>
        <tbody>
          <tr v-for='(schedule, index) in currentOrder.schedules'
            :key='`schedule-${index}`'
            class='text-gray-900 border-t'>
            <td class='py-2 px-2 text-left'>코트 {{schedule.courtName}}</td>
            <td class='py-2 px-2 text-right'>{{schedule.startTime}} ~ {{schedule.endTime}}시</td>
            <td class='py-2 px-2 text-right'>{{formatMoney(schedule.price)}}</td>
          </tr>
        </tbody>
        <tbody v-if='selectedFacilityBookingRequestFeeAmount > 0'>
          <tr class='border-t bg-gray-100 text-xs'>
            <td 
              colspan='2' 
              class='py-2 px-2 text-left'>
              온라인예약 수수료 (부가세포함)
            </td>
            <td class='py-2 px-2 text-right'>{{selectedFacilityBookingRequestFeeAmount}}원</td>
          </tr>
        </tbody>
        <tbody>
          <tr class='border-t bg-gray-100 font-bold'>
            <td 
              colspan='2' 
              class='py-4 px-2 text-left'>
              총 금액
            </td>
            <td class='py-4 px-2 text-right'>{{bookingsTotalPriceWithFeeString}}원</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FacilityContactCard from '@/components/FacilityContactCard'
import StringHelpers       from '@/utils/string-helpers'
import dayjs  from 'dayjs'
import numbro from 'numbro'

export default {
  name: 'PlayerNewBookingRequestCard',
  components: {
    FacilityContactCard,
  },
  computed: {
    ...mapState('payments', [
      'currentOrder',
    ]),
    ...mapState('facilities', [
      'facilities'
    ]),
    monthAndDay () {
      return (this.bookingDateString) ? StringHelpers.monthDayWithKorean(this.bookingDateString) : ''
    },
    dayOfWeek () {
      return (this.bookingDateString) ? StringHelpers.dayOfWeekKorean(this.bookingDateString) : ''
    },
    daysUntil () {
      return dayjs(this.bookingDateString).diff(new Date(), 'day') + 1
    },
    daysUntilString () {
      return `${this.daysUntil}일 전`
    },
    bookingDateString () {
      return this.currentOrder.schedules && this.currentOrder.schedules[0] ? this.currentOrder.schedules[0].bookingDate : ''
    },
    bookingsTotalPriceWithFeeString () {
      return numbro(parseInt(this.currentOrder.bookingsTotalPrice) + this.selectedFacilityBookingRequestFeeAmount).format({thousandSeparated: true})
    },
    facilityNameString () {
      return this.currentOrder.schedules.length && this.currentOrder.schedules[0] ? this.currentOrder.schedules[0].facilityName : ''
    },
    selectedFacility () {
      return this.facilities.length > 0 ? this.facilities.find(facility => facility.id === this.currentOrder.facilityId) : {}
    },
    selectedFacilityBookingRequestFeeAmount () {
      return this.selectedFacility.configurations && this.selectedFacility.configurations.bookingRequestFeeAmount ? parseInt(this.selectedFacility.configurations.bookingRequestFeeAmount) : 0
    },
  },
  methods: {
    formatMoney (amount) {
      return StringHelpers.moneyWithUnits(amount)
    },
  },
}
</script>
