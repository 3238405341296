import Vue         from 'vue'
import Vuex        from 'vuex'
import cloneDeep   from 'lodash/cloneDeep'
import dayjs       from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'
import bookingsApi             from '@/api/v1/bookings'
import newBookingRequestsApi   from '@/api/v1/new-booking-requests'
import substitutionRequestsApi from '@/api/v1/substitution-requests'
import BookingRequestHelpers   from '@/utils/booking-request-helpers'


Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')
// const snakecaseKeys = require('snakecase-keys')

const newBooking = {
  bookingType: 'open',
  name: '',
  courtId: 0,
  startTime: '0',
  endTime: '1',
  length: 1,
}

const state = () => ({
  weeklyScheduledBookings: [],
  scheduleSelectedDate: dayjs(new Date()).format('YYYY-MM-DD'),
  drilldownBooking: cloneDeep(newBooking),
  emptyBooking: cloneDeep(newBooking),
  playerBookingsHistory: [],
  substitutionRequests: [],
  startTime: 0,
  endTime: 24,
  playerNewBookingRequestDateTimeList: [],
  myNewBookingRequests: [], // is this the same thing as above???
})

const getters = {
  activeNewBookingRequests: (state) => {
    return state.myNewBookingRequests.filter(request => request.approvedStatus === 'approved' && dayjs().isBefore(request.endDate, 'day'))
  },
  lastBookingDate: (state, getters) => {
    if (getters.activeNewBookingRequests.length === 1) {
      return getters.activeNewBookingRequests[0].endDate
    } else if (getters.activeNewBookingRequests.length > 1) {
      return dayjs(new Date()).format('YYYY-MM-DD') // need to update to compare dates
    } else {
      return dayjs(new Date()).format('YYYY-MM-DD')
    }
  },
  hasSubstitutionRequests: (state) => {
    return state.substitutionRequests.length > 0
  },
  substitutionRequestRequestedBooking: (state) => (requestId) => {
    return getters.hasSubstitutionRequests ? state.substitutionRequests.find(request => request.id === requestId).after : {}
  },
  bookingsForCourtWithDate: state => (courtId, date) => {
    return state.weeklyScheduledBookings.filter(booking => booking.courtId === courtId && booking.bookingDate === date)
                                        .sort((a, b) => parseFloat(a.startTime) - parseFloat(b.startTime))      
  },
  // bookingsForCourt: state => courtId => {
  //   return state.weeklyScheduledBookings.filter(booking => booking.courtId === courtId && booking.bookingDate === state.scheduleSelectedDate)
  // },
  // isRepeatedBooking: state => {
  //   return state.drilldownBooking.bookingContractId
  // },
  // isNewBooking: state => {
  //   return state.drilldownBooking.bookingType === 'open'
  // },
  cancelledBookings: state => {
    return state.playerBookingsHistory.filter(booking => booking.bookingType === 'cancelled')
                                      .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate))
  },
  pastBookings: state => {
    return state.playerBookingsHistory.filter(booking => booking.bookingType !== 'cancelled' &&
                                                         booking.bookingDate < dayjs().format('YYYY-MM-DD'))
                                      .sort((a, b) => new Date(b.bookingDate) - new Date(a.bookingDate))
  },
  todaysBookings: state => {
    return state.playerBookingsHistory.filter(booking => booking.bookingType !== 'cancelled' &&
                                                         booking.bookingDate == dayjs().format('YYYY-MM-DD'))
  },
  upcomingBookings: state => {
    return state.playerBookingsHistory.filter(booking => booking.bookingType !== 'cancelled' &&
                                                         booking.bookingDate > dayjs().format('YYYY-MM-DD'))
                                      .sort((a, b) => new Date(a.bookingDate) - new Date(b.bookingDate))
  },
  hasSameMonthSubstitution: state => month => {
    return state.playerBookingsHistory.find(booking => booking.bookingType === 'substitution' &&
                                                         dayjs(booking.bookingDate).month() === month)
  },
  bookingsDateDayOfWeek: state => {
    return dayjs(state.scheduleSelectedDate).day()
  },
  weekForSelectedDay: state => {
      let arr = []
      let selectedDayOfWeek = dayjs(state.scheduleSelectedDate).day()
      let day = state.scheduleSelectedDate
      for (let i = selectedDayOfWeek; i <= 6; i++) {
        arr.push(dayjs(day).format('YYYY-MM-DD'))
        day = dayjs(day).add(1, 'd')
      }
      day = state.scheduleSelectedDate
      for (let i = selectedDayOfWeek; i > 0; i--) {
        day = dayjs(day).subtract(1, 'd')
        arr.unshift(dayjs(day).format('YYYY-MM-DD'))
        
      }
      return arr
    },
    hasPlayerNewBookingRequestDateTimeList: state => {
      return state.playerNewBookingRequestDateTimeList.length > 0
    },
  getField,
}

const actions = {
  getPlayerBookings ({ commit, dispatch }) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.getPlayerBookingsAll().then(resp => {
        commit('setPlayerBookings', camelcaseKeys(resp.bookings))
        dispatch('stopFullPageLoading', null, {root: true})
        resolve()
      })
    })
  },
  getSpecificBookingById ({ dispatch }, bookingId) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.getSpecificBooking(bookingId).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(camelcaseKeys(resp.booking))
      })
    })
  },
  getMySubstitutionRequests ({ commit, dispatch }) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      substitutionRequestsApi.getMySubstitutionRequests().then(resp => {
        commit('setSubstitutionRequests', camelcaseKeys(resp.substitution_requests, {deep: true}))
        dispatch('stopFullPageLoading', null, {root: true})
        resolve()
      })
    })
  },
  postPlayerNewBookingRequests ({ commit, dispatch }, submitData) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      newBookingRequestsApi.createNewBookingRequest(submitData).then(resp => {
        commit('addCreatedNewBookingRequsetToMyNewBookingRequests', camelcaseKeys(resp.new_booking_request, {deep: true}))
        commit('addMyNewBookingRequestsOnWeeklyScheduledBookings', [camelcaseKeys(resp.new_booking_request, {deep: true})])
        commit('resetPlayerNewBookingRequestDateTimeList')
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(resp)
      })
    })
  },
  makeSubstitutionRequest ({ dispatch }, params) {
    return new Promise(resolve => {
      dispatch('showFullPageLoading', null, {root: true})
      substitutionRequestsApi.postSubstitutionRequest(params).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(resp)
      })
    })
  },
  selectPlayerNewBookingRequestDateTime ({ commit }, playerNewBookingRequestDateTime) {
    commit('setItemOnPlayerNewBookingRequestDateTimeList', playerNewBookingRequestDateTime)
  },
  cancelRequest ({ commit, dispatch }, substitutionRequestId) {
    return new Promise(resolve => {
      dispatch('showFullPageLoading', null, {root: true})
      substitutionRequestsApi.cancelSubstitutionRequest(substitutionRequestId).then(resp => {
        commit('removeSubstitutionRequest', substitutionRequestId)
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(resp)
      })
    })
  },
}

const mutations = {
  setPlayerBookings (state, bookings) {
    state.playerBookingsHistory = bookings
  },
  setWeeklyScheduledBookings (state, scheduledBookings) {
    state.weeklyScheduledBookings = scheduledBookings
  },
  setMyNewBookingRequests (state, requests) {
    state.myNewBookingRequests = requests
  },
  setItemOnPlayerNewBookingRequestDateTimeList (state, playerNewBookingRequestDateTime) {
    let index = state.playerNewBookingRequestDateTimeList.findIndex(newBookingRequest =>  BookingRequestHelpers.isSameRequestSlot(newBookingRequest, playerNewBookingRequestDateTime))
    if (index === -1) {
      state.playerNewBookingRequestDateTimeList.push(playerNewBookingRequestDateTime)
    } else {
      state.playerNewBookingRequestDateTimeList.splice(index, 1)
    }
  },
  removeItemOnPlayerNewBookingRequestDateTimeList (state, playerNewBookingRequestDateTime) {
    let index = state.playerNewBookingRequestDateTimeList.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, playerNewBookingRequestDateTime))
    if (index !== -1) {
      state.playerNewBookingRequestDateTimeList.splice(index, 1)
    }
  },
  resetPlayerNewBookingRequestDateTimeList (state) {
    state.playerNewBookingRequestDateTimeList = []
  },
  removeSubstitutionRequest (state, requestId) {
    let index = state.substitutionRequests.findIndex(request => request.id === requestId)
    if (index !== -1) {
      state.substitutionRequests.splice(index, 1)
    }
  },
  setSubstitutionRequests (state, requests) {
    state.substitutionRequests = requests
  },
  setWeeklyScheduleBookings (state, scheduleBookings) {
    state.weeklyScheduleBookings = scheduleBookings
  },
  addCreatedNewBookingRequsetToMyNewBookingRequests (state, createdNewBookingRequest) {
    let index = state.myNewBookingRequests.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, createdNewBookingRequest))
    if (index == -1) {
      state.myNewBookingRequests.push(createdNewBookingRequest)
    }
  },
  addMyNewBookingRequestsOnWeeklyScheduledBookings (state, myNewBookingRequests) {
    myNewBookingRequests.forEach(newBookingRequest => 
      newBookingRequest.schedules.forEach(schedule=> {
        let index = state.weeklyScheduledBookings.findIndex(weeklyScheduledBooking => BookingRequestHelpers.isSameRequestSlot(weeklyScheduledBooking, schedule))
        if (index == -1) {
          state.weeklyScheduledBookings.push({
            facilityId: newBookingRequest.facilityId,
            bookingDate: schedule.bookingDate,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            courtId: schedule.courtId,
            bookingType: 'my_new_booking_request'
          })
        }
      })
    )
  },
  updateField
}




export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

