<template>
    <section 
      class='middle-content-section viewport-size background-image-full flex flex-col lg:flex-row justify-center items-center'>
      <div class='w-full lg:w-1/2 flex-shrink-0 text-left lg:text-right pr-4 lg:pr-20 pl-4 lg:pl-0 flex flex-row lg:flex-col items-center lg:items-end justify-center lg:justify-end gap-x-20'>
        <logo 
          :height='logoHeight' 
          is-white-version='true' />
        <ul class='mt-0 lg:mt-24 text-white uppercase font-bold text-base lg:text-2xl'>
          <li
            class='py-1 lg:py-4 relative content-title'
            :class='showRow(1)'>
            Search
            <div
              class='content-dot rounded-full h-3 w-3 border border-green-400 bg-green-400 absolute' 
              style='top: calc(50% - 0.375rem);'></div>
          </li>
          <li
            class='py-1 lg:py-4 relative content-title'
            :class='showRow(2)'>
            Book
            <div
              class='content-dot rounded-full h-3 w-3 border border-green-400 bg-green-400 absolute' 
              style='top: calc(50% - 0.375rem);'></div>
          </li>
          <li
            class='py-1 lg:py-4 relative content-title'
            :class='showRow(3)'>
            Play
            <div
              class='content-dot rounded-full h-3 w-3 border border-green-400 bg-green-400 absolute' 
              style='top: calc(50% - 0.375rem);'></div>
          </li>
        </ul>
      </div>
      <div class='w-full lg:w-1/2 flex-shrink-0 pl-4 lg:pl-20 pr-4 lg:pr-0 mt-12 lg:mt-0'>
        <div class='max-w-xl'>
          <img :src='screenshotUrl' 
            class='block screenshot screenshot-shadow rounded-lg mx-auto' style='max-height: 40vh;' />
          <div class='max-w-md mx-auto mt-8 lg:mt-20 text-left text-sm lg:text-base text-gray-200 lg:text-gray-700 leading-5 lg:leading-6 px-8 lg:px-12'>
            <slot></slot>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import Logo from '@/components/Logo'
export default {
  name: 'MiddleContentSection',
  components: {
    Logo,
  },
  props: [
    'sectionIndex',
  ],
  computed: {
    screenshotUrl () {
      return `screenshot_${this.sectionIndex}.png`
    },
    logoHeight () {
      return (window.innerWidth < 1024) ? '2rem' : '5rem'
    }
  },
  methods: {
    showRow (index) {
      return (this.sectionIndex == index) ? 'selected-content' : ''
    }
  }
}
</script>

<style lang='scss'>
.viewport-size {
  width: 100vw;
  height: 100vh;
}

.screenshot {
  height: 33vh;
}

.screenshot-shadow {
  box-shadow:
    2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
    6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
    100px 100px 80px rgba(0, 0, 0, 0.07)
  ;
}

.content-title {
  @apply opacity-50 text-gray-400;
}

.content-title.selected-content {
  opacity: 1;
  color: #FFF;
}

.content-dot {
  @apply opacity-0;
  left: -1.5rem;
}

.selected-content .content-dot {
  @apply opacity-100;
}


.background-image-full {
  background-repeat: no-repeat; 
  background-position: left; 
  background-size: 200%;
}

.middle-content-section {
  background-image:url("/bg_landing_middle_mobile.png");
}

@media (min-width: 1024px) {
  .background-image-full {
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: cover;
  }

  .middle-content-section {
    background-image:url("/bg_landing_middle.png");
  }

  .screenshot {
    height: auto;
  }
  
  .content-dot {
    left: auto;
    right: -5.375rem;
  }

}

</style>
