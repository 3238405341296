import Vue       from 'vue'
import Vuex      from 'vuex'
import facilitiesApi from '@/api/v1/facilities'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  managedFacilities: [], // for court managers
  selectedManagedFacility: null,
  facilities: [], // for players
  selectedFacility: null,
})

const getters = {
  currentFacilityId: (state, getters, rootState, rootGetters) => {
    return (rootGetters['isCourtManager']) ? getters.selectedManagedFacilityId : getters.selectedFacilityId
  },
  currentFacilityName: (state, getters, rootState, rootGetters) => {
    return (rootGetters['isCourtManager']) ? getters.selectedManagedFacilityName : getters.selectedFacilityName
  },
  currentFacilitySpecialPrices: (state, getters, rootState, rootGetters) => {
    return (rootGetters['isCourtManager']) ? camelcaseKeys(getters.selectedManagedFacilitySpecialPrices, {deep: true}) : getters.selectedFacilitySpecialPrices
  },
  // for manager
  selectedManagedFacilityId: state => {
    return (state.selectedManagedFacility !== null) ? state.selectedManagedFacility.id : 0
  },
  selectedManagedFacilityName: state => {
    return (state.selectedManagedFacility !== null) ? state.selectedManagedFacility.facility_name : ''
  },
  selectedManagedFacilitySpecialPrices: state => {
    return (state.selectedManagedFacility && state.selectedManagedFacility.special_prices) ? state.selectedManagedFacility.special_prices : []
  },
  // for player
  selectedFacilityId: state => {
    return (state.selectedFacility) ? state.selectedFacility.id : 0
  },
  selectedFacilityName: state => {
    return (state.selectedFacility !== null) ? state.selectedFacility.facilityName : 0
  },
  selectedFacilitySpecialPrices: state => {
    return (state.selectedFacility && state.selectedFacility.specialPrices) ? state.selectedFacility.specialPrices : []
  },
  showingFacilityContactCardData: state => { // prob could just be showing Facility later
    return state.selectedFacility
  },
  // configurations
  selectedFacilityBookingRequestFeeAmount: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.bookingRequestFeeAmount) ? parseInt(state.selectedFacility.configurations.bookingRequestFeeAmount) : 0
  },
  selectedFacilityBookableHoursPerDay: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.bookableHoursPerDay) ? parseInt(state.selectedFacility.configurations.bookableHoursPerDay) : 12
  },
  selectedFacilityHidePlayerPaymentButton: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.hidePlayerPaymentButton) ? state.selectedFacility.configurations.hidePlayerPaymentButton === 'hide' : false
  },
  selectedFacilityHidePlayerPaymentMessage: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.hidePlayerPaymentMessage) ? state.selectedFacility.configurations.hidePlayerPaymentMessage : ''
  },
  selectedFacilityShowingOnLoadModalName: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.showingOnLoadModalName) ? state.selectedFacility.configurations.showingOnLoadModalName : ''
  },
  selectedFacilityShowOnLoadModal: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.showOnLoadModal) ? state.selectedFacility.configurations.showOnLoadModal === 'show' : false
  },
  selectedFacilityOnLoadModalMessage: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.onLoadModalMessage) ? state.selectedFacility.configurations.onLoadModalMessage : ''
  },
  selectedFacilityNoOpenSlotsMessage: state => {
    return (state.selectedFacility &&  state.selectedFacility.configurations.noOpenSlotsMessage) ? state.selectedFacility.configurations.noOpenSlotsMessage : '더 이상 예약 가능한 코트가 없습니다.'
  },
  getField,
}

const actions = {
  getManagedFacilities ({ commit, dispatch }) {
    // for manager
    dispatch('activateLoading', null, {root: true})
    return new Promise(resolve => {
      facilitiesApi.getManagedFacilities().then(resp => {
        commit('setManagedFacilities', camelcaseKeys(resp.facilities))
        commit('setSelectedManagedFacility', resp.facilities[0])
        commit('courts/setCourts', camelcaseKeys(resp.facilities[0].courts).sort((a,b)=> parseInt(a.id) - parseInt(b.id)), { root: true })
        dispatch('deactiveLoading', null, {root: true})
        resolve()
      })
    })
  },
  getFacilities ({ state, commit, dispatch }) {
    // for player
    dispatch('activateLoading', null, {root: true})
    return new Promise(resolve => {
      facilitiesApi.getFacilities().then(resp => {
        commit('setFacilities', camelcaseKeys(resp.facilities.sort((a, b)=> parseInt(a.id) - parseInt(b.id)), { deep: true}))
        let selectedFacilityId = localStorage.getItem('selectedFacilityId')
        let selectedFacility = state.facilities.find(facility => facility.id === parseInt(selectedFacilityId))
        if (selectedFacilityId && selectedFacility) {
          dispatch('selectFacility', selectedFacility)
        }
        dispatch('deactiveLoading', null, {root: true})
        resolve()
      })
    })
  },
  getFacilitiesData ({ dispatch, rootState }) {
    if (localStorage.getItem('role').includes('manager')) {
      dispatch('getManagedFacilities').then(() => {
        dispatch('prices/getPrices', null, { root: true })
        dispatch('bookings/getBookingsForManager', rootState['bookings/scheduleSelectedDay'], { root: true })
      })
    } else {
      dispatch('getFacilities')
    }
  },
  selectFacility ({ commit }, facility) {  // for player
    commit('setSelectedFacility', facility)
  }
}

const mutations = {
  setManagedFacilities (state, facilities) {
    state.managedFacilities = facilities
  },
  setSelectedManagedFacility (state, facility) {
    state.selectedManagedFacility = facility
  },
  setFacilities (state, facilities) {
    state.facilities = facilities
  },
  setSelectedFacility (state, facility) {
    state.selectedFacility = facility
  },
  updateField,
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
}
