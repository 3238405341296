<template>
  <button
    class='rounded mx-auto opacity-75 px-4 h-8 uppercase font-semibold text-xs z-10 fixed left-0 right-0 bg-white border-gray-100 shadow-md'
    style='top: 4.5rem;'>
    <slot />
  </button>
</template>

<script>

export default {
  name: 'MobileExpandButton',
}
</script>
