<template>
  <div>
    <h1 class='text-xl lg:text-2xl'>테니스플레이 온라인 예약 약관</h1>
    <p class='text-sm lg:text-base'>온라인 예약 서비스를 사용하기 전에 먼저 이 약관을 읽어보십시오.</p>
    <div class='whitespace-pre-line text-sm lg:text-base mt-12'>
      <h2 class='text-lg font-semibold'>1. 일반 조건</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>Tennis Play는 예약자에게 사전 통보 없이 온라인 예약 약관을 수정할 권리를 보유하고 있습니다. 수정된 약관이 Tennis Play 웹사이트에 게재되면 해당 약관은 효력을 갖게 됩니다.</li>
        <li>Tennis Play는 365일 24시간 운영하는 웹사이트 www.thetennisplay.com을 통해 예약권을 예약하고 구매할 수 있는 기능을 제공합니다. </li>
        <li>온라인으로 예약할 경우 예약자의 이름을 정확하게 기입하는 것은 예약자의 책임입니다. 이름을 잘못 기입한 경우, 예약자은 입장이 거부되며 재예약으로 인해 발생되는 모든 비용은 예약자의 책임입니다.</li>
        <li>www.thetennisplay.com에 온라인으로 예약권을 예약하고 구매함으로써 예약자는 예약권 예약과 관련하여 Tennis Play가 보내는 이메일을 수신하고 Tennis Play 파트너가 보내는 내용을 수신할 것에 동의하게 됩니다.</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>2. 요금 조건 적용</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>예약자은 예약권 예약 시 선택한 요금 및 예약권에 적용되는 온라인 요금 조건을 숙지하고 있어야 합니다. 요금 조건은 예약가능시간 선택 시 나타납니다. 예약 및 결제를 완료하면 4단계(예약자 세부 정보)에서 기입한 이메일 주소로 예약 확인 이메일이 발송됩니다.</li>
        <li>예약자는 결제 페이지로 넘어가기 전에, 요금 조건 체크 박스에 체크하여 해당 예약권의 요금 조건에 동의해야 합니다.</li>
        <li>Tennis Play는 요금 조건에 있어서 재예약, 취소, 노쇼(no show)에 대한 요금 한도를 변경할 권리를 보유합니다. 이러한 변경사항은 이미 구입한 예약권에는 적용되지 않습니다.</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>3. 예약</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>신청일 기준 7일 단위로 예약 가능합니다.</li>
        <li>예약은 온라인 예약만 가능 하고, 공정한 예약시스템의 운영을 위하여 어떠한 경우라도 전화 예약은 불가 합니다.</li>
        <ol class='list-decimal list-inside mt-4'>3-1. 예약 변경
          <li>예약을 변경할 경우, 예약자은 반드시 www.thetennisplay.com에서 예약 변경을 요청해야 합니다. 온라인 예약 시 기입한 이메일을 통해 예약 변경을 요청하는 것은 여정 세부사항에 대해 무단 변경을 예방하기 위한 필수적인 절차입니다. 예약 변경은 구입한 예약권의 요금 조건에 따라 진행됩니다. 예약권에 명시된 경우 예약 변경으로 인한 수수료가 적용될 수 있습니다.</li>
        </ol>
        <ol class='list-decimal list-inside mt-4'>3-2. 예약 취소/환불
          <li>결제 후 1시간 이내의 환불신청은 100% 환불 가능합니다.</li>
          <li>당일 예약시간 24시간 이전까지의 환불신청은 전체금액의 50% 환불 가능합니다.</li>
          <li>당일 예약시간 48시간 이전까지의 환불신청은 전체금액의 100% 환불 가능합니다.</li>
          <li>예약당일 사용하지 않은 경우나 이용시간이 지나간 시점에서의 환불은 불가 합니다.</li>
          <li>우천 등 천재지변으로 인한 환불은 가능합니다.</li>
        </ol>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>4. 양도</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>예약한 코트는 양도, 양수, 교환 금지이며 위반시 코트사용 불가입니다.(예약코트에 본인이 아닐경우 모두 퇴장됩니다)</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>5. 코트사용</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>직원 외 강습 행위를 절대 금지 합니다. 적발시 바로 퇴장 조치됩니다.</li>
        <li>취사 및 화기사용을 금지 및 음식물 반입, 취식 금지합니다.</li>
        <li>음주 및 흡연과 코트에 오물을 투척하는 행위를 금지 합니다.</li>
        <li>테니스 외의 행위를 금지 합니다.(인라인, 족구, 자전거, 퀵보드 애완동물 동반입장 등)</li>
        <li>타인이 사용중인 코트에 무단출입을 금지 합니다.</li>
        <li>사전 허가되지 않은 광고 및 상행위를 금지 합니다.(사진, 영상, 현수막 설치 금지)</li>
        <li>위의 1~6항까지 위반사항 및 기타 사전에 허가되지 않은 행위가 있을 경우, 즉시 사용이 금지되며, 일체의 환불이 불가합니다. 또한 코트 파손 시 변상조치 합니다.</li>
        <li>이용자 부주의로 인한 분실 및 각종 사고 등에 대해서는 테니스장에서 책임을 지지 않습니다.</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>6. 온라인 보안</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>보안상의 이유로 Tennis Play는 신용카드나 직불카드 번호, CVC/CVV 코드 및 유효기간을 저장하지 않습니다. 카카오페이, Visa, MasterCard 및 American Express 결제 시스템이 승인한 회사를 통해 모든 거래가 이루어집니다.</li>
        <li>Tennis Play는 정보 보안 및 민감한 정보에 대한 기밀 유지를 위해 필요한 모든 조치를 취하고 있습니다. 민감한 예약자 정보는 모두 인터넷 상에서 보안 처리되고 암호화 처리됩니다. '결제' 페이지로 이동 시 정보는 SSL(안전 소켓 층) 프로토콜로 암호화되어 전송됩니다.</li>
        <li>Tennis Play는 예약자의 개인정보를 보호하기 위해 개인정보정책을 운영하고 있습니다. 예약권 예약을 위해 예약자의 성과 이름, 연락처 정보와 같은 상세 정보를 기입해야 합니다. 이러한 정보는 예약자에게 예약 서비스를 제공할 목적으로 사용됩니다. 또한 해당 예약스케줄에 대한  변경이나 취소가 생길 경우 예약자에게 알려주기 위한 목적으로 사용됩니다. Tennis Play는 해당 당국이나 경찰이 공식적으로 요청하는 경우를 제외하고 어떠한 경우에도 예약자의 개인정보를 제삼자에게 제공하지 않습니다.</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>7. 온라인 결제</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>결제를 위해 예약자는 유효한 카카오페이를 사용할 수 있습니다.</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>7. 온라인 고객 지원</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>온라인 고객 지원 시 선호되는 커뮤니케이션 수단은 이메일 메시지 입니다. 이메일 요청은 최우선 순위로 처리됩니다. 그러나 Tennis Play는 Tennis Play의 통제 범위를 벗어난 스팸, 바이러스 퇴치 필터 및 시스템에 의한 이메일 차단을 포함하여 이메일 시스템이 부적절하게 작동하는 경우 이에 대한 책임을 지지 않습니다.</li>
      </ol>
      <h2 class='text-lg font-semibold mt-12'>8. 약관 수락</h2>
      <ol class='list-decimal list-inside mt-2'>
        <li>결제에서 약관 동의 체크 박스에 클릭하면 예약에 관한 모든 사항을  숙지하고 이에 동의하며 향후에 이에 대해 이의를 제기하지 않을 것에 동의하게 됩니다.</li>
      </ol>
      <div class='bg-gray-50 p-6 rounded-md mt-8'>[부칙] 1. 이 약관은 2021년 01월 01일부터 시행됩니다.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillingCancellationFullDetails',
}
</script>
