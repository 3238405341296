import Vue                       from 'vue'
import Vuex                      from 'vuex'
import RegistrationApi           from '@/api/v1/users/registrations'
import ssoSessionsApi            from '@/api/v1/users/api-sso-sessions.js'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  hasValidToken: false,
  signupParams: {
    email: '',
    password: '',
    passwordConfirmation: '',
    name: '',
    phoneNumber: '',
  },
  forgotPasswordParams: {
    email: '',
    password: '',
    passwordConfirmation: '',
    name: '',
    phoneNumber: '',
  }
})

const getters = {
  getField,
}

const actions = {
  // we can add checkTokenStatus later
  checkTokenStatus ({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ssoSessionsApi.checkTokenStatus().then(resp => {
        commit('setHasValidToken', true)
        resolve(camelcaseKeys(resp, { deep: true }))
      }).catch(error => {
        console.error(error)
        if (error.response.status === 401) {
          dispatch('clearLocalStorageData', null, {root: true})
          reject(error)
        }
      })
    })
  },
  emailSignup ({commit, dispatch, state}) {
    let signupParams = {
      provider: 'email',
      user_params: {
        email: state.signupParams.email,
        password: state.signupParams.password,
        name: state.signupParams.name,
        phone_number: state.signupParams.phoneNumber,
      },
    }
    return new Promise((resolve, reject) => {
      RegistrationApi.signup(signupParams).then(resp => {
        localStorage.setItem('auth._token.tennis_play', resp.data.access_token)
        localStorage.setItem('role', resp.data.role)
        localStorage.setItem('userId', resp.data.user_id)
        dispatch('setLocalStorageData', null, {root: true})
        commit('setHasValidToken', true)
        resolve(resp)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  updateLoginPassword ({ commit, state }) {
    let updateLoginPasswordParams = {
      email: state.forgotPasswordParams.email,
      password: state.forgotPasswordParams.password,
      name: state.forgotPasswordParams.name,
      phone_number: state.forgotPasswordParams.phoneNumber,
    }
    return new Promise((resolve, reject) => {
      ssoSessionsApi.patchLoginPassword(snakecaseKeys(updateLoginPasswordParams, { deep: true })).then(resp => {
        commit('setForgotPasswordParams', {
          email: '',
          password: '',
          passwordConfirmation: '',
          name: '',
          phoneNumber: '',
        })
        resolve(camelcaseKeys(resp.user, {deep: true}))
      }).catch(error => {
        reject(error)
        console.error(error)
      })
    })
  },
}

const mutations = {
  setHasValidToken (state, boolean) {
    state.hasValidToken = boolean
  },
  setForgotPasswordParams (state, params) {
    state.forgotPasswordParams = params
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
