<template>
  <div class='p-8 flex-grow text-left'>
    <div class='mt-6 flex flex-row justify-start'>
      <h1 class='mt-2  text-2xl font-semibold mr-12'><span class='font-base'>코트:</span> {{courtName}}</h1>
      <button class='block bg-gray-900 text-sm text-white py-3 px-8 rounded-md hover:shadow-lg'
        @click='saveChanges'>
        저장
      </button>
    </div>
    <div class='my-10'>
      <h3 class='mb-4 border-b pb-2'>종류</h3>
        <select class='w-1/3 border px-4 rounded'
                v-model='surfaceType'>
          <option value='인조잔디'>인조잔디</option>
          <option value='클레이'>클레이</option>
          <option value='하드'>하드</option>
          <option value='잔디'>잔디</option>
          <option value='카펫'>카펫</option>
        </select>
    </div>
    <div class='my-10'>
      <h3 class='mb-4 border-b pb-2'>조명유무</h3>
      <label class='mr-4 border px-4 py-3'>
        조명 있음
        <input class='ml-2' type='radio' v-model='hasLights' :value='true'>
      </label>
      <label class='mr-4 border px-4 py-3'>
        조명 없음
        <input class='ml-2' type='radio' v-model='hasLights' :value='false'>
      </label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'CourtSettings',
  computed: {
    ...mapFields('courts', [
      'editingCourt.surfaceType',
      'editingCourt.hasLights'
    ]),
    ...mapState('courts', [
      'editingCourt',
    ]),
    courtName () {
      return (this.editingCourt) ? this.editingCourt.courtName : ''
    },
  },
  methods: {
    ...mapActions('courts', [
      'updateCourt'
    ]),
    ...mapActions('facilities', [
      'getManagedFacilities'
    ]),
    saveChanges () {
      this.$confirm('변경된 사항을 저장하시겠습니까?', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        this.updateCourt({
          'court': {
            surface_type: this.surfaceType,
            has_lights: this.hasLights,
          }
        }).then(() => {
          this.getManagedFacilities()
        })
        this.$message({
          type: 'success',
          message: '변경사항이 저장되었습니다.'
        })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
button:disabled,
button[disabled] {
  @apply opacity-50 cursor-not-allowed;
}
</style>
