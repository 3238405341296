import Vue         from 'vue'
import Vuex        from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import bookingsApi             from '@/api/v1/bookings'
import newBookingRequestsApi   from '@/api/v1/new-booking-requests'
import BookingRequestHelpers   from '@/utils/booking-request-helpers'
import EventBus                from '@/utils/event-bus'

import isEmpty     from 'lodash/isEmpty'
import dayjs       from 'dayjs'
import cloneDeep   from 'lodash/cloneDeep'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')


const state = () => ({
  newBookingRequestsWithUserId: [],
  selectedNewBookingRequestCreatedDate: '',
  selectedNewBookingRequestPlayDate: '',
  selectedNewBookingRequestType: '',
  newRequestsCreatedDateRange: [
    dayjs().startOf('week').format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD')
  ],
  substitutionRequests: [],
  newBookingRequestSlotList: [],
  extensioningNewBookingRequest: {},
})

const getters = {
  newBookingRequestsWithUserId: state => {
    let newArray = []
    let tmpRequest = {}
    state.newBookingRequestsWithUserId.forEach(bookingRequest => {
      bookingRequest.metaData.dates.forEach(bookingDate => { 
        tmpRequest = cloneDeep(bookingRequest)
        tmpRequest.metaData['playDate'] = bookingDate
        newArray.push(tmpRequest)
      })
    })
    return newArray.sort((a, b) => new Date(b.metaData.playDate) - new Date(a.metaData.playDate))
  },
  hasNewBookingRequestsWithUserId: (state, getters) => {
    return getters.newBookingRequestsWithUserId.length > 0
  },
  hasNewBookingRequestSlotList: state => {
    return state.newBookingRequestSlotList.length > 0
  },
  newBookingRequestSlotListCount: state => {
    return state.newBookingRequestSlotList.length 
  },
  hasExtensioningNewBookingRequest: state => {
    return !isEmpty(state.extensioningNewBookingRequest)
  },
  extensioningNewBookingRequestSchedules: (state, getters, rootState, rootGetters) => {
    return getters.hasExtensioningNewBookingRequest && !isEmpty(state.extensioningNewBookingRequest.schedules) ? state.extensioningNewBookingRequest.schedules.map(schedule => {
      return {
        bookingDate: getters.extensioningNewBookingRequestStartDate,
        courtId:     schedule.courtId,
        startTime:   schedule.startTime,
        endTime:     schedule.endTime,
        price:       rootGetters['prices/priceForNewBookingRequest']({
          bookingDate: getters.extensioningNewBookingRequestStartDate,
          courtId:     schedule.courtId,
          startTime:   schedule.startTime,
          endTime:     schedule.endTime}),
      }
    }) : []
  },
  extensioningNewBookingRequestStartDate: (state, getters) => {
    return getters.hasExtensioningNewBookingRequest && !isEmpty(state.extensioningNewBookingRequest.schedules) ? dayjs(state.extensioningNewBookingRequest.schedules[0].bookingDate).add(parseInt(state.extensioningNewBookingRequest.repeatCount), 'week').locale('kr').format('YYYY-MM-DD') : ''
  },
  getField,
}

const actions = {
  getSpecificBookingById ({ dispatch }, bookingId) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      bookingsApi.getSpecificBooking(bookingId).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(camelcaseKeys(resp.booking))
      })
    })
  },
  getManagerNewBookingRequests ({ state, commit, dispatch }) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      newBookingRequestsApi.getManagerNewBookingRequests(state.selectedNewBookingRequestType, state.selectedNewBookingRequestCreatedDate, state.selectedNewBookingRequestPlayDate).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        commit('setNewBookingRequestWithUserId', camelcaseKeys(resp.new_booking_requests, {deep: true}))
        resolve()
      })
    })
  },
  getManagerNewBookingRequestsForBilling ({ state, commit, dispatch }) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      newBookingRequestsApi.getManagerNewBookingRequestsForBilling(state.newRequestsCreatedDateRange[0], state.newRequestsCreatedDateRange[1]).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        commit('setNewBookingRequestWithUserId', camelcaseKeys(resp.new_booking_requests, {deep: true}))
        resolve()
      })
    })
  },
  getSingleNewBookingRequestForExtension ({ dispatch, commit }, newBookingRequestId) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      newBookingRequestsApi.getSingleNewBookingRequest(newBookingRequestId).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        commit('setSelectedNewBookingRequest', camelcaseKeys(resp.new_booking_request, {deep: true}))
        resolve()
      })
    })
  },
  postNewBookingRequestSlots ({ dispatch }, submitData) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise((resolve, reject) => {
      newBookingRequestsApi.createAndApproveNewBookingRequest(submitData).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(resp)
      }).catch((error) => {
        dispatch('stopFullPageLoading', null, {root: true})
        reject(error)
      })
    })
  },
  postNewBlockRequestSlots ({ dispatch }, submitData) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise((resolve, reject) => {
      newBookingRequestsApi.createBlockedBookings(submitData).then(resp => {
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(resp)
      }).catch((error) => {
        dispatch('stopFullPageLoading', null, {root: true})
        reject(error)
      })
    })
  },
  selectNewBookingRequestSlot ({ commit }, newBookingRequestSlot) {
    commit('setNewBookingRequestSlotList', newBookingRequestSlot)
  },
}

const mutations = {
  setSubstitutionRequests (state, requests) {
    state.substitutionRequests = requests
  },
  setRequestInList (state, updatedRequest) {
    let index = state.substitutionRequests.findIndex(request => request.id === updatedRequest.id)
    if (index !== -1) {
      state.substitutionRequests.splice(index, 1, updatedRequest)
    }
  },
  setNewBookingRequestSlotList (state, newBookingRequestSlot) {
    let index = state.newBookingRequestSlotList.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, newBookingRequestSlot))
    if (index === -1) {
      state.newBookingRequestSlotList.push(newBookingRequestSlot)
    } else {
      EventBus.$emit('remove-repeat-check', newBookingRequestSlot)
      state.newBookingRequestSlotList.splice(index, 1)
    }
  },
  removeNewBookingRequestSlotList (state, newBookingRequestSlot) {
    let index = state.newBookingRequestSlotList.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, newBookingRequestSlot))
    if (index !== -1) {
      state.newBookingRequestSlotList.splice(index, 1)
    }
  },
  resetNewBookingRequestSlotList (state) {
    state.newBookingRequestSlotList = []
  },
  setSelectedNewBookingRequest (state, newBookingRequest) {
    state.extensioningNewBookingRequest = newBookingRequest
  },
  setNewBookingRequestWithUserId (state, newBookingRequestsWithUserId) {
    state.newBookingRequestsWithUserId = newBookingRequestsWithUserId
  },
  updateCancellednewBookingRequestsWithUserId (state, cancelledBookingRequest) {
    let index = state.newBookingRequestsWithUserId.findIndex(bookingRequest => bookingRequest.id === cancelledBookingRequest.id)
    if (index !== -1) {
      state.newBookingRequestsWithUserId.splice(index, 1, cancelledBookingRequest)
    }
  },
  updateField,
}




export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
