<template>
  <div>
    <modal
      name='manager-new-booking-request-cancellation-modal'
      :adaptive='true'
      :clickToClose='false'
      classes='relative p-12 lg:p-16'
      height='760px'
      width='400px'>
      <button class='z-30 absolute top-3 right-3' @click='closeManagerNewBookingRequestCancellationModal'>
        <x-icon class='text-black' />
      </button>
      <h2 class='text-gray-500'>고객명</h2>
      <div class='text-lg font-semibold mt-1 w-40'>{{ customerNameForBookingRequest }}</div>
      <h2 class='text-gray-500 mt-4'>코트 사용 날짜</h2>
      <div class='text-lg font-semibold mt-1 w-40'>{{ monthAndDayForBookingRequest }} ({{dayOfWeeForBookingRequest}})</div>
      <h2 class='text-gray-500 mt-4'>코트 예약 내용</h2>
      <div v-for='(arrayOfSlots, key) in bookingsByCourt'
        :key='`${bookingRequest.id}-bookingSlot-${key}`'
        class='flex flex-row justify-start items-start mt-1 font-semibold'>
        <label class='w-16'><span class=''>코트</span> {{key}}</label>
          <div class='flex-grow'>
          <div v-for='(slot, key) in sortedAndCombinedSlots(arrayOfSlots)'
            :key='`${bookingRequest.id}-bookingSlot-${key}-slot-for-court-${slot.id}`'
            class='flex flex-row items-center justify-between'>
            <span class='inline-block'>{{slot.startTime}} - {{slot.endTime}}시 {{ timeLengthString(slot) }}</span>
            <span class='inline-block ml-1'>{{priceString(slot.price)}}원</span>
          </div>
        </div>
      </div>
      <div v-if='feeAmount > 0'
        class='flex flex-row justify-between items-start text-sm font-semibold mb-2'>
        <label class='block'>온라인예약 수수료 (부가세포함)</label>
        <div>
          {{priceString(feeAmount)}}원
        </div>
      </div>
      <h2 class='text-gray-500 mt-4'>총 예약 금액</h2>
      <div class='text-lg font-semibold mt-1 w-40'>{{ priceString(totalPriceForBookingRequest) }}원</div>
      <h2 class='text-gray-500 mt-4'>환불 금액</h2>
      <input v-model='refundAmount'
        type='number'
        class='mt-1 border p-1 rounded border-gray-300 w-40 text-lg font-semibold' /> 원
      <h2 class='text-gray-500 mt-4'>환불 사유</h2>
      <select class='block mt-1 border p-1 rounded border-gray-300 w-40 text-lg font-semibold'
              v-model='selectedReasonForCancellation'
              @change='updateReasonForCancellationSelect'>
        <option disabled></option>
        <option value='우천'>우천</option>
        <option value='other'>직접입력</option>
      </select>
      <input
        v-if='notListedReasonForCancellation'
        v-model='reasonForCancellation'
        class='mt-1 border p-1 rounded border-gray-300 w-full text-lg font-semibold'
        type='text'
        placeholder='환불 사유'>
      <button
        class='mt-8 block text-white py-2 w-full rounded-md hover:shadow-lg'
        :disabled='disabledNewBookingRequestCancellationButton'
        :class='newBookingRequestCancellationButtonClass'
        @click='cancelNewBookingRequest(bookingRequest.id)'>취소하기</button>
        <div class='flex flex-row justify-between mt-4'>
          <ul v-if='disabledNewBookingRequestCancellationButton'
            class='list-disc list-inside text-red-600 text-sm text-left'>
            <li v-if='emptyRefundAmount'>환불 금액을 입력해주세요.</li>
            <li v-if='emptyReasonForCancellation'>환불 사유를 입력해주세요.</li>
            <li v-if='refundAmountOverPriceForBookingRequest'>환불 금액은 예약 금액보다 클 수 없습니다.</li>
          </ul>
        </div>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { XIcon }      from '@vue-hero-icons/outline'
import numbro         from 'numbro'
import StringHelpers          from '@/utils/string-helpers'
import BookingRequestHelpers  from '@/utils/booking-request-helpers'

export default {
  name: 'ManagerNewRequestsRowCancellation',
  components: {
    XIcon,
  },
  props: [
    'bookingRequest',
  ],
  data () {
    return {
      refundAmount: 0,
      selectedReasonForCancellation: '',
      reasonForCancellation: '',
    }
  },
  computed: {
    totalPriceForBookingRequest () {
      let totalPrice = parseInt(this.bookingRequest.bookingsTotalPrice) / parseInt(this.bookingRequest.repeatCount) + parseInt(this.feeAmount)
      return totalPrice
    },
    customerNameForBookingRequest () {
      return this.bookingRequest ? this.bookingRequest.name : ''
    },
    monthAndDayForBookingRequest () {
      return this.bookingRequest && this.bookingRequest.metaData.playDate ? StringHelpers.monthDayWithKorean(this.bookingRequest.metaData.playDate) : ''
    },
    dayOfWeeForBookingRequest () {
      return this.bookingRequest && this.bookingRequest.metaData.playDate ? StringHelpers.dayOfWeekKorean(this.bookingRequest.metaData.playDate) : ''
    },
    bookingsByCourt () {
      return this.bookingRequest ? BookingRequestHelpers.requestBookingsByCourt(this.bookingRequest.schedules) : []
    },
    emptyRefundAmount () {
      return !this.refundAmount
    },
    emptyReasonForCancellation () {
      return !this.reasonForCancellation
    },
    refundAmountOverPriceForBookingRequest () {
      return this.bookingRequest && this.totalPriceForBookingRequest < this.refundAmount
    },
    notListedReasonForCancellation () {
      return this.selectedReasonForCancellation.toLowerCase() === 'other'
    },
    disabledNewBookingRequestCancellationButton () {
      return this.emptyReasonForCancellation || this.emptyRefundAmount || this.refundAmountOverPriceForBookingRequest
    },
    newBookingRequestCancellationButtonClass () {
      return (!this.disabledNewBookingRequestCancellationButton) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    feeAmount () {
      return this.bookingRequest.feeAmount ? parseInt(this.bookingRequest.feeAmount) : 0
    },
  },
  methods: {
    ...mapActions('paymentsKakao', [
      'requestKakaoPaymentCancel'
    ]),
    ...mapActions('paymentsToss', [
      'requestTossPaymentCancel'
    ]),
    sortedAndCombinedSlots (slots) {
      return BookingRequestHelpers.combinedSlots(slots)
    },
    timeLengthString (slot) {
      let length = parseInt(slot.endTime) - parseInt(slot.startTime)
      return (length > 1) ? `(${length}시간)` : ''
    },
    priceString (price) {
      return numbro(price).format({trimMantissa: true, thousandSeparated: true})
    },
    cancelKakaoPayment (newBookingRequestId) {
      let text = `${this.refundAmount}원 환불 및 예약 취소를 요청하겠습니까?`
      this.$confirm(text, {
        confirmButtonText: '취소 요청',
        cancelButtonText: '아니요',
        type: 'warning'
      }).then(() => {
        this.requestKakaoPaymentCancel({ orderId: newBookingRequestId,
                                         cancelParams: { refund_amount: this.refundAmount, reason_for_cancellation: this.reasonForCancellation }}).then(() => {
          this.closeManagerNewBookingRequestCancellationModal()                                 
          this.$alert('예약이 취소되었습니다.', {
            type: 'success'
          })
        }).catch((error) => {
          console.error(error)
          this.$alert('예약취소가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch(() => {})
    },
    cancelTossPayment (newBookingRequestId) {
      let text = `${this.refundAmount}원 환불 및 예약 취소를 요청하겠습니까?`
      this.$confirm(text, {
        confirmButtonText: '취소 요청',
        cancelButtonText: '아니요',
        type: 'warning'
      }).then(() => {
        this.requestTossPaymentCancel({ orderId: newBookingRequestId,
                                        cancelParams: { refund_amount: this.refundAmount, reason_for_cancellation: this.reasonForCancellation }}).then(() => {
          this.closeManagerNewBookingRequestCancellationModal()                                
          this.$alert('예약이 취소되었습니다.', {
            type: 'success'
          })
        }).catch((error) => {
          console.error(error)
          this.$alert('예약취소가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch(() => {})
    },
    cancelNewBookingRequest (newBookingRequestId) {
      if (this.bookingRequest.paidWith === 'kakao_payment') {
        this.cancelKakaoPayment(newBookingRequestId)
      } else if (this.bookingRequest.paidWith === 'toss_payment') {
        this.cancelTossPayment(newBookingRequestId)
      } else {
        console.error('cancel new booking request error')
      }
    },
    closeManagerNewBookingRequestCancellationModal () {
      this.$emit('close-manager-new-booking-request-cancellation-modal')
      this.refundAmount = 0
      this.selectedReasonForCancellation = ''
      this.reasonForCancellation = ''
    },
    updateReasonForCancellationSelect () {
      if (this.selectedReasonForCancellation.toLowerCase() !== 'other') {
        this.reasonForCancellation = this.selectedReasonForCancellation
      } else {
        this.reasonForCancellation = ''
      }
    },
  },
}
</script>
