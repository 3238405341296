<template>
  <div class='green-gradient-bg text-left px-4 md:px-8 py-6 md:py-8'>
    <div class='mb-8 lg:mb-12'>
      <h1 class='uppercase font-bold text-2xl text-gray-800'>
        {{myProfile.name}}님<span class='font-normal text-gray-600'>의 예약</span>
      </h1>
    </div>
    <div class='flex flex-row justify-start items-center gap-x-4 border-b'>
      <h4 
        class='px-4 py-2 text-base border-b-2 border-solid hover:bg-gray-100 cursor-pointer'
        :class='highlightedTab("upcoming")'
        @click='setBookingRequestsTab("upcoming")'>다가오는 예약 ({{upcomingBookingRequests.length}})</h4>
      <h4 
        class='px-4 py-2 text-base border-b-2 border-solid hover:bg-gray-100 cursor-pointer'
        :class='highlightedTab("past")'
        @click='setBookingRequestsTab("past")'>지나간 예약 ({{pastBookingRequests.length}})</h4>
    </div>
    <div v-if='showUpcomingBookingRequests'>
      <player-upcoming-booking-request
        v-for='bookingRequest in upcomingBookingRequests'
        :key='`upcoming-booking-request-${bookingRequest.id}`'
        :booking-request='bookingRequest'
        date-category='upcoming'
        class='mt-6' />
      <div v-if='isUpcomingBookingsEmpty'
        class='bg-gray-100 rounded text-center p-10 md:p-16 max-w-lg my-4 md:my-6'>
        다가오는 예약이 없습니다.<br>
        <router-link
          :to='{ name: "PlayerScheduleBookings" }'
          class='inline-block bg-gray-100 hover:bg-gray-200 px-1 underline hover:no-underline rounded'>
          예약하기 메뉴에서
        </router-link> 예약을 진행해주세요.
      </div>
    </div>
    <div v-if='showPastBookingRequests'>
      <player-past-booking-request
        v-for='bookingRequest in pastBookingRequests'
        :key='`past-booking-request-${bookingRequest.id}-${bookingRequest.metaData.playDate}`'
        date-category='past'
        :booking-request='bookingRequest'
        class='mt-6' />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters }   from 'vuex'
import PlayerPastBookingRequest     from '@/views/player-bookings/PlayerPastBookingRequest'
import PlayerUpcomingBookingRequest from '@/views/player-bookings/PlayerUpcomingBookingRequest'


export default {
  name: 'PlayerReservations',
  components: {
    PlayerPastBookingRequest,
    PlayerUpcomingBookingRequest,
  },
  data () {
    return {
      bookingRequestsTab: 'upcoming'
    }
  },
  watch: {
    hasMyProfile: {
      handler: function (newVal) {
        if (newVal) {
          this.getMyNewBookingRequests()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('playerBookingRequests', [
      'pastBookingRequests',
      'upcomingBookingRequests'
    ]),
    ...mapGetters('playerProfile', [
      'myProfile',
      'hasMyProfile'
    ]),
    pastCount () {
      return this.pastBookingRequests.length
    },
    isUpcomingBookingsEmpty () {
      return this.upcomingCount === 0
    },
    upcomingCount () {
      return this.upcomingBookingRequests.length
    },
    showingPlayerName () {
      return this.hasMyProfile ? this.myProfile.name : ''
    },
    showUpcomingBookingRequests () {
      return this.bookingRequestsTab === 'upcoming'
    },
    showPastBookingRequests () {
      return this.bookingRequestsTab === 'past'
    },
  },
  methods: {
    ...mapActions('playerBookingRequests', [
      'getMyNewBookingRequests',
    ]),
    highlightedTab (tab) {
      return (this.bookingRequestsTab === tab) ? 'font-semibold border-blue-400' : 'border-transparent'
    },
    setBookingRequestsTab (tab) {
      this.bookingRequestsTab = tab
    },
  },
}
</script>

<style lang='scss' scoped>
  .green-gradient-bg {
    background: #BBD2C5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, rgb(187, 210, 197, 0.1), rgb(83, 105, 118, 0.05));  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, rgb(187, 210, 197, 0.05), rgb(83, 105, 118, 0.05)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 100vh;
  }
</style>
