<template>
  <div class=' overflow-y-auto'>
    <div class='mt-6 border bg-gray-100 rounded-md p-4 relative' style='background-color: #f7f7f7;'>
      <div class='absolute' style='top: 1rem; right: 1rem;'>
        <el-popover
          placement='bottom'
          width='200px'
          v-model='deleteBlockPopover'>
          <p>block을 삭제할까요?</p>
          <div class='text-center break-words mt-4'>
            <button 
              @click='submitRemoveBooking'
              class='inline-block mr-2 border rounded py-1 px-2'>
              네, 삭제
            </button>
            <button 
              @click='hidePopover'
              class='inline-block mr-2 border rounded py-1 px-2'>
              아니요
            </button>
          </div>
          <button 
            slot='reference'
            class='p-2 border rounded flex flex-row justify-center items-center text-gray-700 border-gray-200 opacity-75 hover:border-blue-400 uppercase text-sm hover:shadow-md hover:opacity-100 hover:bg-white'>
            <x-octagon-icon size='1x' class='mr-1' />
            <div>open</div>
          </button>
        </el-popover>
      </div>
      <h2 class='mb-6 mt-2 uppercase'>Block Booking</h2>
      <div class='mb-6'>
        <label for='phoneNumber' class='block text-sm font-medium text-gray-500'>코트</label>
        <div class='font-medium text-gray-900'>{{courtNameFromId(drilldownBooking.courtId)}}</div>
      </div>
      <div class='mb-6'>
        <label for='phoneNumber' class='block text-sm font-medium text-gray-500'>날짜</label>
        <div class='font-medium text-gray-900'>{{bookingDateString}}</div>
      </div>
      <div class='mb-6'>
        <label for='phoneNumber' class='block text-sm font-medium text-gray-500'>시간</label>
        <div class='text-gray-900'>
          {{drilldownBooking.startTime}} - {{drilldownBooking.endTime}}시 ({{drilldownBooking.length}}시간)
        </div>
      </div>
      <div class='mb-6'>
        <label for='note' class='block text-sm font-medium text-gray-500'>노트</label>
        <div class='font-regular text-gray-700'>{{drilldownBooking.note}}</div>
      </div>
    </div>
 
  </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { XOctagonIcon } from 'vue-feather-icons'
import StringHelpers    from '@/utils/string-helpers'

export default {
  name: 'BlockBooking',
  components: {
    XOctagonIcon,
  },
  data () {
    return {
      deleteBlockPopover: false,
    }
  },
  computed: {
    ...mapState('bookings', [
      'drilldownBooking'
    ]),
    ...mapGetters('courts', [
      'courtNameFromId'
    ]),
    bookingDateString () {
      return StringHelpers.shortDateString(this.drilldownBooking.bookingDate)
    },
    title () {
      return `코트 ${this.courtNameFromId(this.drilldownBooking.courtId)}: ${StringHelpers.shortDateString(this.drilldownBooking.bookingDate)} ${this.drilldownBooking.startTime}시`
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('bookings', [
      'cancelBooking'
    ]),
    submitRemoveBooking () {
      this.$confirm('This will permanently delete the blcok. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        confirmButtonClass: 'overrideConfirmDeleteButton',
        cancelButtonText: 'Keep',
        cancelButtonClass: 'overrideCancelButton',
        type: 'warning'
      }).then(() => {
        this.cancelBooking(this.drilldownBooking).then(() => {
          // close sidepanel
          this.sidepanelClose()
          this.$message({
            type: 'success',
            message: 'Delete completed'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled'
        })
      })
    },
    hidePopover () {
      this.deleteBlockPopover = false
    }
  },
}
</script>
