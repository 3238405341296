<template>
  <div class='relative max-w-5xl p-8 text-left min-h-screen'>
    <h1 class='font-bold text-2xl mt-4'>
      Report
    </h1>
    <div class='flex flex-row justify-between items-end mt-16'>
      <div class='w-2/5'>
        <h3 class='font-light text-lg text-gray-600 flex flex-row items-center gap-x-0.5'>
          <span class='font-medium'>{{reportDateTitleString}}</span>
          매출
        </h3>
        <h1 class='font-bold text-3xl'>
          {{revenueString}} 
          <span class='font-light'>{{revenueUnit}}</span>
        </h1>
      </div>
      <div class='w-3/5'>
        <bar-chart-comparison 
          :datum='dayData' 
          :dates='dates'
          height='3rem' />
      </div>
    </div>
    <div class='flex flex-col lg:flex-row justify-between items-start lg:items-center mt-24'>
      <div class='w-2/5'>
        <h3 class='font-light text-lg text-gray-600 flex flex-row items-center gap-x-0.5'>
          코트 예약 비중
        </h3>
        <h1 class='font-bold text-3xl'>
          {{hourBookingString}} 
          <span class='font-light'>%</span>
        </h1>
      </div>
      <div class='w-full lg:w-3/5 flex flex-col lg:flex-row items-start lg:items-center'>
        <div>
          <pie-chart-comparison 
            :datum='pieBreakdownData'
            :colors='pieChartColors' />
        </div>
        <div class='flex-grow w-full lg:w-3/5'>
          <table class='text-gray-600 w-full text-sm lg:text-base'>
            <tr>
              <td class='pb-0.5 px-2'><div class='h-4 w-4 rounded' style='background-color: #93C5FD;'></div></td>
              <td class='pb-0.5 pr-2'>1회예약</td>
              <td class='pb-0.5 pl-2 lg:pl-4 text-right'>2,878 시 </td>
              <td class='pb-0.5 pl-2 text-right text-gray-400'>57.1 %</td>
              <td class='pb-0.5 pl-2 lg:pl-8 text-right'><span class='opacity-50'>(매출)</span> 15.2 억</td>
            </tr>
            <tr>
              <td class='pb-0.5 px-2'><div class='h-4 w-4 rounded' style='background-color: #1a84bd;'></div></td>
              <td class='pb-0.5 pr-2'>연대관</td>
              <td class='pb-0.5 pl-4 text-right'>632 시</td>
              <td class='pb-0.5 pl-2 text-right text-gray-400'>10.9 %</td>
              <td class='pb-0.5 pl-8 text-right'><span class='opacity-50'>(매출)</span> 4.8 억</td>
            </tr>
            <tr>
              <td class='pb-0.5 px-2'><div class='h-4 w-4 rounded' style='background-color: #999999;'></div></td>
              <td class='pb-0.5 pr-2'> 예약없음 </td>
              <td class='pb-0.5 pl-4 text-right'>1,530 시</td>
              <td class='pb-0.5 pl-2 text-right text-gray-400'>32.0 %</td>
              <td class='pb-0.5 pl-8 text-right'>0</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class='flex flex-col lg:flex-row justify-between items-start mt-24'>
      <div class='w-2/5'>
        <h3 class='font-light text-lg text-gray-600 flex flex-row items-center gap-x-0.5'>
          <span class='font-medium'>{{reportDateTitleString}}</span>
          인기시간
        </h3>
        <h1 class='font-bold text-3xl hidden'>
          {{hourBookingString}} 
          <span class='font-light'>%</span>
        </h1>
      </div>
      <div class='w-full lg:w-3/5' style='height:600px;'>
        <heat-map-chart 
          :datum='heatMapData' 
          :days='daysForHeatmap' 
          :hours='hoursData'
          :max-value='maxValue'
          :min-hour='minHour' />
      </div>
    </div>

    
  </div>
</template>

<script>
// import dayjs                    from 'dayjs'
// import { ArrowNarrowRightIcon, BanIcon, CheckIcon } from '@vue-hero-icons/outline'
// import StringHelpers from '@/utils/string-helpers'
import { mapState } from 'vuex'
import BarChartComparison from '@/components/charts/BarChartComparison'
import PieChartComparison from '@/components/charts/PieChartComparison'
import HeatMapChart       from '@/components/charts/HeatMapChart'


export default {
  name: 'ManageReport',
  components: {
    BarChartComparison,
    PieChartComparison,
    HeatMapChart
  },
  data () {
    return {
      pieChartColors: ['#999999', '#1a84bd', '#93C5FD'],
      formatter: '{b}\n{c}',
      minHour: 8,
      maxValue: 20,
      hoursData: [
        "8시",
        "9시",
        "10시",
        "11시",
        "12시",
        "13시",
        "14시",
        "15시",
        "16시",
        "17시",
        "18시",
        "19시",
        "20시"
      ],
      daysForHeatmap: [
        "일",
        "월",
        "화",
        "수",
        "목",
        "금",
        "토"
      ],
    }
  },
  computed: {
    ...mapState('manageReports', [
      'dayData',
      'dates',
      'heatMapData',
    ]),
    reportDateTitleString () {
      return `9월`
    },
    revenueString () {
      return `20.2`
    },
    revenueUnit () {
      return `억`
    },
    hourBookingString () {
      return '68.0'
    },
    pieBreakdownData () {
      return [{name: '예약없음',     value:1530},
              {name: '연대관',  value: 632},
              {name: '1회예약', value: 2878}]
    },
  },
  methods: {

  },
  mounted () {
  }
}
</script>

<style lang='scss' scoped>


</style>
