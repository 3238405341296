<template>
  <div class='bg-white'>
    <div class='p-8 pb-16 text-left settings-nav w-full lg:w-96 fixed lg:static z-40 lg:z-0 bg-white'
      :class='settingsNavclass'>
      <h1 class='text-2xl font-bold text-gray-800 mt-2 mb-8'>설정</h1>
      <div>
        <label class='block my-2 text-gray-600'>가격설정</label>
        <div v-for='(pricesByCourt, index) in pricesByCourts'
             :key='`pricesByCourt-${index}`'
          class='py-2 px-4 border border-gray-200 mb-2 rounded cursor-pointer flex flex-row justify-between hover:bg-gray-100'
          :class='selectedStyle(pricesByCourt.courtIds)'
          @click='clickEditPrice(pricesByCourt)'>
          <div>코트: {{editPriceCourtListString(pricesByCourt.courtIds)}}</div>
        </div>
      </div>
      <div class='mb-8'>
        <label class='block my-2 text-gray-600'>일반설정</label>
        <div v-for='court in courts' :key='`courtlist-${court.id}`'
          class='py-2 px-4 border border-gray-200 mb-2 rounded cursor-pointer flex flex-row justify-between hover:bg-gray-100'
          :class='selectedStyle(court.id)'
          @click='clickEditCourt(court)'>
          <div class='w-2/3'>코트: {{court.courtName}}</div>
          <light-bulb-icon class='inline-block w-1/6'
                           :class='hasLigthsStyle(court.hasLights)'/>
          <div class='text-xs text-right w-1/6'>{{court.surfaceType}}</div>
        </div>
      </div>
    </div>
    <mobile-expand-button
      class='inline-block lg:hidden'
      @click.native='hideContentOnMobile'>
      {{currentlySelectedSetting}} <span class='inline-block ml-2 text-gray-400'>(다른 설정 선택)</span>
    </mobile-expand-button>
  </div>
</template>

<script>
import { LightBulbIcon } from "@vue-hero-icons/outline"
import MobileExpandButton from '@/components/MobileExpandButton'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'SettingsOverall',
  components: {
    MobileExpandButton,
    LightBulbIcon
  },
  watch: {
    'hasInitialSelectedCourt': {
      handler: function (newVal) {
        if (newVal) {
          this.clickEditPrice(this.pricesByCourts[0])
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState([
      'showContentOnMobile'
    ]),
    ...mapState('courts', [
      'courts',
      'editingCourt',
    ]),
    ...mapGetters('courts', [
      'courtNameFromId',
    ]),
    ...mapState('prices', [
      'pricesByCourts',
      'editingPrice',
    ]),
    ...mapGetters('manageSettings', [
      'isEditingSettingCourt',
      'isEditingSettingPrice'
    ]),
    currentlySelectedSetting () {
      if (this.isEditingSettingCourt) {
        return `일반: 코트 ${this.editingCourt.courtName}`
      } else if (this.isEditingSettingPrice) {
        return `가격: 코트 ${this.editingPrice.courtIds.map((courtId) => this.courtNameFromId(courtId)).join(', ')}`
      } else {
        return ''
      }
    },
    settingsNavclass () {
      return (this.showContentOnMobile) ? 'hidden' : 'block'
    },
    hasInitialSelectedCourt () {
      return this.pricesByCourts.length
    }
  },
  methods: {
    ...mapActions([
      'hideContentOnMobile',
      'displayContentOnMobile',
    ]),
    ...mapActions('courts', [
      'selectCourtToEdit'
    ]),
    ...mapActions('prices', [
      'selectPriceToEdit'
    ]),
    ...mapActions('manageSettings', [
      'selectSettingToEdit'
    ]),
    clickEditCourt (court) {
      this.selectCourtToEdit(court)
      this.selectSettingToEdit('court')
      this.displayContentOnMobile()
    },
    clickEditPrice (price) {
      this.selectPriceToEdit(price)
      this.selectSettingToEdit('price')
      this.displayContentOnMobile()
    },
    selectedStyle (selectedId) {
      if (this.isEditingSettingCourt) {
        return (this.editingCourt.id === selectedId) ? 'font-semibold border-blue-800 shadow-md' : ''
      } else if (this.isEditingSettingPrice) {
        return (JSON.stringify(this.editingPrice.courtIds) == JSON.stringify(selectedId)) ? 'font-semibold border-blue-800 shadow-md' : ''
      }
    },
    hasLigthsStyle (hasLights) {
      return (hasLights) ? 'text-yellow-500' : 'hidden'
    },
    editPriceCourtListString (courtList) {
      return courtList.map((courtId) => this.courtNameFromId(courtId)).join(', ')
    }
  },
}
</script>

<style lang='scss' scoped>
.settings-nav {
  height: 100%;
}
@media (min-width: 1024px) {
  .settings-nav {
    height: 100%;
    display: block;
  }
}
</style>

