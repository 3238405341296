<template>
    <!-- need to style this size with javascript -->
  <v-chart :options="chartOptions" style='width:100%;height:100%;'/>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/pie'

export default {
  name: 'PieChartComparison',
  props: ['datum', 'colors', 'formatter'],
  components: {
    'v-chart': ECharts
  },
  computed: {
    chartOptions () {
      return {
        series: [
          {
            type: 'pie',
            radius: ['55%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
              formatter: this.formatter
            },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '12',
                  fontWeight: 'bold'
                }
            },
            labelLine: {
              show: false
            },
            data: this.datum,
            color: this.colors
          }
        ]        
      }
    }
  }
}
</script>

<style lang='scss' scoped>

@media (min-width: 640px) {

}
</style>
