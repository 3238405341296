import { render, staticRenderFns } from "./ManageNewRequests.vue?vue&type=template&id=4c541a47&scoped=true&"
import script from "./ManageNewRequests.vue?vue&type=script&lang=js&"
export * from "./ManageNewRequests.vue?vue&type=script&lang=js&"
import style0 from "./ManageNewRequests.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ManageNewRequests.vue?vue&type=style&index=1&id=4c541a47&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c541a47",
  null
  
)

export default component.exports