import Vue       from 'vue'
import Vuex      from 'vuex'
import kakaoPaymentsApi from '@/api/v1/kakao-payments'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  nextRedirectMobileUrl: '',
  nextRedirectPcUrl: ''
})

const getters = {
}

const actions = {
  requestKakaoPaymentReady({commit}, orderId) {
    return new Promise((resolve, reject) => {
      kakaoPaymentsApi.kakaoPaymentReady(orderId).then((resp) => {
        commit('setNextRedirectMobileUrl', resp.kakao_payment_resp.next_redirect_mobile_url)
        commit('setNextRedirectPcUrl', resp.kakao_payment_resp.next_redirect_pc_url)
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  requestKakaoPaymentSuccess ({ commit }, orderParams ) {
    return new Promise((resolve, reject) => {
      kakaoPaymentsApi.kakaoPaymentSuccess(orderParams.order_id, {pg_token: orderParams.pg_token}).then(resp => {
        commit('payments/setCurrentOrder', camelcaseKeys(resp.new_booking_request, {deep: true}), { root: true })
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  requestKakaoPaymentCancel ({ commit, rootGetters }, {orderId, cancelParams} ) {
    return new Promise((resolve, reject) => {
      kakaoPaymentsApi.kakaoPaymentCancel(orderId, cancelParams).then(resp => {
        if (rootGetters['isCourtManager']) {
          commit('manageRequests/updateCancellednewBookingRequestsWithUserId', camelcaseKeys(resp.new_booking_request, {deep: true}), {root: true})
        } else if (rootGetters['isRegisteredPlayer']) {
          commit('playerBookingRequests/updateCancelledMyBookingRequests', camelcaseKeys(resp.new_booking_request, {deep: true}), {root: true})
        }
        resolve()
      }).catch((error) => {
        reject(error)
      })
    })
  },
  KakaoPaymentStatusCheck ({ commit }, orderId ) {
    return new Promise(resolve => {
      kakaoPaymentsApi.KakaoPaymentStatusCheck(orderId).then(resp => {
        commit('payments/setCurrentOrder', camelcaseKeys(resp.new_booking_request, {deep: true}), { root: true })
        resolve()
      })
    })
  },
}

const mutations = {
  setNextRedirectMobileUrl (state, nextRedirectMobileUrl) {
    state.nextRedirectMobileUrl = nextRedirectMobileUrl
  },
  setNextRedirectPcUrl (state, nextRedirectPcUrl) {
    state.nextRedirectPcUrl = nextRedirectPcUrl
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
