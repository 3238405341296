import base from './base'

const createFacilityProfile = (facilityProfileParams) => base
  .post('/facility_profiles/', facilityProfileParams).then(resp => resp.data)

const updateFacilityProfile = (facilityProfileId, facilityProfileParams) => base
  .patch(`/facility_profiles/${facilityProfileId}`, facilityProfileParams).then(resp => resp.data)

export default {
  createFacilityProfile: createFacilityProfile,
  updateFacilityProfile: updateFacilityProfile
}
