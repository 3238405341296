<template>
  <div class='fixed z-30 h-screen v-screen'>
    <div class='fixed z-40 bg-gray-600 opacity-50 top-0 left-0 right-0 bottom-0'></div>
    <div class='fixed z-40 top-0 left-0 flex flex-row items-center justify-center h-full w-full'>
      <div class='bg-white rounded-lg'>
        <loading class='' />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'LoadingIndicatorFullPage',
  components: {
    Loading
  }
}
</script>

<style scoped lang='scss'>



</style>
