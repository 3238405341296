<template>
  <div 
    class='cursor-pointer hover:shadow-md rounded-md text-left'>
    <h1 class='text-2xl font-semibold' @click='goToDescriptionPage'>{{ showingFacilityContactCardData.facilityName }}</h1>
    <div class='flex flex-row justify-start items-start gap-x-2 mt-2'>
      <img v-if='!hideMap'
        :src="showingFacilityContactCardData.mapImageUrl"
        class='w-24 object-cover p-1 bg-gray-100 rounded-md'
         @click='goToMapPage' />
      <div class='flex flex-col justify-start items-start gap-y-1 pt-1'>
        <div 
          class='flex flex-row justify-start items-center gap-x-4'
          @click='goToMapPage'>
          <map-pin-icon class='w-5 flex-shrink-0 flex-grow-0' />
          <div class='text-xs'>
            {{ showingFacilityContactCardData.address }}
          </div>
        </div>
        <a v-if='showingFacilityContactCardData.phoneNumber'
          :href='`tel:${showingFacilityContactCardData.phoneNumber}`'
          class='flex flex-row justify-start items-center gap-x-4'>
          <smartphone-icon class='w-5 flex-shrink-0 flex-grow-0' />
          <span class='text-xs'>
            {{ showingFacilityContactCardData.phoneNumber }}
          </span>
        </a>
        <a
          v-if='showingFacilityContactCardData.email'
          :href='`mailto:${showingFacilityContactCardData.email}`'
          class='flex flex-row justify-start items-center gap-x-4'>
          <mail-icon class='w-5 flex-shrink-0 flex-grow-0'/>
          <span class='text-xs'>
            {{showingFacilityContactCardData.email}}
          </span>
        </a>
      </div>
    </div>
  </div>

</template>

<script>
import { MailIcon, MapPinIcon, SmartphoneIcon } from 'vue-feather-icons'

export default {
  name: 'FacilityContactCard',
  components: {
    SmartphoneIcon,
    MapPinIcon,
    MailIcon,
  },
  props: [
    'hideMap',
    'facilityData',
    'usedForSelection',
  ],
  computed: {
    showingFacilityContactCardData () {
      return this.facilityData
    },
  },
  methods: {
    goToDescriptionPage () {
      if (this.usedForSelection) {
        // do nothing
      } else {
        window.open(this.showingFacilityContactCardData.descriptionUrl, '_blank')
      }
      
    },
    goToMapPage () {
       if (this.usedForSelection) {
         // do nothing
      } else {
        window.open(this.showingFacilityContactCardData.mapUrl, '_blank')
      }
    },
  },
}
</script>
