import Vue         from 'vue'
import Vuex        from 'vuex'
import range       from 'lodash/range'
import bookingsApi from '../../../src/api/v1/bookings'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')
// const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  bookingsDates: [],
  searchFacilityIds: [],
  startTime: 0, // prob need to reset to court start use time for the status page
  endTime: 24, // prob need to reset to court start use time for the status page
  searchNumHours: 1,
})

const getters = {
  searchTimeArray: state => {
    return range(state.startTime, state.endTime)
  },
  searchFacilities: (state, getters, rootState) => {
    return rootState.facilities.facilities.filter(facility => state.searchFacilityIds.includes(facility.id))
  }
}

const actions = {
  resetSearchedBookings ({commit}) {
    commit('setBookingsDates', [])
  },
  resetSearchFields ( {commit} ) {
    commit('setSearchFaciltyIds', [])
    commit('setBookingsDates', [])
    commit('setSearchTimes', [0, 24])
    commit('setSearchNumHours', 1)
  },
  showManagerBookableTimes ({ commit, dispatch }, dateTimeRange) {
    return new Promise(resolve => {
      commit('setBookingsDates', dateTimeRange.dateArray)
      commit('setSearchTimes', dateTimeRange.timeRange)
      commit('setSearchNumHours', dateTimeRange.searchNumHours)
      dispatch('getBookableTimesManager')
      resolve()
    })
  },
  getBookableTimesManager ({ state, commit, rootGetters }) {
    return new Promise(resolve => {
      bookingsApi.getManagerBookingsInRange(rootGetters['facilities/selectedManagedFacilityId'],
                                            state.bookingsDates,
                                            state.startTime, 
                                            state.endTime).then(resp => {                                 
        commit('bookings/setBookings', camelcaseKeys(resp.bookings), { root: true })
        resolve()
      })
    })
  },
  saveSearchDetails ({ commit }, details) {
    commit('setBookingsDates', details.dateArray)
    commit('setSearchTimes', details.timeRange)
    commit('setSearchNumHours', details.searchNumHours)
    commit('setSearchFaciltyIds', details.facilityIds)
  },
}

const mutations = {
  setBookings (state, bookings) {
    state.bookings = camelcaseKeys(bookings)
  },
  setBookingsDates (state, dates) {
    state.bookingsDates = dates
  },
  setSearchFaciltyIds (state, facilityIds) {
    state.searchFacilityIds = facilityIds
  },
  setSearchNumHours (state, hours) {
    state.searchNumHours = hours
  },
  setSearchTimes (state, times) {
    state.startTime = times[0]
    state.endTime   = times[1]
  },
}


export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}

