<template>
  <div class='lg:flex lg:flex-row lg:items-stretch' style='background-color: #FCFCFC;'> 
    <bookings-nav
      class='bookings-nav lg:border-r border-gray-100 shadow-xl w-full lg:w-96 lg:flex-grow-0 lg:flex-shrink-0 fixed lg:static z-40 lg:z-0 bg-white'
      :class='bookingsNavclass' />
    <bookings-schedule 
      class='overflow-x-auto overflow-y-auto lg:flex-grow bookings-container'
      :class='desktopRightPadding'
      :show-fully-booked-court='true'  />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BookingsNav      from '@/views/bookings/BookingsNav'
import BookingsSchedule from '@/views/bookings/BookingsSchedule'

export default {
  name: 'ManageBookings',
  components: {
    BookingsSchedule,
    BookingsNav,
  },
  computed: {
    ...mapState([
      'showSidepanel',
      'showContentOnMobile'
    ]),
    desktopRightPadding () {
      return (this.showSidepanel) ? 'desktop-right-padding' : ''
    },
    bookingsNavclass () {
      return (this.showContentOnMobile) ? 'hidden' : 'block'
    }
  },
}
</script>

<style lang='scss' scoped>
.bookings-nav {
  height: calc(100vh - 4rem);
}

.bookings-container,
.bookings-container.desktop-right-padding {
  padding-right: 0;
}

@media (min-width: 1024px) {
  .bookings-nav {
    height: auto;
    display: block;
  }

  .bookings-container.desktop-right-padding {
    padding-right: 30rem;
  }
}

@media print {
  .bookings-nav {
    display: none;
  }

  .bookings-container {
    @apply m-0 p-0;
    overflow: visible;
  }
}
</style>