<template>
  <div>
    <button 
      @click='refreshTimer'
      class='block border rounded w-full py-2 mt-4 uppercase text-gray-700 border-gray-200 hover:border-indigo-300 hover:shadow-lg text-sm flex flex-row justify-center items-center gap-x-1'>
      {{ timeLeftToRefresh }}초 before refresh
      <refresh-icon class='h-4' />
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { RefreshIcon } from '@vue-hero-icons/outline'

export default {
  name: 'BookingsNavRefreshControl',
  components: {
    RefreshIcon,
  },
  data () {
    return {
      timeLeftToRefresh: 300,
      interval: null,
    }
  },
  computed: {
    ...mapState('bookings', [
      'scheduleSelectedDay',
    ]),
  },
  methods: {
    ...mapActions('bookings', [
      'getBookingsForManager',
    ]),
    refreshTimer () {
      this.timeLeftToRefresh = 0
    },
    refreshAndResetTimer () {
      this.getBookingsForManager(this.scheduleSelectedDay).then(() => {
        this.timeLeftToRefresh = 300
        this.runTimer()
      })
    },
    runTimer () {
      this.interval = setInterval(() => {
        this.timeLeftToRefresh -= 1
        if (this.timeLeftToRefresh <= 0) {
          clearInterval(this.interval)
          this.refreshAndResetTimer()
        }
      }, 1000)
    },
  },
  mounted () {
    this.runTimer()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
}
</script>
