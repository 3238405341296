<template>
  <div 
    class='text-sm lg:text-base max-w-lg border rounded-lg bg-white bg-gray-100 shadow-md'
    :class='cancelledOrPassedBookingRequestBoxCss'>
    <div class='bg-white px-6 py-4 font-semibold'>
      <div v-if='isCancelledBookingRequest'>
        {{lastUpdatedAt}} 취소됨
      </div>
      <div v-else>
        이용완료
      </div>
    </div>
    <div class='flex flex-row items-start justify-between'>
      <div class='flex flex-col gap-x-4 py-4 px-6 w-40'>
        <div class='flex-shrink-0 flex-grow-0 rounded-md w-40 flex flex-col justify-start items-start gap-y-1'>
          <h3 class='text-sm'>{{monthAndDay}}</h3>
          <h1 class='text-3xl font-semibold text-gray-900'>{{dayOfWeek}}요일</h1>
        </div>
      </div>
      <div class='flex flex-col justify-center gap-y-1 py-4 px-6'>
        <div class='flex-shrink-0 flex-grow-0 rounded-md text-sm flex flex-col justify-start items-start gap-y-1'
          style='word-break: keep-all;'>
          <span class='flex flex-row justify-start items-center gap-x-1'><location-marker-icon class='inline-block h-5' /> 장소</span>
          <span class='text-2xl font-semibold text-gray-900'>{{bookingRequest.metaData.facilityName}}</span>
        </div>
      </div>
    </div>
    <div class='py-8 px-6 text-base'>
      <div v-for='(arrayOfSlots, key) in bookingsByCourt'
        :key='`${bookingRequest.id}-bookingSlot-${key}`'
        class='flex flex-row justify-start items-start border-b pb-2 mb-2'>
        <label class='w-16'><span class=''>코트</span> {{key}}</label>
          <div class='flex-grow'>
          <div v-for='(slot, key) in sortedAndCombinedSlots(arrayOfSlots)'
            :key='`${bookingRequest.id}-bookingSlot-${key}-slot-for-court-${slot.id}`'
            class='flex flex-row items-center justify-between'>
            <span class='inline-block'>{{slot.startTime}} - {{slot.endTime}}시 {{ timeLengthString(slot) }}</span>
            <span class='inline-block ml-1'>{{priceString(slot.price)}}원</span>
          </div>
        </div>
      </div>
      <div v-if='feeAmount > 0'
        class='flex flex-row justify-between items-start text-sm mb-2'>
        <label class='block'>온라인예약 수수료 (부가세포함)</label>
        <div>
          {{priceString(feeAmount)}}원
        </div>
      </div>
      <div class='text-lg font-semibold flex flex-row justify-between items-center rounded-md pt-2'>
        <span class='w-20'>결제 금액</span>
        <h3 class='text-gray-900'>{{priceString(totalPriceForBookingRequest)}}원</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { LocationMarkerIcon } from '@vue-hero-icons/outline'
import StringHelpers          from '@/utils/string-helpers'
import BookingRequestHelpers  from '@/utils/booking-request-helpers'
import numbro from 'numbro'

export default {
  name: 'PlayerPastBookingRequest',
  components: {
    LocationMarkerIcon,
  },
  props: [
    'bookingRequest',
    'dateCategory',
  ],
  computed: {
    monthAndDay () {
      return (this.bookingRequest.metaData.playDate) ? StringHelpers.monthDayWithKorean(this.bookingRequest.metaData.playDate) : ''
    },
    dayOfWeek () {
      return (this.bookingRequest.metaData.playDate) ? StringHelpers.dayOfWeekKorean(this.bookingRequest.metaData.playDate) : ''
    },
    totalPriceForBookingRequest () {
      let totalPrice = parseInt(this.bookingRequest.bookingsTotalPrice) / parseInt(this.bookingRequest.repeatCount) + parseInt(this.feeAmount)
      return totalPrice
    },
    bookingsByCourt () {
      return BookingRequestHelpers.requestBookingsByCourt(this.bookingRequest.schedules)
    },
    isCancelledBookingRequest () {
      return this.bookingRequest.bookingType === 'pay_cancelled'
    },
    cancelledOrPassedBookingRequestBoxCss () {
      return this.isCancelledBookingRequest ? 'line-through opacity-50' : 'opacity-70'
    },
    lastUpdatedAt () {
      let cancelledAt = this.bookingRequest.cancelledAt ? this.bookingRequest.cancelledAt : this.bookingRequest.updatedAt
      return StringHelpers.dateTimeString(cancelledAt)
    },
    feeAmount () {
      return this.bookingRequest.feeAmount ? parseInt(this.bookingRequest.feeAmount) : 0
    },
  },
  methods: {
    sortedAndCombinedSlots (slots) {
      return BookingRequestHelpers.combinedSlots(slots)
    },
    timeLengthString (slot) {
      let length = parseInt(slot.endTime) - parseInt(slot.startTime)
      return (length > 1) ? `(${length}시간)` : ''
    },
    priceString (price) {
      return numbro(price).format({trimMantissa: true, thousandSeparated: true})
    },
  },
}
</script>
