<template>
  <div>
    <h1 :class='topStyle'>
      취소 및 환불 규정 안내
      <router-link v-if='isPreview'
        :to='{ name: "CancellationPolicy"}'
        class='inline-block hover:bg-gray-100 rounded-md upercase underline text-blue-900 font-semibold text-center'
        target='_blank'>
        (자세히 보기)
      </router-link>
    </h1>
    <ol 
      class='list-decimal'
      :class='listStyle'>
      <li>결제 후 1시간 이내의 환불신청은 100% 환불 가능합니다.</li>
      <li>당일 예약시간 24시간 이전까지의 환불신청은 전체금액의 50% 환불 가능합니다.</li>
      <li>당일 예약시간 48시간 이전까지의 환불신청은 전체금액의 100% 환불 가능합니다.</li>
      <li>예약당일 사용하지 않을 경우나 이용시간이 지나간 시점에서의 환불은 불가 합니다.</li>
      <li>우천 등 천재지변으로 인한 환불은 가능합니다.</li>
    </ol>
  </div>
</template>

<script>
  
export default {
  name: 'BillingCancellationShort',
  props: [
    'isPreview',
  ],
  computed: {
    topStyle () {
      return this.isPreview ? 'text-sm mt-4' : 'text-lg mt-16'
    },
    listStyle () {
      return this.isPreview ? 'text-xs mt-2 ml-4' : 'mt-8'
    },
  }
}
</script>

