import Vue      from 'vue'
import Vuex     from 'vuex'

import users          from './modules/users'

import bookings       from './modules/bookings'
import courts         from './modules/courts'
import facilities     from './modules/facilities'
import prices         from './modules/prices'
import search         from './modules/search'

import playerProfile         from './modules/player-profile'
import playerSchedule        from './modules/player-schedule'
import playerBookingRequests from './modules/player-booking-requests'

import manageRequests from './modules/manage-requests'
import managePlayers  from './modules/manage-players'
import manageReports  from './modules/manage-reports'
import manageSettings from './modules/manage-settings'

import payments       from './modules/payments'
import paymentsKakao  from './modules/payments-kakao'
import paymentsToss   from './modules/payments-toss'

Vue.use(Vuex)

const state = () => ({
  isLoading: false,
  isSearching: false,
  roles: '',
  accessToken: '',
  showSidepanel: false,
  sidepanelContentComponent: 'div',
  showContentOnMobile: true,
  showMainPageLoadingIndicator: false,
  showingOnBoardingModal: false,
  userId: 0,
})

const getters = {
  showOverlay: state => {
    return state.showNavMenu || state.showModal || state.showPopover
  },
  isCourtManager: state => {
    return state.roles.includes('manager')
  },
  isCoach: state => {
    return state.roles.includes('coach')
  },
  isRegisteredPlayer: state => {
    return state.roles === 'player'
  },
}

const actions = {
  sidepanelClose ({ commit, dispatch }) {
    commit('showSidepanelToggle', false)
    dispatch('resetSidepanelComponent')
    dispatch('bookings/resetdrilldownBooking', null, {root: true})
  },
  sidepanelOpen ({ commit, state }, params) {
    if (!state.showSidepanel) {
      commit('showSidepanelToggle', true)
    }
    commit('setSidepanelContentComponent', params.drilldownComponent)
  },
  resetSidepanelComponent ({ commit }) {
    commit('setSidepanelContentComponent', 'div')
  },
  activateLoading ({ commit }) {
    commit('setIsLoading', true)
  },
  deactiveLoading ({ commit }) {
    commit('setIsLoading', false)
  },
  hideContentOnMobile ({ commit }) {
    commit('setShowContentOnMobile', false)
  },
  displayContentOnMobile ({ commit }) {
    commit('setShowContentOnMobile', true)
  },
  showFullPageLoading ({ commit }) {
    commit('setShowMainPageLoadingIndicator', true)
  },
  stopFullPageLoading ({ commit }) {
    commit('setShowMainPageLoadingIndicator', false)
  },
  setLocalStorageData ({ commit }) {
    let roles = (localStorage.getItem('role')) ? localStorage.getItem('role') : ''
    commit('setRoles', roles)
    let userId = (localStorage.getItem('userId')) ? parseInt(localStorage.getItem('userId')) : ''
    commit('setUserId', userId)
  },
  clearLocalStorageData ({ commit }) {
    localStorage.removeItem('auth._token.tennis_play')
    localStorage.removeItem('role')
    localStorage.removeItem('userId')
    localStorage.removeItem('selectedFacilityId')
    commit('users/setHasValidToken', false, { root: true })
    commit('setRoles', '')
    commit('setUserId', 0)
  },
  showOnBoardingModal ({ commit }) {
    commit('setShowOnBoardingModal', true)
  },
  closeOnBoardingModal ({ commit }) {
    commit('setShowOnBoardingModal', false)
  }
}


const mutations = {
  showSidepanelToggle (state, openSidepanel) {
    state.showSidepanel = openSidepanel
  },
  setSidepanelContentComponent (state, componentName) {
    state.sidepanelContentComponent = componentName
  },
  setIsLoading (state, isLoading) {
    state.isLoading = isLoading
  },
  setRoles (state, roles) {
    state.roles = roles
  },
  setShowMainPageLoadingIndicator (state, boolean) {
    state.showMainPageLoadingIndicator = boolean
  },
  setShowContentOnMobile (state, showBool) {
    state.showContentOnMobile = showBool
  },
  setUserId (state, userId) {
    state.userId = userId
  },
  setShowOnBoardingModal (state, boolean) {
    state.showingOnBoardingModal = boolean
  }
}

export default new Vuex.Store({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    users,
    bookings,
    courts,
    facilities,
    prices,
    search,
    playerProfile,
    playerSchedule,
    playerBookingRequests,
    manageRequests,
    managePlayers,
    manageReports,
    manageSettings,
    paymentsKakao,
    paymentsToss,
    payments,
  },
  strict: 'true'
})
