import axios  from 'axios'
import router from '@/router'
import index from '@/router/index'


const base = axios.create({
  baseURL: `${process.env.VUE_APP_TENNIS_API}/api/v1`,
  headers: {'Content-Type': 'application/json; charset=utf-8'},
})

base.interceptors.request.use(config => {
    let token = localStorage.getItem('auth._token.tennis_play')
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  function (error) {
    return Promise.reject (error)
  }
)



base.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    if (index.currentRoute.name === 'Login' && error.response.config.url === '/users/sso_sessions/check_token') {
      return Promise.reject(error)
    } else if (error.response.config.url === '/profiles/my_profiles') {
      return Promise.reject(error.response)
    }
    console.error(error)
    router.push({ name: 'Login' }).catch(() => {})
  } else if (error.response.status === 403) {
    console.error(error)
    router.push({ name: 'Login' }).catch(() => {})
  } else {
    console.error(error)
    return Promise.reject(error)
  }
})

export default base
