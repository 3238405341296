<template>
  <div class='overflow-y-auto'>
    <h1 class='text-2xl mb-8'>
       예약하기
    </h1>
    <div class='mb-8'>
      <label for='reserver' 
        class='block text-sm font-medium text-gray-700 mb-2'>
        종류
      </label>
      <el-radio-group v-model='bookingType' class=''>
        <el-radio-button label='reserved'>일반 예약</el-radio-button>
        <el-radio-button label='block'>타임 블락</el-radio-button>
      </el-radio-group>
    </div>
    <div v-if='isReservationBooking' class='mb-8'>
      <label for='reserver' 
        class='block text-sm font-medium text-gray-700 mb-2'>
        예약자
      </label>
      <select-or-create-profile
        @update-selected-profile='selectedProfile' />
    </div>
    <div class='mb-8'>
      <label for='repeat'
        class='block text-sm font-medium text-gray-700 mb-2'>반복 기간</label>
      <div class='flex flex-row justify-between items-center'>
        <div>
          {{scheduleSelectedDayString}} ~ 
          <datepicker
            class='inline-block'
            v-model='repeatEndDate'
            format='yyyy-MM-dd (D)'
            :language='ko'
            input-class='w-36'
            calendar-class='-left-24'
            :disabled-dates="{to: new Date(scheduleSelectedDay)}"
            :highlighted='highlightedDatesOnCalendar'
            @selected='updateRepeatCount'>
          </datepicker>
        </div>
        <div class='text-sm font-medium'>
          (반복 <span style="background-color: #FFFF00">{{repeatCount}}회</span>)
        </div>
      </div>
    </div>
    <div class='mb-8'>
      <table class='w-full text-sm'>
        <thead>
          <tr class='border-b text-left'>
            <th class='pb-2'>코트</th>
            <th class='pb-2'>시작날짜</th>
            <th class='pb-2'>종료날짜</th>
            <th class='pb-2'>시간</th>
            <th class='pb-2 text-right'>가격</th>
          </tr>
        </thead>
        <add-new-booking-request-row 
          v-for='newBookingRequest in newBookingRequestSlotList'
          :key='`newBookingRequest-${newBookingRequest.courtId}-${newBookingRequest.bookingDate}-${newBookingRequest.startTime}`'
          :new-booking-request='newBookingRequest'
          :check-for-repeat-dates='true'
          :repeat-count='repeatCount'
          :new-booking-request-type='bookingType'
          @stack-create-new-booking-request-available='getHasBookingOnRepeatedDateStatues'
          @remove-new-booking-request-on-list='removeNewBookingRequestOnList' />
        <tr class='border-b border-t font-medium'>
          <td colspan='4'
            class='py-2 text-left'>1주당 가격</td>
          <td class='py-2 text-right'>{{subTotalPriceString}}</td>
        </tr>
        <tr class='border-b border-t font-medium'>
          <td colspan='4'
            class='py-2 text-left'>반복 횟수</td>
          <td class='py-2 text-right'>x {{repeatCount}}</td>
        </tr>
      </table>
    </div>
    <div class='mb-8'>
      <div 
        class='p-2 border-b font-bold flex flex-row justify-between items-center' 
        style='background-color: #FAFAFA;'>
        <label class='block'>Total</label>
        <div class=''>
          <input v-model='totalPrice' class='form-input text-right h-full py-2 rounded-md'>
          원
        </div>
      </div>
    </div>

    <div class='mb-8'>
      <label for='note' class='block text-sm font-medium text-gray-700'>노트</label>
      <div class=''>
        <textarea 
          v-model='note'
          class='w-full text-sm'
          rows='6'>
        </textarea>
      </div>
    </div>  
    <portal to='sidepanel-footer'>
      <div class='flex flex-row w-full h-20 py-4'>
        <button class='block text-sm text-white py-3 px-3 w-1/2 rounded-md hover:shadow-lg bg-gray-900'
                :disabled='disabledNewBookingRequestAddButton'
                :class='createNewBookingRequestButtonClass'
                @click='submitNewReservation'>
          {{ addBookingButtonText }}
        </button>
        <button class='block bg-transparent text-sm text-gray-700 py-3 px-2 md:px-4 border border-transparent hover:border-gray-200 ml-2 rounded-md hover:shadow-sm'
          @click='doneEdit'>
          취소
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import Datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'
import dayjs  from 'dayjs'
import numbro from 'numbro'
import StringHelpers      from '@/utils/string-helpers'
import AddNewBookingRequestRow from '@/components/bookings/AddNewBookingRequestRow'
import SelectOrCreateProfile   from '@/components/profiles/SelectOrCreateProfile'
import BookingRequestHelpers   from '@/utils/booking-request-helpers'
import EventBus                from '@/utils/event-bus'

export default {
  name: 'ManagerNewBookingRequest',
  components: {
    Datepicker,
    AddNewBookingRequestRow,
    SelectOrCreateProfile
  },
  data () {
    return {
      repeatEndDate: '',
      ko: ko,
      hasBookingOnRepeatedDateStatues: [],
      repeatCount: 1,
      totalPrice: 0,
      selectedProfileId: 0,
      bookingType: 'reserved',
      note: '',
    }
  },
  watch: {
    newBookingRequestSlotList: {
      handler: function () {
        this.updateTotalPrice()
      },
      immediate: true,
    },
    repeatCount: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          this.updateTotalPrice()
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('bookings', [
      'scheduleSelectedDay',
    ]),
    ...mapState('manageRequests', [
      'newBookingRequestSlotList',
    ]),
    ...mapGetters('facilities', [
      'selectedManagedFacilityId',
      'selectedManagedFacilityName',
    ]),
    ...mapGetters('courts', [
      'courtNameFromId',
    ]),
    ...mapGetters('prices', [
      'priceForNewBookingRequest'
    ]),
    subTotalPrice () {
      return this.newBookingRequestSlotList.map(booking => parseFloat(this.priceForNewBookingRequest(booking)))
                                           .reduce((a, b) => a + b, 0)
    },
    isReservationBooking () {
      return (this.bookingType === 'reserved')
    },
    subTotalPriceString () {
      return numbro(this.subTotalPrice).format({trimMantissa: true, thousandSeparated: true})
    },
    submitNewBookingRequestData () {
      let params = {
          new_booking_request: {
            facility_id:          this.selectedManagedFacilityId,
            booking_type:         this.bookingType,
            repeat_count:         this.repeatCount,
            bookings_total_price: parseInt(this.totalPrice),
            note:                 this.note,
            meta_data: {
              facility_name: this.selectedManagedFacilityName,
              dates: this.datesFromRepeatCountAndFirstDate(this.scheduleSelectedDay)
            },
            schedules: this.newBookingRequestSlotList.map(request => {
              return {
                court_id:      request.courtId,
                court_name:    this.courtNameFromId(request.courtId),
                facility_name: this.selectedManagedFacilityName,
                booking_date:  request.bookingDate,
                start_time:    request.startTime,
                end_time:      request.endTime,
                price:         parseInt(this.priceForNewBookingRequest(request)),
              }
          }),
        }
      }
      if (this.isReservationBooking) {
        params.new_booking_request['profile_id'] = this.selectedProfileId
      }
      return params
    },
    hasBookingsOnRepeatedDates () {
      return this.hasBookingOnRepeatedDateStatues.map(newBookingRequest => newBookingRequest.status).some(status => status === true)
    },
    createNewBookingRequestButtonClass () {
      return (!this.disabledNewBookingRequestAddButton) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    disabledNewBookingRequestAddButton () {
      return this.hasBookingOnRepeatedDateStatues.length === 0 ||
             this.hasBookingsOnRepeatedDates || 
             (!this.selectedProfileId && this.isReservationBooking)
    },
    addBookingButtonText () {
      return this.isReservationBooking ? '예약추가' : '타임블락'
    },
    scheduleSelectedDayString () {
      return this.scheduleSelectedDay + ' (' + StringHelpers.dayOfWeekKorean(this.scheduleSelectedDay) + ')'
    },
    highlightedDatesOnCalendar () {
      return {
        from: new Date(this.scheduleSelectedDay),
        to: new Date(this.repeatEndDate)
      }
    }
  },
  methods: {
    ...mapMutations('manageRequests', [
      'removeNewBookingRequestSlotList',
      'resetNewBookingRequestSlotList',
    ]),
    ...mapActions('manageRequests', [
      'postNewBookingRequestSlots',
      'postNewBlockRequestSlots',
    ]),
    ...mapActions('bookings', [
      'getBookingsForManager',
    ]),
    datesFromRepeatCountAndFirstDate (firstDate) {
      let arr = []
      for (var i = 0; i < this.repeatCount; i++) {

        arr.push(dayjs(firstDate).add(1*i, 'week').format('YYYY-MM-DD'))
      }
      return arr
    },
    selectedProfile (profileId) {
      this.selectedProfileId = profileId
    },
    updateTotalPrice () {
      this.totalPrice = this.repeatCount * this.subTotalPrice
    },
    getHasBookingOnRepeatedDateStatues (status) {
      this.hasBookingOnRepeatedDateStatues.push(status)
    },
    removeBookingFromCheck (removedRequest) {
      this.removeFromCheckRepeatList(removedRequest)
    },
    removeNewBookingRequestOnList (removedRequest) {
      this.removeNewBookingRequestSlotList(removedRequest)
      this.removeFromCheckRepeatList(removedRequest)
    },
    removeFromCheckRepeatList (removedRequest) {
      let index = this.hasBookingOnRepeatedDateStatues.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, removedRequest))
      if (index !== -1) {
        this.hasBookingOnRepeatedDateStatues.splice(index, 1)
      }
    },
    doneEdit () {
      this.$emit('done-edit')
    },
    submitNewReservation () {
      this.$confirm(`${this.addBookingButtonText} 하시겠습니까?`, {
        confirmButtonText: this.addBookingButtonText,
        cancelButtonText: '취소',
        type: 'success'
      }).then(() => {
        if (this.isReservationBooking) {
          this.postNewBookingRequestSlots(this.submitNewBookingRequestData).then(() => {
            this.resetNewBookingRequestSlotList()
            this.getBookingsForManager(this.scheduleSelectedDay)
            this.$emit('done-edit')
          }).catch((error) => {
            if (error.response.status === 409) {
              this.resetNewBookingRequestSlotList()
              this.getBookingsForManager(this.scheduleSelectedDay)
              this.$emit('done-edit')
              this.$alert('이미 예약되거나 결제중인 시간이 포함되어 있습니다. 다른 시간을 선택해주세요.', {
                type: 'warning',
                showClose: false,
              })
            } else {
              this.$alert('알 수 없는 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.', {
                type: 'warning',
                showClose: false,
              })
            }
          })
        } else {
          this.postNewBlockRequestSlots(this.submitNewBookingRequestData).then(() => {
            this.resetNewBookingRequestSlotList()
            this.getBookingsForManager(this.scheduleSelectedDay)
            this.$emit('done-edit')
          }).catch((error) => {
            if (error.response.status === 409) {
              this.resetNewBookingRequestSlotList()
              this.getBookingsForManager(this.scheduleSelectedDay)
              this.$emit('done-edit')
              this.$alert('이미 예약되거나 결제중인 시간이 포함되어 있습니다. 다른 시간을 선택해주세요.', {
                type: 'warning',
                showClose: false,
              })
            } else {
              this.resetNewBookingRequestSlotList()
              this.getBookingsForManager(this.scheduleSelectedDay)
              this.$emit('done-edit')
              this.$alert('알 수 없는 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.', {
                type: 'warning',
                showClose: false,
              })
            }
          })
        }
      }).catch(() => {})
    },
    updateRepeatCount (event) {
      this.repeatEndDate = dayjs(event)
      this.repeatCount = this.repeatEndDate.diff(this.scheduleSelectedDay, 'week') + 1
    }
  },
  mounted () {
    EventBus.$on('remove-repeat-check', (request) => this.removeBookingFromCheck(request))
    this.repeatEndDate = this.scheduleSelectedDay
  },
  beforeDestroy () {
     EventBus.$off('remove-repeat-check')
  }
}
</script>

<style lang='scss'>
input.vs__search[type='search'], 
input.vs__search[type='search']:focus {
  padding: 0 7px;
  line-height: 1.4;
  border-color: transparent;
  --tw-ring-color: transparent;
}
</style>
