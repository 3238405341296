<template>
  <div class='booking-schedule-controls'>
    <div v-if='selectExistingBookingMode'
      class='flex flex-row justify-between items-center fixed top-1 bg-green-500 text-white z-30 py-3 bookings-schedule-controls-button-width'>
      <div class='font-semibold w-full'>
        예약 선택
      </div>
      <button v-if='hasSelectedExistingBookingList'
        class='rounded lg:rounded-md flex flex-row justify-center items-center text-white opacity-75 hover:opacity-100 mr-2'
        @click='cancelMultipleBookings'>
        <trash-icon />
      </button>
    </div>
    <div 
      class='flex flex-row justify-end fixed bottom-1 px-2 z-10 bookings-schedule-controls-button-width'>
      <button v-if='hasNewBookingRequestSlotList'
        class='rounded lg:rounded-md py-2 lg:py-3 bg-blue-500 text-white font-semibold mr-1'
        :class='reservationBookingWidth'
        @click='openNewBookingRequest'>
        예약하기
      </button>
      <button v-if='isCourtManager'
        class='rounded lg:rounded-md py-2 lg:py-3 bg-green-500 text-white font-semibold w-1/4'
        @click='toggleSelectExistingBookingMode'>
        {{ toggleSelectExistingBookingText }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { TrashIcon }      from '@vue-hero-icons/outline'

export default {
  name: 'BookingsScheduleControls',
  components: {
    TrashIcon,
  },
  computed: {
    ...mapGetters([
      'isCourtManager',
    ]),
    ...mapState('courts', [
      'courts',
    ]),
    ...mapState('bookings', [
      'selectExistingBookingMode'
    ]),
    ...mapGetters('bookings', [
      'hasSelectedExistingBookingList',
      'selectedExistingBookingCount'
    ]),
    ...mapGetters('manageRequests', [
      'hasNewBookingRequestSlotList'
    ]),
    newBookingRequestComponent () {
      return (this.isCourtManager) ? 'manager-new-booking-request' : 'player-new-booking-request'
    },
    reservationBookingWidth () {
      return (this.isCourtManager) ? 'w-3/4' : 'w-full'
    },
    toggleSelectExistingBookingText () {
      return this.selectExistingBookingMode ? '취소' : '선택'
    },
  },
  methods: {
    ...mapActions([
      'sidepanelOpen',
    ]),
    ...mapActions('bookings', [
      'toggleSelectExistingBookingMode',
      'cancelBatchBookingsNotRelatedRequest'
    ]),
    openNewBookingRequest () {
      this.sidepanelOpen({ drilldownComponent: this.newBookingRequestComponent })
    },
    cancelMultipleBookings () {
      this.$confirm(`선택한 ${this.selectedExistingBookingCount}개의 예약을 삭제할까요?`, {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        type: 'warning'
      }).then(() => {
        this.cancelBatchBookingsNotRelatedRequest().then(() => {
          this.$message({
            type: 'success',
            message: '예약이 삭제되었습니다.'
          })
        })
      }).catch(() => {})
    }
  },
}
</script>

<style lang='scss' scoped>
  .bookings-schedule-controls-button-width {
    width: 100%;
  }

  @media (min-width: 1024px) {
    .bookings-schedule-controls-button-width {
      width: calc(100% - 30rem);
    }
  }

  @media print {
    .booking-schedule-controls {
      display: none;
    }
  }
</style>
