<template>
  <div>
    <h1 class='mt-10 text-lg text-center uppercase text-gray-700 mb-2' style='font-weight:900'>
      테니스플레이계정의 비밀번호를 재설정합니다.
    </h1>
    <p>비밀번호를 재설정을 위해 회원가입하신 이메일, 이름, 전화번호, 그리고 새로운 비밀번호를 입력해 주세요.</p>
    <div class='mt-4'>
      <input 
        v-model='email'
        class=' w-full p-2 rounded border-gray-300'
        type='text'
        placeholder='이메일'>
      <input 
        v-model='name'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='text'
        placeholder='이름'>
      <input 
        v-model='phoneNumber'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='text'
        placeholder='전화번호'>
      <input 
        v-model='password'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='password'
        placeholder='비밀번호'
        @focusout='checkPasswordRequirements'>
      <div v-if='!arePasswordRequirementsMet'
        class='text-red-600 text-sm text-left'>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.</div>
      <input 
        v-model='passwordConfirmation'
        class='mt-4 w-full p-2 rounded border-gray-300'
        type='password'
        placeholder='비밀번호 확인'
        @focusout='checkPasswordConfirmation'>
      <div v-if='!isPasswordConfirmed'
        class='text-red-600 text-sm text-left'>비밀번호가 일치하지 않습니다.</div>
      <button
        class='mt-8 w-full p-4 rounded text-white uppercase'
        :class='emptyFieldLoginButtonClass'
        :disabled='needToInputLoginField'
        @click='submitUpdateLoginPassword'>
        비밀번호 재설정
      </button>
      <div class='flex flex-row-reverse justify-between mt-4'>
        <button class='text-gray-900'
          @click="changeShowingForm('login')">
          로그인하러 가기
        </button>
        <ul v-if='needToInputLoginField'
          class='list-disc list-inside text-red-600 text-sm text-left'>
          <li v-if='emptyEmailField'>이메일을 입력해주세요.</li>
          <li v-if='emptyPasswordField'>비밀번호를 입럭해주세요.</li>
          <li v-if='emptyNameField'>이름을 입럭해주세요.</li>
          <li v-if='emptyPhoneNumberField'>전화번호를 입럭해주세요.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields }        from 'vuex-map-fields'
import { mapActions }       from 'vuex'

export default {
  name: 'UpdateLoginPasswordForm',
  data () {
    return {
      arePasswordRequirementsMet: true,
      isPasswordConfirmed: true,
    }
  },
  computed: {
    ...mapFields('users', [
      'forgotPasswordParams.email',
      'forgotPasswordParams.password',
      'forgotPasswordParams.passwordConfirmation',
      'forgotPasswordParams.name',
      'forgotPasswordParams.phoneNumber',
    ]),
    emptyEmailField () {
      return !this.email
    },
    emptyPasswordField () {
      return !this.password
    },
    emptyPasswordConfirmationField () {
      return !this.passwordConfirmation
    },
    emptyNameField () {
      return !this.name
    },
    emptyPhoneNumberField () {
      return !this.phoneNumber
    },
    needToInputLoginField () {
      return this.emptyEmailField ||
             this.emptyPasswordField ||
             this.emptyPasswordConfirmationField ||
             this.emptyNameField ||
             this.emptyPhoneNumberField ||
             !this.arePasswordRequirementsMet ||
             !this.isPasswordConfirmed
    },
    emptyFieldLoginButtonClass () {
      return (this.needToInputLoginField) ? 'bg-gray-500 opacity-25 cursor-not-allowed' : 'bg-gray-900'
    },
  },
  methods: {
    submitUpdateLoginPassword () {
      this.updateLoginPassword().then(() => {
        this.$alert('비밀번호 재설정이 완료되었습니다.', {
          confirmButtonText: '확인',
          type: 'success'
        }).then(() => {
          this.changeShowingForm('login')
        }).catch(() => {
          this.changeShowingForm('login')
        })
      }).catch(() => {
        this.$alert('찾으시는 회원정보가 없습니다. 이름, 전화번호 그리고 이메일을 확인해주십시오.', {
          confirmButtonText: '확인',
          type: 'warning'
        })
      })
    },
    checkPasswordRequirements () {
      let regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,16}$/
      this.arePasswordRequirementsMet = regExp.test(this.password)
      if (this.passwordConfirmation) {
        this.isPasswordConfirmed = (this.password === this.passwordConfirmation)
      }
    },
    checkPasswordConfirmation () {
      this.isPasswordConfirmed = (this.password === this.passwordConfirmation)
    },
    ...mapActions('users',[
      'updateLoginPassword'
    ]),
    changeShowingForm (showingForm) {
      this.$emit('change-showing-form', showingForm)
    },
  }
}
</script>
