<template>
  <section class=' mt-8 lg:mt-12'>
    <h2 class='text-lg font-semibold uppercase text-gray-600'>Requests</h2>
    <div class='mt-2 border rounded-md shadow-sm flex flex-row justify-start'>
      <div class='flex-grow border-r p-2'>
        <h4 class='text-sm text-gray-500'>
          asdfasdfasdfasdf
           <span class='inline-block text-gray-400 whitespace-nowrap'>- asdfasdfasdfasdf</span>
        </h4>
        <div class='text-2xl'>
          asdfasdfasdf status
        </div>
        <div class='text-xs uppercase text-gray-500'>
          PAID
        </div>
      </div>
      <div class='px-3 py-2'>
        <h4 class='text-sm text-gray-500'>월요일</h4>
        <div class='text-base pb-2 text-gray-700'>
          15:00 시
        </div>
        <div class='text-xs uppercase text-gray-600'>
          코트 A
        </div>
      </div>
      <div class='px-3 py-2'>
        <h4 class='text-sm text-gray-500'>목요일</h4>
        <div class='text-base pb-2 text-gray-700'>
          14:00 시
        </div>
        <div class='text-xs uppercase text-gray-600'>
          코트 B
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PlayerDrilldownRequests',
  computed: {
    ...mapState('managePlayers', [
      'drilldownPlayer'
    ]),
  },
}
</script>
