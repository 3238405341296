<template>
  <div class='booking-nav'>
    <datepicker 
      :inline='true'
      @input='updateDate'
      minimumView='day'
      maximumView='day'
      calendar-class='w-full'
      :value='scheduleSelectedDay'
      :language='ko'
      :disabled-dates='disabledFunction'/>
    <button v-if='!showingToday'
      @click='backToToday'
      class='inline-block hover:shadow-md rounded-md py-2 w-full mt-4 text-sm border hover:border-gray-500 uppercase text-gray-700 text-center'>
      {{goToDateButtonString}}
    </button>
  </div>
</template>

<script>
import { mapFields }  from 'vuex-map-fields'
import Datepicker from 'vuejs-datepicker'
import {ko}       from 'vuejs-datepicker/dist/locale'
import dayjs    from 'dayjs'
import { mapState } from 'vuex'

export default {
  name: 'PlayerBookingsNavSelectDate',
  components: {
    Datepicker,
  },
  data() {
    return {
      ko: ko,
    }
  },
  watch: {
    currentTimeInKorea: {
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.backToToday()
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapFields('bookings', [
      'scheduleSelectedDay',
    ]),
    ...mapState('bookings', [
      'currentTimeInKorea',
    ]),
    goToDateButtonString () {
      if (!this.showingToday) {
        return '오늘로 가기'
      } else {
        return ''
      }
    },
    showingToday () {
      let todayFormatted         = dayjs(this.currentTimeInKorea).format('YYYY-MM-DD')
      let scheduledDateFormatted = dayjs(this.scheduleSelectedDay).format('YYYY-MM-DD')
      return scheduledDateFormatted == todayFormatted
    },
    disabledFunction () {
      return {
        customPredictor: (date) => {
          return ( (dayjs(date).startOf('day') < dayjs(this.currentTimeInKorea).startOf('day')) ||
                   (dayjs(date).startOf('day') > dayjs(this.currentTimeInKorea).add(7, 'day').startOf('day')) ||
                  ((dayjs(date).startOf('day') > dayjs(this.currentTimeInKorea).add(6, 'day').startOf('day')) && dayjs(this.currentTimeInKorea).hour() < 9)) 
        }
      }
    }
  },
  methods: {
    backToToday () {
      if (this.showingToday) {
        this.$emit('updated-date')
      } else {
        this.scheduleSelectedDay = dayjs(this.currentTimeInKorea).format('YYYY-MM-DD')
      }
    },
    updateDate (date) {
      this.scheduleSelectedDay = dayjs(date).format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang='scss'>
  .booking-nav .el-calendar {
    background-color: transparent;
  }

</style>
