<template>
  <div class='h-full w-full bg-white relative'>
    <nav-menu 
      v-if='hasUserId'
      class='z-20 shadow-md fixed top-0 left-0 w-screen h-16 lg:h-full lg:w-16 lg:pl-0 lg:pt-8 nav-menu' />
    <div class='z-10 mt-16 ml-0 lg:mt-0 lg:ml-16 page-content'>
      <slot />
    </div>
    <sidepanel><portal-target name='sidepanel'></portal-target></sidepanel>
    <onboarding-modal />
    <loading-indicator-full-page v-if='showMainPageLoadingIndicator' />
    <on-load-modal v-if='isRegisteredPlayer && selectedFacilityShowOnLoadModal'>
      <logo height='3rem' class='mb-8' />
      <div v-html='selectedFacilityOnLoadModalMessage'
        class='whitespace-pre-line text-base px-4 leading-5 mb-4 font-medium'>    
      </div>
    </on-load-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LoadingIndicatorFullPage from '@/components/LoadingIndicatorFullPage'
import NavMenu                  from '@/components/NavMenu'
import Sidepanel                from '@/components/Sidepanel'
import OnboardingModal          from '@/components/OnboardingModal'
import OnLoadModal              from '@/components/OnLoadModal.vue'
import Logo                     from '@/components/Logo'

export default {
  name: 'DefaultLayout',
  components: {
    LoadingIndicatorFullPage,
    NavMenu,
    Sidepanel,
    OnboardingModal,
    OnLoadModal,
    Logo,
  },
  computed: {
    ...mapState([
      'showMainPageLoadingIndicator',
      'showingOnBoardingModal',
    ]),
    ...mapGetters([
      'isRegisteredPlayer'
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityShowOnLoadModal',
      'selectedFacilityOnLoadModalMessage',
    ]),
    hasUserId () {
      return localStorage.getItem('userId')
    },
  },
  methods: {
    ...mapActions([
      'setLocalStorageData',
    ]),
    ...mapActions('facilities', [
      'getFacilitiesData',
    ]),
    ...mapActions('bookings', [
    'getCurrentTimeInKorea',
    ]),
    ...mapActions('playerProfile', [
      'getMyProfiles',
    ]),
  },
  mounted () {
    this.getCurrentTimeInKorea()
    if (this.hasUserId) {
      this.setLocalStorageData()
      this.getFacilitiesData()
      // checking with Local Storage since its faster, and the action above is asyncronous, and can't be trusted to be done in time
      if (localStorage.getItem('role') == 'player') {
        this.getMyProfiles()
      }
    } else if (this.$route.name === 'Schedule') {
      this.getFacilitiesData() // need to get so we can show the right schedule
    } else {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang='scss' scoped>
.nav-menu {
  background-color: #F5F5F5;
}

@media (min-width: 1024px) {
  .nav-menu {
    background-color: #EFEFEF;
  }
}

@media print {
  .page-content {
    @apply p-0 m-0;
  }
}
</style>
