<template>
  <div class='pt-8 pb-32 lg:max-w-3xl'>
    <div class='px-8 text-left'>
      <h1 class='font-semibold text-2xl mb-8'>예약정보 확인</h1>
      <el-steps :active='1' align-center class='player-booking-flow mb-8'>
        <el-step title='코트 선택'></el-step>
        <el-step title='정보 확인 & 결제'></el-step>
        <el-step title='예약 완료'></el-step>
      </el-steps>
      <player-new-booking-request-card />
      <div class='overflow-y-auto w-full border mt-16' style='max-height: 400px;'>
        <billing-cancellation-full-details class='p-8' />
      </div>
      <div class='flex flex-col lg:flex-row justify-start items-center border rounded mb-8'>
        <div class='flex flex-col justify-start items-start bg-gray-100 py-4 px-8 w-full lg:w-48'>
          <label class='text-xs text-gray-500'>예약자</label>
          <h3 class='text-gray-900 font-bold text-sm'>{{myProfile.name}}</h3>
          <h3 class='text-gray-900 text-sm'>{{myProfile.phoneNumber}}</h3>
        </div>
        <label 
          class='flex flex-row justify-start items-center gap-x-4 p-4'>
          <input 
            type='checkbox'
            v-model='confirmCancellationPolicy'/>
          <span class='text-sm'>온라인 예약 약관에 관한 위 내용을 확인하였으며 결제에 동의합니다.</span>
        </label>
      </div>
    </div>
    <div class='px-8 text-left'>
      <div class='border'>
        <div class='p-8 ' style="background-color: rgb(250, 250, 250);">
          <h2 class='text-xl lg:text-2xl'>결제수단</h2>
          <p class='text-sm lg:text-base text-gray-500'>은행 점검시간인 23:30 ~ 00:30 까지 이용불가한 게좌이체 결제수단이 포함되어 있습니다.</p>
        </div>
        <div class='p-8'>
          <label class='block mb-4' v-if='false'>
            <input class='ml-2' type='radio' v-model='selectedPayWith' value='kakaoPayment'>
            <img src='/payment_icon_kakao_medium.png' class='ml-4 mr-2 inline-block h-6' /> 카카오페이
          </label>
          <label class='block'>
            <input class='ml-2' type='radio' v-model='selectedPayWith' value='tossPayment'>
            <img src='/payment_icon_toss_medium.png' class='ml-4 mr-2 inline-block h-4' /> 토스
          </label>
        </div>
      </div>
    </div>
    <div class='fixed flex flex-row gap-x-4 lg:gap-x-8 bottom-0 py-4 px-8 bg-gray-100 w-full'>
      <span v-if='!confirmCancellationPolicy'
        class='py-3 px-3 lg:px-8 border rounded-md text-sm lg:test-base'>온라인 예약 약관에 관한 위 내용을 확인하고 결제에 동의해주세요.</span>
      <span v-else-if='!hasSelectedPayWith'
        class='py-3 px-3 lg:px-8 border rounded-md text-sm lg:test-base'>결제수단을 선택해주세요.</span>
      <button 
        v-else
        class='block text-sm text-white font-semibold px-8 lg:px-24 rounded-md hover:shadow-lg bg-gray-900'
        :disabled='!confirmCancellationPolicy'
        @click='requestPayment'>
        {{bookingsTotalPriceWithFeeString}} 결제하기
      </button>
      <button class='block text-sm py-3 px-4 border border-sold border-transparent hover:border-gray-300 hover:shadow-md rounded-md'
        @click='goToScheduleBooking'>
        예약 취소
      </button>
    </div>
  </div>
</template>

<script>
import { loadTossPayments } from '@tosspayments/payment-sdk'
import { mapState, mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import PlayerNewBookingRequestCard    from '@/components/PlayerNewBookingRequestCard'
import BillingCancellationFullDetails from '@/components/BillingCancellationFullDetails'
import StringHelpers from '@/utils/string-helpers'
import uniq from 'lodash/uniq'

export default {
  name: 'PaymentReady',
  components: {
    PlayerNewBookingRequestCard,
    BillingCancellationFullDetails,
  },
  data () {
    return {
      confirmCancellationPolicy: false,
    }
  },
  computed: {
    ...mapState('payments', [
      'currentOrder',
    ]),
    ...mapFields('payments', [
      'selectedPayWith',
    ]),
    ...mapState('paymentsKakao', [
      'nextRedirectMobileUrl',
      'nextRedirectPcUrl',
    ]),
    ...mapGetters('playerProfile', [
      'myProfile',
    ]),
    ...mapGetters('payments', [
      'hasCurrentOrder',
      'hasSelectedPayWith',
      'isSelectedPayWithKakao',
      'isSelectedPayWithToss',
    ]),
    ...mapGetters('facilities', [
      'selectedFacilityBookingRequestFeeAmount',
    ]),
    bookingsTotalPriceWithFeeString () {
      return StringHelpers.moneyWithUnits(parseInt(this.currentOrder.bookingsTotalPrice) + this.selectedFacilityBookingRequestFeeAmount)
    },
  },
  methods: {
    ...mapActions('payments', [
      'resetCurrentOrder'
    ]),
    ...mapActions('paymentsKakao', [
      'requestKakaoPaymentReady',
    ]),
    goToScheduleBooking () {
      this.$router.push({name: 'PlayerScheduleBookings'})
    },
    requestKakaoPayment () {
      this.requestKakaoPaymentReady(this.currentOrder.id).then(() => {
        if (this.$isMobile()) {
          window.location.href = this.nextRedirectMobileUrl
        } else {
          window.location.href = this.nextRedirectPcUrl
        }
      }).catch((error) => {
        console.error('error: ', error)
        if (error.response.status === 409) {
          this.$alert('이미 예약되거나 결제중인 시간이 포함되어 있습니다. 다른 시간을 선택해주세요.', {
            type: 'warning',
            showClose: false,
          }).then(() => {
            this.$router.push({name: 'PlayerScheduleBookings'})
          })
        } else {
          this.$alert('결제가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning',
            showClose: false,
          }).then(() => {
            this.$router.push({name: 'PaymentFail'})
          })
        }
      })
    },
    requestTossPayment () {
      loadTossPayments(`${process.env.VUE_APP_FORCE_BE_TOSS_PAYMENT_CLIENT_KEY}`).then(tossPayments => {
        // if orderId changed, api also need to be changed
        let orderId = `F${this.currentOrder.facilityId}`
        orderId = orderId + uniq(this.currentOrder.schedules.map((schedule) => `C${schedule.courtId}`)).join('')
        orderId = orderId + `-toss-${this.currentOrder.id}`
        tossPayments.requestPayment('CARD', {
          amount: parseInt(this.currentOrder.bookingsTotalPrice) + this.selectedFacilityBookingRequestFeeAmount,
          orderId: orderId,
          orderName: this.currentOrder.metaData.facilityName,
          customerName: this.myProfile.name,
          successUrl: `${window.location.origin}/payments/success?pay_with=toss_payment`,
          failUrl: `${window.location.origin}/payments/fail`
        }).catch((error) => {
          console.error(error)
        })
      }).catch((error) => {
        console.error('error: ', error)
        this.$alert('결제가 정상적으로 처리되지 않았습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
          type: 'warning',
          showClose: false,
        }).then(() => {
          this.$router.push({name: 'PaymentFail'})
        })
      })
    },
    requestPayment () {
      if (this.isSelectedPayWithKakao) {
        this.requestKakaoPayment()
      } else if (this.isSelectedPayWithToss) {
        this.requestTossPayment()
      }
    }
  },
  mounted () {
    if (!this.hasCurrentOrder) {
      this.$alert('결제정보가 존재하지 않습니다.', {
        type: 'warning'
      }).then(() => {
        this.resetCurrentOrder()
        this.$router.push({name: 'PlayerScheduleBookings'})
      })
    }
  }
}
</script>

<style lang='scss'>
  .player-booking-flow .el-step__title.is-finish,
  .player-booking-flow .el-step__description.is-finish,
  .player-booking-flow .el-step__head.is-finish,
  .player-booking-flow .el-step__head.is-finish .el-step__line,
  .player-booking-flow .el-step__title.is-process,
  .player-booking-flow .el-step__description.is-process,
  .player-booking-flow .el-step__head.is-process {
    color: #166cc5;
    border-color: #166cc5;
  }
</style>
