import base from './base'

const getPrices = (facilityId) => base
  .get('/prices', {
    params: {
      facility_id: facilityId
    }}).then(resp => resp.data)

const updateBatchPrices = (facilityId, submitData) => base
  .patch('/prices/batch_update/',{
    facility_id: facilityId,
    submit_data: submitData,
  }).then(resp => resp.data)

export default {
  getPrices: getPrices,
  updateBatchPrices: updateBatchPrices
}
