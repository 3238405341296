<template>
  <component :is='navComponent'
    class='flex flex-row items-center lg:flex-col justify-between lg:justify-between'>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'
import NavMenuManager from '@/components/NavMenuManager'
import NavMenuPlayer  from '@/components/NavMenuPlayer'
export default {
  name: 'NavMenu',
  components: {
    NavMenuManager,
    NavMenuPlayer
  },
  computed: {
    ...mapGetters([
      'isCourtManager',
      'isCoach',
      'isRegisteredPlayer',
    ]),
    navComponent () {
      if (this.isCourtManager) {
        return 'nav-menu-manager'
      } else if (this.isRegisteredPlayer) {
        return 'nav-menu-player'
      } else {
        return 'nav'
      }
    },
  },
}
</script>