<template>
  <div class='overflow-y-auto'>
    <h1 class='text-2xl mb-6'>
       예약하기
    </h1>
    <el-steps :active='0' align-center class='player-booking-flow'>
      <el-step title='코트 선택'></el-step>
      <el-step title='정보 확인 & 결제'></el-step>
      <el-step title='예약 완료'></el-step>
    </el-steps>
    
    <div class='border mt-8 lg:mt-12 rounded-md'>
      <facility-contact-card
        :facility-data='showingFacilityContactCardData'
        class='px-4 py-8 border-b rounnded-b-0'
        style='background-color: #FAFAFA;'
        :hide-map='true' />
      <table class='w-full text-sm'>
        <thead>
          <tr class='border-b text-left'>
            <th class='pt-8 pb-2 px-2'>코트</th>
            <th class='pt-8 pb-2 px-2'>날짜</th>
            <th class='pt-8 pb-2 px-2'>시간</th>
            <th class='pt-8 pb-2 px-2 text-right'>가격</th>
          </tr>
        </thead>
        <add-new-booking-request-row
          v-for='newBookingRequest in newBookingRequestSlotList'
          :key='`newBookingRequest-${newBookingRequest.courtId}-${newBookingRequest.bookingDate}-${newBookingRequest.startTime}`'
          :new-booking-request='newBookingRequest'
          :check-for-repeat-dates='false'
          :repeat-count='repeatCount'
          :hide-end-date='true'
          @stack-create-new-booking-request-available='getHasBookingOnRepeatedDateStatues'
          @remove-new-booking-request-on-list='removeNewBookingRequestOnList' />
      </table>
      <div
        v-if='selectedFacilityBookingRequestFeeAmount > 0'
        class='p-2 flex flex-row justify-between items-center text-xs'>
        <label class='block'>온라인예약 수수료 (부가세포함)</label>
        <div>
          {{selectedFacilityBookingRequestFeeAmount}}원
        </div>
      </div>
      <div
        class='p-2 font-bold flex flex-row justify-between items-center'
        style='background-color: #FAFAFA;'>
        <label class='block'>총 금액</label>
        <div>
          {{bookingsTotalPriceWithFeeString}}원
        </div>
      </div>

    </div>
    <billing-cancellation-short :is-preview='true' />
    <div v-if='selectedFacilityHidePlayerPaymentButton'
      class='text-lg text-center bg-gray-100 px-4 py-8 mt-8'>
      <div v-html='selectedFacilityHidePlayerPaymentMessage'
        class='whitespace-pre-line text-base px-4 leading-5 mb-4'>    
      </div>
      <div class='mt-2 bg-gray-50 py-2'>
        {{ showingFacilityContactCardData.phoneNumber }}
      </div>
    </div>
    <portal to='sidepanel-footer' v-else>
      <div class='flex flex-row w-full h-20 py-4'>
        <button class='block text-sm text-white py-3 px-3 w-1/2 rounded-md hover:shadow-lg bg-gray-900'
                :disabled='disabledNewBookingRequestAddButton'
                :class='createNewBookingRequestButtonClass'
                @click='submitNewReservation'>
          예약하기
        </button>
        <button class='block bg-transparent text-sm text-gray-700 py-3 px-2 md:px-4 border border-transparent hover:border-gray-200 ml-2 rounded-md hover:shadow-sm'
          @click='doneEdit'>
          취소
        </button>
      </div>
    </portal>

  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import dayjs  from 'dayjs'
import numbro from 'numbro'
import AddNewBookingRequestRow  from '@/components/bookings/AddNewBookingRequestRow'
import FacilityContactCard      from '@/components/FacilityContactCard'
import BillingCancellationShort from '@/components/BillingCancellationShort'
import BookingRequestHelpers    from '@/utils/booking-request-helpers'
import EventBus                 from '@/utils/event-bus'

const camelcaseKeys = require('camelcase-keys')

export default {
  name: 'PlayerNewBookingRequest',
  components: {
    AddNewBookingRequestRow,
    FacilityContactCard,
    BillingCancellationShort,
  },
  data () {
    return {
      hasBookingOnRepeatedDateStatues: [],
      totalPrice: 0,
      repeatCount: 1,
    }
  },
  computed: {
    ...mapState([
      'userId'
    ]),
    ...mapState('bookings', [
      'scheduleSelectedDay',
      'bookings',
    ]),
    ...mapState('manageRequests', [
      'newBookingRequestSlotList',
    ]),
    ...mapGetters('facilities', [
      'currentFacilityId',
      'currentFacilityName',
      'showingFacilityContactCardData',
      'selectedFacilityBookingRequestFeeAmount',
      'selectedFacilityHidePlayerPaymentButton',
      'selectedFacilityHidePlayerPaymentMessage',
    ]),
    ...mapGetters('courts', [
      'courtNameFromId',
    ]),
    ...mapGetters('prices', [
      'priceForNewBookingRequest'
    ]),
    ...mapGetters('playerProfile', [
      'myProfile',
      'hasProfiles',
    ]),
    bookingsTotalPrice () {
      return this.newBookingRequestSlotList.map(booking => parseFloat(this.priceForNewBookingRequest(booking)))
                                           .reduce((a, b) => a + b, 0)
    },
    bookingsTotalPriceWithFeeString () {
      return numbro(parseInt(this.bookingsTotalPrice) + this.selectedFacilityBookingRequestFeeAmount).format({trimMantissa: true, thousandSeparated: true})
    },
    submitNewBookingRequestData () {
      let params = {
          new_booking_request: {
            user_id:              this.userId,
            profile_id:           this.myProfileId,
            facility_id:          this.currentFacilityId,
            booking_type:         'not_paid',
            repeat_count:         1,
            bookings_total_price: parseInt(this.bookingsTotalPrice),
            fee_amount: this.selectedFacilityBookingRequestFeeAmount,
            meta_data: {
              facility_name: this.currentFacilityName,
              dates: [this.scheduleSelectedDay]
            },
            schedules: this.newBookingRequestSlotList.map(request => {
              return {
                court_id:      request.courtId,
                court_name:    this.courtNameFromId(request.courtId),
                facility_name: this.currentFacilityName,
                booking_date:  request.bookingDate,
                start_time:    request.startTime,
                end_time:      request.endTime,
                price:         parseInt(this.priceForNewBookingRequest(request)),
              }
          }),
        }
      }
      return params
    },
    hasBookingsOnRepeatedDates () {
      return this.hasBookingOnRepeatedDateStatues.map(newBookingRequest => newBookingRequest.status).some(status => status === true)
    },
    myProfileId () {
      return (this.hasProfiles) ? this.myProfile.id : 0
    },
    createNewBookingRequestButtonClass () {
      return (!this.disabledNewBookingRequestAddButton) ? 'bg-gray-900' : 'opacity-25 bg-gray-500'
    },
    disabledNewBookingRequestAddButton () {
      return this.hasBookingsOnRepeatedDates || !this.myProfileId
    }
  },
  methods: {
    ...mapMutations('manageRequests', [
      'removeNewBookingRequestSlotList',
      'resetNewBookingRequestSlotList',
    ]),
    ...mapActions('playerBookingRequests', [
      'postPlayerNewBookingRequests',
    ]),
    ...mapMutations('payments', [
      'setCurrentOrder',
    ]),
    ...mapActions([
      'deactiveLoading',
    ]),
    ...mapActions('bookings', [
      'getPlayerUnbookableTimes',
    ]),
    datesFromRepeatCountAndFirstDate (firstDate) {
      let arr = []
      for (var i = 0; i < this.repeatCount; i++) {

        arr.push(dayjs(firstDate).add(1*i, 'week').format('YYYY-MM-DD'))
      }
      return arr
    },
    getHasBookingOnRepeatedDateStatues (status) {
      this.hasBookingOnRepeatedDateStatues.push(status)
    },
    removeBookingFromCheck (removedRequest) {
      this.removeFromCheckRepeatList(removedRequest)
    },
    removeNewBookingRequestOnList (removedRequest) {
      this.removeNewBookingRequestSlotList(removedRequest)
      this.removeFromCheckRepeatList(removedRequest)
    },
    removeFromCheckRepeatList (removedRequest) {
      let index = this.hasBookingOnRepeatedDateStatues.findIndex(newBookingRequest => BookingRequestHelpers.isSameRequestSlot(newBookingRequest, removedRequest))
      if (index !== -1) {
        this.hasBookingOnRepeatedDateStatues.splice(index, 1)
      }
    },
    doneEdit () {
      this.$emit('done-edit')
    },
    bookingEndDateString (date) {
      return `${dayjs(date).add(parseInt(this.repeatCount)-1, 'week').locale('kr').format('YYYY-MM-DD')}`
    },
    submitNewReservation () {
      this.postPlayerNewBookingRequests(this.submitNewBookingRequestData).then((resp) => {
        this.resetNewBookingRequestSlotList()
        this.$emit('done-edit')
        this.setCurrentOrder(camelcaseKeys(resp.new_booking_request, {deep: true}))
        this.$router.push({name: 'PaymentReady'})
      }).catch((error) => {
        if (error.response.status === 409) {
          this.$alert('이미 예약되거나 결제중인 시간이 포함되어 있습니다. 다른 시간을 선택해주세요.', {
            type: 'warning',
            showClose: false,
          }).then(() => {
            this.getPlayerUnbookableTimes().then(() => {
              this.bookings.forEach(booking => {
                this.removeNewBookingRequestSlotList(booking)
              })
              this.$nextTick(() => {
                this.deactiveLoading()
              })
            })
          })
        } else {
          this.$alert('알 수 없는 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.', {
            type: 'warning',
            showClose: false,
          })
        }
      })
    },
  },
  mounted () {
    EventBus.$on('remove-repeat-check', (request) => this.removeBookingFromCheck(request))
  },
  beforeDestroy () {
     EventBus.$off('remove-repeat-check')
  }
}
</script>

<style lang='scss'>
  .player-booking-flow .el-step__title.is-finish,
  .player-booking-flow .el-step__description.is-finish,
  .player-booking-flow .el-step__head.is-finish,
  .player-booking-flow .el-step__head.is-finish .el-step__line,
  .player-booking-flow .el-step__title.is-process,
  .player-booking-flow .el-step__description.is-process,
  .player-booking-flow .el-step__head.is-process {
    color: #166cc5;
    border-color: #166cc5;
  }
</style>
