<template>
 <div class='flex flex-col lg:flex-row'>
    <div class='w-full lg:w-1/2 flex flex-col justify-center items-center p-16'
      style='background-color: #F2F4F4;'>
      <logo height='4rem' />
    </div>
    <billing-cancellation-full-details  class='w-full lg:w-1/2 text-left p-8 lg:p-16 min-h-screen' />
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import BillingCancellationFullDetails from '@/components/BillingCancellationFullDetails'

export default {
  name: 'CancellationPolicy',
  components: {
    BillingCancellationFullDetails,
    Logo,
  },
}
</script>

<style lang='scss'>


</style>
