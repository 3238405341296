<template>
  <div class='border-white hover:rounded-md hover:shadow-inner hover:z-10 hover:border-blue-700 opacity-75 hover:opacity-100 rounded cursor-pointer relative'
    :class='isEditingBookingSlotStyle'
    :style='backgroundColorStyle'>
    <div class='box-border'>
      <div class='text-xs text-gray-900 font-bold uppercase leading-4'>{{bookingTitle}}</div>
      <div class='text-xs text-gray-500'>
        <div v-if='!isSlotBlocked'
          class='opacity-100 text-gray-900 font-semibold'>
          {{bookingProfilleName}}
          <span v-if='booking.totalRepeatCount > 1'>{{bookingRepeatString}}</span>
        </div>
        {{startTimeFormatted}}-{{endTimeFormatted}}시
      </div>
    </div>
    <check-circle-icon
      v-if='isSelectedBooking'
      class='absolute bottom-0 right-0 text-green-600' size='1x' />
  </div>
</template>

<script>
import { CheckCircleIcon } from "@vue-hero-icons/outline"
import StringHelpers from '@/utils/string-helpers'

export default {
  name: 'BookingsSlotExisting',
  components: {
    CheckCircleIcon
  },
  props: [
    'booking',
    'courtName',
    'courtHourDivided',
    'isEditingBooking',
    'isSelectedBooking'
  ],
  computed: {    
    backgroundColorStyle () {
      return `background-color: #${this.backgroundColor};`
    },
    bookingTitle () {
      return (this.isSlotReserved) ? '' : 'blocked'
    },
    bookingProfilleName () {
      return (this.booking.profile.name === 'mine') ? '내 예약' : this.booking.profile.name
    },
    bookingRepeatString () {
      return StringHelpers.bookingRepeatString(this.booking)
    },
    backgroundColor () {
      if (this.isSlotReserved) {
        if (this.isRepeatingContract) {
          return '7ad5f5'
        } else {
          return 'd2f1fc'
        }
      } else {
        return 'EEEEEE'
      }
    },
    isRepeatingContract () {
      return this.booking.bookingContractId && 
             parseFloat(this.booking.bookingContractId)  > 0
    },
    isEditingBookingSlotStyle () {
      return (this.isEditingBooking) ? 'z-10 opacity-100 border-blue-900 rounded-md shadow-inner' : ''
    },
    isSlotBlocked () {
      return this.booking.bookingType === 'blocked'
    },
    isSlotReserved () {
      return this.booking.bookingType.includes('reserved') || this.booking.bookingType === 'substitution'
    },
    startTimeFormatted () {
      return (this.booking.startTime) ? parseInt(this.booking.startTime) : 0
    },
    endTimeFormatted () {
      return (this.booking.endTime) ? parseInt(this.booking.endTime) : 0
    }
  },
}
</script>
