<template>
  <div class='text-center py-32 px-8'>
    <exclamation-circle-icon class='w-full mb-4 text-red-600' size='6x' />
    <div class='font-semibold text-2xl mb-24 w-full'>결제가 정상적으로 처리되지 않았습니다.</div>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from '@vue-hero-icons/outline'
import { mapActions } from 'vuex'

export default {
  name: 'PaymentFail',
  components: {
    ExclamationCircleIcon
  },
  methods: {
    ...mapActions('payments', [
      'resetCurrentOrder',
    ]),
  },
  beforeDestroy () {
    this.resetCurrentOrder()
  }
}
</script>
