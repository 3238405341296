import cloneDeep from 'lodash/cloneDeep'

class BookingRequestHelpers {

  static isSameRequestSlot (requestA, requestB) {
    return requestA.bookingDate            === requestB.bookingDate &&
           requestA.courtId                === requestB.courtId &&
           parseFloat(requestA.startTime)  === parseFloat(requestB.startTime)
  }

  // returns an object that has an array of bookings organized by courtID
  static requestBookingsByCourt (requestsBookings) {
    let returnVal = {}
    requestsBookings.forEach(booking => {
      if (!returnVal[booking.courtName]) {
        returnVal[booking.courtName] = []
      }
      returnVal[booking.courtName].push(booking)
    })

    return returnVal
  }

  static sortedTimes (slots) {
      return slots.sort((a, b) => {
        return parseInt(a.startTime) - parseInt(b.startTime)
      })
    }

  static combinedSlots (slots) {
    let sorted = this.sortedTimes(cloneDeep(slots))
    let newArray = []
    let tempSlot = {}
    while (sorted.length > 0) {
      tempSlot = sorted.shift()
      if (sorted.length === 0 || parseInt(tempSlot.endTime) < parseInt(sorted[0].startTime)) {
        newArray.push(tempSlot)
      } else {
        sorted[0].startTime = tempSlot.startTime
        sorted[0].price = sorted[0].price + tempSlot.price
      }
    }
    return newArray
  }
}

export default BookingRequestHelpers
