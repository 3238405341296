<template>
  <component :is='layout' id='app'>
    <router-view :layout.sync='layout' />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'

export default {
  name: `App`,
  components: {
    DefaultLayout
  },
  computed: {
    useDivLayout () {
      return this.$route.name === 'Login' || 
             this.$route.name === 'CancellationPolicy'
    },
    layout () {
      return (this.useDivLayout) ? 'div' : 'DefaultLayout'
    },
  },
  mounted () {
    document.title = process.env.VUE_APP_TITLE
  }
};
</script>
<style lang="scss">
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  word-break: keep-all;
}

* {
  @apply box-border;
}

.standard-transition {
  transition: all 0.2s ease-in-out;
}


</style>
