import base from './base'

const getPlayerProfiles = (clientType, page) => {
  const queryString = clientType ? `?page=${page}&client_type=${clientType}` : `?page=${page}`
  return base.get(`/profiles${queryString}`).then(resp => resp.data)
}

const getMyPlayerProfiles = () => base
  .get('/profiles/my_profiles').then(resp => resp.data)

const searchPlayerProfiles = (playerNameOrPhone, clientType) => base
  .get('/profiles/search', {
    params: {
      'search_string': playerNameOrPhone,
      'client_type': clientType
    }}).then(resp => resp.data)

const findPlayerProfile = (playerNameOrPhone) => base
  .get('/profiles/search', {
    params: {
      'search_string': playerNameOrPhone,
      'limit' :        5
    }}).then(resp => resp.data)

const createProfile = (profileParams) => base
  .post(`/profiles/`, profileParams).then(resp => resp.data)

const createProfileByManager = (profileParams) => base
  .post(`profiles/create_by_manager/`, profileParams).then(resp => resp.data)

const editProfile = (profileId, profileParams) => base
  .patch(`/profiles/${profileId}`, profileParams).then(resp => resp.data)

const getPlayerProfile = (profileId) => base
  .get(`/profiles/${profileId}`).then(resp => resp.data)

const getPlayerProfileDetails = (profileId) => base
  .get(`/profiles/${profileId}/drilldown`).then(resp => resp.data)

const deletePlayerProfile = (profileId) => base
  .delete(`/profiles/${profileId}/delete_manager`).then(resp => resp.data)


export default {
  getPlayerProfiles:       getPlayerProfiles,
  getMyPlayerProfiles:     getMyPlayerProfiles,
  searchPlayerProfiles:    searchPlayerProfiles,
  findPlayerProfile:       findPlayerProfile,
  createProfile:           createProfile,
  createProfileByManager:  createProfileByManager,
  editProfile:             editProfile,
  getPlayerProfile:        getPlayerProfile,
  getPlayerProfileDetails: getPlayerProfileDetails,
  deletePlayerProfile:     deletePlayerProfile
}
