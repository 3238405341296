<template>
  <div class='border-white opacity-50 rounded bg-indigo-200'>
    <div class='box-border'>
      <div class='text-xs text-gray-500'>Requested</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BookingSlotMyNewBookingRequest',
  props: [
    'booking'
  ],
}
</script>
