  <template>
  <tr class='text-xs hover:bg-blue-100 hover:text-gray-900'
    :class='newBookingRequestBoxCss'>
    <td class='p-1 lg:p-2 border-r border-b whitespace-nowrap'>{{paidAtDate}}</td>
    <td class='p-1 lg:p-2 border-r border-b whitespace-nowrap'>{{ playAtDateString }}</td>
    <td class='p-1 lg:p-2 border-r border-b whitespace-nowrap text-gray-900'>{{ bookingRequest.name }}</td>
    <td class='p-1 lg:p-2 border-r border-b'>
      <div v-for='(arrayOfSlots, key) in bookingsByCourt'
        :key='`${bookingRequest.id}-bookingSlot-${key}`'
        class='flex flex-row justify-start items-start'>
        <label class='w-12'><span class=''>코트</span> {{key}}</label>
        <div class='flex-grow'>
          <div v-for='(slot, key) in sortedAndCombinedSlots(arrayOfSlots)'
            :key='`${bookingRequest.id}-bookingSlot-${key}-slot-for-court-${slot.id}`'
            class='flex flex-row items-center justify-between whitespace-nowrap'>
            <span class='inline-block'>{{slot.startTime}} - {{slot.endTime}}시 {{ timeLengthString(slot) }}</span>
            <span class='ml-1 hidden'>{{priceString(slot.price)}}원</span>
          </div>
        </div>
      </div>
    </td>
    <td class='p-1 lg:p-2 border-r border-b text-right'>{{priceString(totalPriceForBookingRequest)}}원</td>
    <td class='p-1 lg:p-2 border-r border-b'>
      {{ newBookingRequestStatusText }}
    </td>
    <td class='p-1 lg:p-2 border-b'>
      <button v-if='!isCancelledBookingRequest'
        class='standard-transition hover:text-red-600 border border-solid border-gray-300 hover:border-red-300 bg-white w-24 text-xs mt-2 px-2 py-1 rounded hover:shadow-md'
        @click='openManagerNewBookingRequestCancellationModal'>
        취소하기
      </button>
    </td>
  </tr>
</template>

<script>
import StringHelpers          from '@/utils/string-helpers'
import BookingRequestHelpers  from '@/utils/booking-request-helpers'
import numbro from 'numbro'
import dayjs  from 'dayjs'

export default {
  name: 'ManageNewRequestRow',
  components: {
  },
  props: [
    'bookingRequest',
    'dateCategory',
  ],
  computed: {
    totalPriceForBookingRequest () {
      let totalPrice = parseInt(this.bookingRequest.bookingsTotalPrice) / parseInt(this.bookingRequest.repeatCount) + parseInt(this.feeAmount)
      return totalPrice
    },
    bookingsByCourt () {
      return BookingRequestHelpers.requestBookingsByCourt(this.bookingRequest.schedules)
    },
    isCancelledBookingRequest () {
      return this.bookingRequest.bookingType === 'pay_cancelled'
    },
    isPastBookingRequest () {
      return dayjs(this.bookingRequest.metaData.playDate).isBefore(new Date(), 'day') ||
            (dayjs(this.bookingRequest.metaData.playDate).isSame(new Date(), 'day') && 
            this.bookingRequest.schedules.find(schedule => parseInt(schedule.endTime) <= dayjs().hour()))
    },
    isUpcomingBookingRequest () {
      return (dayjs(this.bookingRequest.metaData.playDate).isAfter(new Date(), 'day') ||
             (dayjs(this.bookingRequest.metaData.playDate).isSame(new Date(), 'day') && 
              this.bookingRequest.schedules.find(schedule => parseInt(schedule.endTime) > dayjs().hour())))
    },
    newBookingRequestStatusText () {
      if (this.isCancelledBookingRequest) {
        return `${this.lastUpdatedAt} 취소됨`
      } else if (this.isPastBookingRequest) {
        return '이용완료'
      } else if (this.isUpcomingBookingRequest) {
        return '이용예정'
      } else {
        return ''
      }  
    },
    newBookingRequestBoxCss () {
      if (this.isCancelledBookingRequest) {
        return 'bg-gray-100 opacity-50 line-through'
      } else if (this.isPastBookingRequest) {
        return 'opacity-70'
      } else if (this.isUpcomingBookingRequest) {
        return ''
      } else {
        return ''
      }
    },
    paidAtDate () {
      return StringHelpers.dateString(this.bookingRequest.paidAt)
    },
    playAtDateString () {
      return (this.bookingRequest.metaData.playDate) ? StringHelpers.dateString(this.bookingRequest.metaData.playDate) : ''
    },
    lastUpdatedAt () {
      let cancelledAt = this.bookingRequest.cancelledAt ? this.bookingRequest.cancelledAt : this.bookingRequest.updatedAt
      return StringHelpers.dateTimeString(cancelledAt)
    },
    feeAmount () {
      return this.bookingRequest.feeAmount ? parseInt(this.bookingRequest.feeAmount) : 0
    },
  },
  methods: {
    sortedAndCombinedSlots (slots) {
      return BookingRequestHelpers.combinedSlots(slots)
    },
    timeLengthString (slot) {
      let length = parseInt(slot.endTime) - parseInt(slot.startTime)
      return (length > 1) ? `(${length}시간)` : ''
    },
    priceString (price) {
      return numbro(price).format({trimMantissa: true, thousandSeparated: true})
    },
    openManagerNewBookingRequestCancellationModal () {
      this.$emit('open-manager-new-booking-request-cancellation-modal', this.bookingRequest)
    },
  },
}
</script>
