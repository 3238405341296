import Vue       from 'vue'
import Vuex      from 'vuex'
import pricesApi from '@/api/v1/prices'
import dayjs from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')
const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  pricesByCourts: [],
  editingPrice: {},
})

const getters = {
  priceForNewBookingRequest: (state, getters, rootState, rootGetters) => (bookingData) => {
    let specialPrices = rootGetters['facilities/currentFacilitySpecialPrices']
    let found = specialPrices.find(specialPrice => specialPrice.date === dayjs(bookingData.bookingDate).format('YYYY-MM-DD'))
    if (found) {
      let price = found.formats.find(price => (parseFloat(price.startTime) <= parseFloat(bookingData.startTime)) &&
                                              (parseFloat(bookingData.startTime) < parseFloat(price.endTime))).price
      return price
    }
    var selectedCourtPrices = state.pricesByCourts.find(price => price.courtIds.includes(bookingData.courtId)).pricesByMonths
    var selectedMonthPrices = selectedCourtPrices.find(priceByMonths => priceByMonths.months.includes(dayjs(bookingData.bookingDate).month())).priceFormats
    return selectedMonthPrices.find(price => price.dayOfWeeks.includes(dayjs(bookingData.bookingDate).day()) &&
                                             (parseFloat(price.startTime) <= parseFloat(bookingData.startTime)) &&
                                             (parseFloat(bookingData.startTime) < parseFloat(price.endTime))).price
  },
  getField
}

const actions = {
  selectPriceToEdit ({ commit }, price) {
    commit('setEditingPrice', price)
  },
  getPrices({ commit, rootGetters}) {
    return new Promise(resolve => {
      pricesApi.getPrices(rootGetters['facilities/currentFacilityId']).then(resp => {
        commit('setPrices', camelcaseKeys(resp.prices_by_courts, {deep: true}))
        resolve()
      })
    })
  },
  updateBatchPrices ({ commit, rootGetters }, batchPrices) {
    return new Promise(resolve => {
      pricesApi.updateBatchPrices(rootGetters['facilities/currentFacilityId'], snakecaseKeys(batchPrices, {depp: true})).then(resp => {
        commit('setPrices', camelcaseKeys(resp.prices_by_courts, {deep: true}))
        resolve()
      })
    })
  }
}

const mutations = {
  setPrices (state, prices) {
    state.pricesByCourts = prices
  },
  setEditingPrice (state, price) {
    state.editingPrice = price
  },
  updateField
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
