<template>
  <div class='lg:flex lg:flex-row lg:items-stretch h-screen' style='background-color: #FCFCFC;'>
    <div class='new-requests-nav lg:border-r border-gray-100 shadow-xl w-full lg:w-96 lg:flex-grow-0 lg:flex-shrink-0 fixed lg:static z-40 lg:z-0 bg-white p-4 lg:p-8'
    :class='newRequestsNavclass'>
      <div class='manage-new-booking-requests'>
        <datepicker 
          :inline='true'
          v-model='selectedDate'
          minimumView='day'
          maximumView='day'
          calendar-class='w-full'
          format='yyyy-MM-dd'
          :language='ko'/>
      </div>
      <section class='mt-4 text-left'>
        <h2 class='uppercase text-base font-semibold'>View By</h2>
        <div class='mt-2'>
          <label for='playDateType'>
            <input type='radio'
              id='playDateType'
              v-model='viewByPlayOrCreatedDate'
              value='play'>
           코트 사용 날짜
          </label>
        </div>
        <div class='mt-2'>
          <label for='createdDateType'>
            <input type='radio'
              id='createdDateType'
              v-model='viewByPlayOrCreatedDate'
              value='created'>
           예약 결제 날짜
          </label>
        </div>
      </section>
      <section class='mt-8 text-left'>
        <h2 class='uppercase text-base font-semibold'>Filter</h2>
        <div class='mt-2'>
          <label for='all'>
            <input type='radio'
              id='all'
              v-model='selectedNewBookingRequestType'
              value=''>
              전체 예약
          </label>
        </div>
        <div class='mt-2'>
          <label for='reserved'>
            <input type='radio'
              id='reserved'
              v-model='selectedNewBookingRequestType'
              value='reserved'>
            확정된 예약
          </label>
        </div>
        <div class='mt-2'>
          <label for='pay_cancelled'>
            <input type='radio'
              id='pay_cancelled'
              v-model='selectedNewBookingRequestType'
              value='pay_cancelled'>
            취소된 예약
          </label>
        </div>
      </section>
    </div>
    <div class='overflow-x-auto overflow-y-auto lg:flex-grow text-left p-2 lg:p-8'>
      <h1 class='text-lg lg:text-2xl font-semibold mb-4 mt-8 lg:mt-0'>
        {{ newBookingRequestCreatedDateString }}
        {{ newBookingDateRangeTypeString }}
      </h1>
      <div v-if='hasNewBookingRequestsWithUserId'>
        <table>
          <thead>
            <tr class='text-xs'>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b border-r'>결제 날짜</th>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b border-r'>사용 날짜</th>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b border-r'>이름</th>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b border-r'>예약 내용</th>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b border-r text-right'>결제금액</th>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b border-r'>상태</th>
              <th class='p-1 lg:p-2 uppercase whitespace-nowrap border-b'></th>
            </tr>
          </thead>
        <tbody>
        <manage-new-request-row
          v-for='newBookingRequest in newBookingRequestsWithUserId'
          :key='`newBookingRequest-${newBookingRequest.id}`'
          :bookingRequest='newBookingRequest'
          @cancelled-booking-request='refreshData'
          @open-manager-new-booking-request-cancellation-modal='openManagerNewBookingRequestCancellationModal'
          class='mt-6' />
        </tbody>
        </table>
        <manager-new-requests-row-cancellation
          :booking-request='selectedManagerNewRequest'
          @close-manager-new-booking-request-cancellation-modal='CloseManagerNewBookingRequestCancellationModal' />
      </div>
      <div v-else
        class='mt-6 opacity-50'>
        추가된 예약이 없습니다.
      </div>
    </div>
    <mobile-expand-button
      class='inline-block lg:hidden'
      @click.native='hideContentOnMobile'>
      {{ newBookingRequestCreatedDateString }}<span class='inline-block ml-2 text-gray-400'>[날짜 선택]</span>
    </mobile-expand-button>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import MobileExpandButton                from '@/components/MobileExpandButton.vue'
import ManageNewRequestRow               from '@/views/manage-requests/ManageNewRequestRow.vue'
import ManagerNewRequestsRowCancellation from '@/views/manage-requests/ManagerNewRequestsRowCancellation.vue'
import StringHelpers       from '@/utils/string-helpers'
import dayjs               from 'dayjs'
import Datepicker from 'vuejs-datepicker'
import {ko}       from 'vuejs-datepicker/dist/locale'

export default {
  name: 'ManageNewRequests',
  components: {
    MobileExpandButton,
    Datepicker,
    ManageNewRequestRow,
    ManagerNewRequestsRowCancellation,
  },
  data () {
    return {
      ko: ko,
      selectedManagerNewRequest: null,
      viewByPlayOrCreatedDate: 'play',
      selectedDate: dayjs(new Date()).format('YYYY-MM-DD'),
    }
  },
  watch: {
    getManagerNewBookingRequestsParams: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && 
           (this.selectedNewBookingRequestCreatedDate !== '' || this.selectedNewBookingRequestPlayDate !== '')) {
          this.refreshData()
        }
      }, 
      immediate: true,
    },
    viewByPlayOrCreatedDate: {
      handler: function (newVal) {
        if (newVal === 'play') {
          this.selectedNewBookingRequestCreatedDate = ''
          this.selectedNewBookingRequestPlayDate = dayjs(this.selectedDate).format('YYYY-MM-DD')
        } else {
          this.selectedNewBookingRequestPlayDate = ''
          this.selectedNewBookingRequestCreatedDate = dayjs(this.selectedDate).format('YYYY-MM-DD')
          
        }
      }, 
      immediate: true,
    },
    selectedDate: {
      handler: function (newVal) {
        if (this.viewByPlayOrCreatedDate === 'play') {
          this.selectedNewBookingRequestCreatedDate = ''
          this.selectedNewBookingRequestPlayDate = dayjs(newVal).format('YYYY-MM-DD')
        } else {
          this.selectedNewBookingRequestPlayDate = ''
          this.selectedNewBookingRequestCreatedDate = dayjs(newVal).format('YYYY-MM-DD')
          
        }
      }, 
      immediate: true,
    },
  },
  computed: {
    ...mapState([
      'showContentOnMobile'
    ]),
    ...mapFields('manageRequests', [
      'selectedNewBookingRequestCreatedDate',
      'selectedNewBookingRequestPlayDate',
      'selectedNewBookingRequestType',
    ]),
    ...mapGetters('manageRequests', [
      'newBookingRequestsWithUserId',
      'hasNewBookingRequestsWithUserId'
    ]),
    getManagerNewBookingRequestsParams () {
      return `'new_booking_request_type: ${this.selectedNewBookingRequestType},
               new_booking_request_created_date: ${this.selectedNewBookingRequestCreatedDate}, 
               new_booking_request_play_date: ${this.selectedNewBookingRequestPlayDate}'`
    },
    newBookingRequestCreatedDateString () {
      return StringHelpers.dateString(this.selectedDate)
    },
    newBookingDateRangeTypeString () {
      return (this.viewByPlayOrCreatedDate === 'play') ? "에 사용하는 예약" : '에 결제된 예약'
    },
    newRequestsNavclass () {
      return (this.showContentOnMobile) ? 'hidden' : 'block'
    },

  },
  methods: {
    ...mapActions('manageRequests', [
      'getManagerNewBookingRequests',
    ]),
    ...mapActions([
      'hideContentOnMobile',
      'displayContentOnMobile',
    ]),
    refreshData () {
      this.getManagerNewBookingRequests()
      this.displayContentOnMobile()
    },
    openManagerNewBookingRequestCancellationModal (newBookingRequest) {
      this.selectedManagerNewRequest = newBookingRequest
      this.$modal.show('manager-new-booking-request-cancellation-modal')
    },
    CloseManagerNewBookingRequestCancellationModal () {
      this.selectedManagerNewRequest = null
      this.$modal.hide('manager-new-booking-request-cancellation-modal')
    },
  },
  beforeDestroy () {
    this.selectedNewBookingRequestPlayDate = ''
    this.selectedNewBookingRequestCreatedDate = ''
  },
}
</script>

<style lang='scss'>
.manage-new-booking-requests .vdp-datepicker__calendar {
  @apply w-full border-transparent;
  background-color: transparent;
}
</style>


<style type='scss' scoped>
.new-requests-nav {
  height: 100%;
}
@media (min-width: 1024px) {
  .new-requests-nav {
    height: 100%;
    display: block;
  }
}
</style>