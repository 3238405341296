<template>
  <section class=' mt-8 lg:mt-12'>
    <div class='flex flex-row justify-between items-center'>
      <h2 class='text-lg font-semibold uppercase text-gray-600'>예약 정보</h2>
      <div class='text-sm'>
        <span class='inline-block uppercase pr-2'>Total:</span>
        <span class='font-semibold'>{{formattedPriceTotalString}}</span>
      </div>
    </div>
    <div class='player-bookings-dates mt-2'>
      <el-date-picker
        v-model='drilldownBookingsDateRange'
        class='w-full'
        type='daterange'
        align='right'
        unlink-panels
        range-separator='-'
        start-placeholder='시작 날찌'
        end-placeholder='마지막 날찌'
        :picker-options='pickerOptions'>
      </el-date-picker>
    </div>
    <div v-if='playerHasBookings && !emptyBookingsInDateRange'
      class='mt-4'>
      <player-drilldown-row-single-day v-for='(bookingOnSingleDay, bookingDate) in drilldownPlayerBookings'
        :key='`bookings-on-${bookingDate}`'
        :bookings='bookingOnSingleDay'
        :booking-date='bookingDate'
        class='text-sm' />
    </div>
    <div v-else
      class='bg-gray-50 rounded-lg flex flex-row items-center text-center justify-center p-12 mt-8 text-sm'>
      <span><span class='text-gray-900 font-semibold'>{{startDateString}}</span> 부터 <span class='text-gray-900 font-semibold'>{{endDateString}}</span> 까지 예약 없음</span>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields }            from 'vuex-map-fields'
import PlayerDrilldownRowSingleDay from '@/views/manage-players/PlayerDrilldownRowSingleDay'
import numbro from 'numbro'
import dayjs  from 'dayjs'

export default {
  name: 'PlayerDrilldownBookingBillings',
  components: {
    PlayerDrilldownRowSingleDay
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
    }
  },
  computed: {
    ...mapState('managePlayers', [
      'drilldownPlayer',
    ]),
     ...mapGetters('courts', [
      'courtNameFromId',
    ]),
    ...mapGetters('managePlayers', [
      'drilldownBookingsFiltered',
      'drilldownPlayerBookings',
    ]),
    ...mapFields('managePlayers', [
      'drilldownBookingsDateRange',
    ]),
    formattedPriceTotalString () {
      return numbro(this.priceTotal).format({trimMantissa: true, thousandSeparated: true})
    },
    priceTotal () {
      return this.drilldownBookingsFiltered.map(booking => booking.price).reduce((a, b) => a + b, 0)
    },
    playerHasBookings () {
      return this.drilldownPlayer.bookings.length > 0
    },
    emptyBookingsInDateRange () {
      return this.drilldownBookingsFiltered.length === 0
    },
    startDateString () {
      return dayjs(this.drilldownBookingsDateRange[0]).format('YYYY-MM-DD')
    },
    endDateString () {
      return dayjs(this.drilldownBookingsDateRange[1]).format('YYYY-MM-DD')
    },
  },

}
</script>
<style lang='scss'>
  .player-bookings-dates .el-date-editor--daterange.el-input__inner {
    width: 100%;
  }
</style>
