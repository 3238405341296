import Vue         from 'vue'
import Vuex        from 'vuex'
import dayjs       from 'dayjs'
import cloneDeep   from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'
import newBookingRequestsApi   from '@/api/v1/new-booking-requests'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')
// const snakecaseKeys = require('snakecase-keys')

const state = () => ({
  startTime: 0,
  endTime: 24,
  myBookingRequests: [],
})

const getters = {
  pastBookingRequests: state => {
    let newArray = []
    let tmpRequest = {}
    state.myBookingRequests.forEach(bookingRequest => {
      bookingRequest.metaData.dates.forEach(bookingDate => {
        if (bookingRequest.bookingType === 'pay_cancelled' ||
            dayjs(bookingDate).isBefore(new Date(), 'day') ||
           (dayjs(bookingDate).isSame(new Date(), 'day') && 
            bookingRequest.schedules.find(schedule => parseInt(schedule.endTime) <= dayjs().hour()))) {
          tmpRequest = cloneDeep(bookingRequest)
          tmpRequest.metaData['playDate'] = bookingDate
          newArray.push(tmpRequest)
        }
      })
    })
    return newArray.sort((a, b) => new Date(b.metaData.playDate) - new Date(a.metaData.playDate))
  },

  upcomingBookingRequests: state => {
    let newArray = []
    let tmpRequest = {}
    state.myBookingRequests.forEach(bookingRequest => {
      bookingRequest.metaData.dates.forEach(bookingDate => {
        if (bookingRequest.bookingType !== 'pay_cancelled' && 
            (dayjs(bookingDate).isAfter(new Date(), 'day') ||
             (dayjs(bookingDate).isSame(new Date(), 'day') && 
              bookingRequest.schedules.find(schedule => parseInt(schedule.endTime) > dayjs().hour())))) {
          tmpRequest = cloneDeep(bookingRequest)
          tmpRequest.metaData['playDate'] = bookingDate
          tmpRequest.metaData['minStartTime'] = Math.min.apply(Math, bookingRequest.schedules.map((schedule) => { return schedule.startTime }))
          newArray.push(tmpRequest)
        }
      })
    })
    return newArray.sort((a, b) => new Date(a.metaData.playDate) - new Date(b.metaData.playDate))
  },
  getField,
}

const actions = {
  getMyNewBookingRequests ({ commit, dispatch }) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise(resolve => {
      newBookingRequestsApi.getMyNewBookingRequests().then(resp => {
        commit('setMyBookingRequests', camelcaseKeys(resp.new_booking_requests, {deep: true}))
        dispatch('stopFullPageLoading', null, {root: true})
        resolve()
      })
    })
  },
  postPlayerNewBookingRequests ({ dispatch }, submitData) {
    dispatch('showFullPageLoading', null, {root: true})
    return new Promise((resolve, reject) => {
      newBookingRequestsApi.createAndReadyForPay(submitData).then(resp => {
        dispatch('getMyNewBookingRequests')
        dispatch('stopFullPageLoading', null, {root: true})
        resolve(resp)
      }).catch(error => {
        dispatch('stopFullPageLoading', null, {root: true})
        reject(error)
      })
    })
  },
}

const mutations = {
  setWeeklyScheduledBookings (state, scheduledBookings) {
    state.weeklyScheduledBookings = scheduledBookings
  },
  setMyBookingRequests (state, requests) {
    state.myBookingRequests = requests
  },
  updateCancelledMyBookingRequests (state, cancelledBookingRequest) {
    let index = state.myBookingRequests.findIndex(bookingRequest => bookingRequest.id === cancelledBookingRequest.id)
    if (index !== -1) {
      state.myBookingRequests.splice(index, 1, cancelledBookingRequest)
    }
  },
  updateField
}




export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
