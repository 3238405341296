<template>
  <div class='overflow-y-auto'>
    <div v-if='isShowingExistingBooking' class='border rounded-md p-4 relative'>
      <el-dialog
        :visible.sync='showRemoveBookingDialog'
        custom-class='w-5/6 lg:w-1/5'
        top='30vh'
        :append-to-body='true'
        :before-close='closeRemoveBookingDialog'>
        <div class='px-6'>
          <div>반복되는 예약입니다.</div>
          <button class='block border w-full text-sm text-white py-3 px-4 h-12 mt-12 mb-4 rounded-md hover:shadow-lg bg-gray-900'
            @click='submitRemoveBooking(tmpBookingHold)'>
            이 예약만 삭제
          </button>
          <button class='block border w-full text-sm text-white py-3 px-4 h-12 mb-4 rounded-md hover:shadow-lg bg-gray-900'
            @click='submitRemoveBatchBookings(tmpBookingHold)'>
            이후 모든 예약 삭제({{drilldownBooking.remainingRepeatCount}}회)
          </button>
          <button class='block border w-full h-12 mb-4 font-semibold'
            @click='closeRemoveBookingDialog'>
            취소
          </button>
        </div>
      </el-dialog>
      <existing-booking-view
        @edit-booking='editBooking'
        @extend-booking='extendBooking'
        @delete-booking='clickBookingRemoveButton' />
    </div>
    <booking-edit-form 
      v-if='isShowingBookingEditForm'
      @cancel-edit='closeEditOrExtension'
      @submit-edit-booking='submitEditBooking'
      @submit-edit-batch-bookings='submitEditBatchBookings' />
    <booking-extension-form 
      v-if='isShowingBookingExtensionForm'
      @cancel-extension='closeEditOrExtension' />
  </div>

</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BookingEditForm      from '@/components/bookings/BookingEditForm'
import BookingExtensionForm from '@/components/bookings/BookingExtensionForm'
import ExistingBookingView  from '@/components/bookings/ExistingBookingView'

export default {
  name: 'ExistingBooking',
  components: {
    BookingEditForm,
    ExistingBookingView,
    BookingExtensionForm,
  },
  data () {
    return {
      showingComponent: 'existing-booking',
      cancelPopoverVisible: false,
      showRemoveBookingDialog: false,
      tmpBookingHold: null
    }
  },
  computed: {
    ...mapState('bookings', [
      'drilldownBooking'
    ]),
    ...mapGetters('courts', [
      'courtNameFromId'
    ]),
    ...mapGetters('bookings', [
      'isRepeatedBooking'
    ]),
    isShowingExistingBooking () {
      return this.showingComponent === 'existing-booking'
    },
    isShowingBookingEditForm () {
      return this.showingComponent === 'booking-edit-form'
    },
    isShowingBookingExtensionForm () {
      return this.showingComponent === 'booking-extension-form'
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('bookings', [
      'cancelBooking',
      'updateBooking',
      'updateBatchBookings',
      'cancelBatchBookingsRelatedRequest',
      'resetBookingsOnRepeatedDates'
    ]),
    ...mapActions('manageRequests', [
      'getSingleNewBookingRequestForExtension'
    ]),
    ...mapMutations('bookings', [
      'setDrilldownBooking',
    ]),
    closeEditOrExtension () {
      // probably also need to reset to previous values
      if (this.showingComponent === 'booking-edit-form') {
        this.setDrilldownBooking(this.tmpBookingHold)
      }
      this.showingComponent = 'existing-booking'
      this.resetBookingsOnRepeatedDates()
    },
    editBooking (booking) {
      this.tmpBookingHold = this.drilldownBooking
      this.setDrilldownBooking(booking)
      this.showingComponent = 'booking-edit-form'
    },
    extendBooking () {
      this.getSingleNewBookingRequestForExtension(this.drilldownBooking.newBookingRequestId).then(() => {
        this.showingComponent = 'booking-extension-form'
      })
    },
    closeRemoveBookingDialog () {
      this.tmpBookingHold = null
      this.showRemoveBookingDialog = false
    },
    openRemoveBookingDialog (booking) {
      this.tmpBookingHold = booking
      this.showRemoveBookingDialog = true
    },
    clickBookingRemoveButton (booking) {
      if (this.isRepeatedBooking) {
        this.openRemoveBookingDialog(booking)
      } else {
        this.$confirm('예약내용을 삭제하시겠습니까?', {
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
          type: 'success'
        }).then(() => {
          this.submitRemoveBooking(booking)
        }).catch(() => {})
      }
    },
    submitEditBooking (booking) {
      this.updateBooking(booking).then(() => {
        this.closeEditOrExtension()
        this.$message({
          type: 'success',
          message: '예약이 수정되었습니다.'
        })
      }).catch(() => {})
    },
    submitEditBatchBookings (booking) {
      this.updateBatchBookings(booking).then(() => {
        this.closeEditOrExtension()
        this.$message({
          type: 'success',
          message: '예약이 수정되었습니다.'
        })
      })
    },
    submitRemoveBooking (booking) {
      this.cancelBooking(booking).then(() => {
        this.sidepanelClose()
        this.$message({
          type: 'success',
          message: '예약이 삭제되었습니다.'
        })
      }).catch(() => {})
    },
    submitRemoveBatchBookings (booking) {
      this.cancelBatchBookingsRelatedRequest(booking).then(() => {
        this.sidepanelClose()
        this.$message({
          type: 'success',
          message: '예약이 삭제되었습니다.'
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang='scss'>
  .el-button.el-button--default.overrideCancelButton {
    @apply bg-gray-900 text-white uppercase rounded-md;
  }

  .el-button.el-button--default.el-button--primary.overrideConfirmDeleteButton {
    @apply bg-pink-100 border-pink-100 rounded-md;
    color: #333;
  }

  .el-message-box {
    @apply w-4/5 lg:w-1/4;
  }
</style>
