import Vue       from 'vue'
import Vuex      from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)
const camelcaseKeys = require('camelcase-keys')

const state = () => ({
  selectedPayWith: '',
  currentOrder: {},
})

const getters = {
  hasSelectedPayWith: (state) => {
    return state.selectedPayWith
  },
  hasCurrentOrder: (state) => {
    return !isEmpty(state.currentOrder)
  },
  isCurrentOrderDone: (state, getters) => {
    return getters.hasCurrentOrder ? state.currentOrder.bookingType === 'reserved' : false
  },
  isSelectedPayWithKakao: (state) => {
    return state.selectedPayWith === 'kakaoPayment'
  },
  isSelectedPayWithToss: (state) => {
    return state.selectedPayWith === 'tossPayment'
  },
  getField
}

const actions = {
  resetCurrentOrder ({commit}) {
    commit('setCurrentOrder', {})
  }
}

const mutations = {
  setCurrentOrder (state, currentOrder) {
    state.currentOrder = camelcaseKeys(currentOrder, {deep: true})
  },
  updateField
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
