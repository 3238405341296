<template>
  <table class='table-fixed w-full text-sm lg:text-base'>
    <thead>
      <tr class='hover:bg-gray-50 cursor-pointer hover:shadow-md border-b'
        @click='toggleShowDetails'>
        <td class='py-2 lg:py-4 px-1 uppercase w-6 lg:w-12'><chevron-double-right-icon size='1.2x'/></td>
        <td class='py-2 lg:py-4 px-1 uppercase w-24 lg:w-48 font-semibold text-sm'>{{ monthString }}<span class='text-sm'> (자세히 보기)</span></td>
        <td class='py-2 lg:py-4 px-1 w-16 lg:w-20'></td>
        <td class='py-2 lg:py-4 w-4'></td>
        <td class='py-2 lg:py-4 px-1 w-16 lg:w-20'></td>  
        <td class='py-2 lg:py-4 px-1'></td>  
      </tr>
    </thead>
    <price-for-label
      v-for='(priceFormat, index) in priceFormatDetailedData'
      :key='`priceFormat-${index}`'
      :price-format='priceFormat' />
  </table>
</template>

<script>
import { ChevronDoubleRightIcon } from "@vue-hero-icons/outline"
import StringHelpers  from '@/utils/string-helpers'
import PriceForLabel from '@/views/settings/PriceForLabel'

export default {
  name: 'PriceForMonths',
  props: ['priceForMonths'],
  components: {
    ChevronDoubleRightIcon,
    PriceForLabel,
  },
  data () {
    return {
      showDetails: false
    }
  },
  computed: {
    monthString () {
      return this.priceForMonths.months.map(month => StringHelpers.monthNumberToKorean(month)).join(', ')
    },
    priceFormatDetailedData () {
      return (this.showDetails) ? this.priceForMonths.priceFormats : []
    },
  },
  methods: {
    toggleShowDetails () {
      this.showDetails = !this.showDetails
    }
  }
}
</script>
